import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import InputSelect from '../../../components/InputSelect';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useProjectInterest } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { enrollmentStatusOptions, projectInterestOptions } from '../../../utils/const';
import { formateDate } from '../../../utils/formateDate';

const ProjectInterestEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    projectInterest,
    toastMessage,
    handleChange,
    getProjectInterest,
    handleSubmitButton,
    setPageType,
    setProjectInterest,
    errors,
  } = useProjectInterest();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getProjectInterest(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setProjectInterest({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.projectInterest,
        { url: '', name: `ProjectInterest object (${projectInterest?.id})` },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl={`/${baseUrl}/${projectInterest?.id}/history`}
        buttonText="history"
        showButton={pageType === 'change'}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Interest:"
            onChange={(value) => handleChange(value, 'interest')}
            options={projectInterestOptions}
            selectedValue={projectInterest?.interest || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Project:"
            onChange={(value) => handleChange(value, 'project_id')}
            options={projectInterest?.allProjects || []}
            selectedValue={projectInterest?.project_id || ''}
            width="80%"
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Volunteer:"
            onChange={(value) => handleChange(value, 'volunteer_id')}
            options={projectInterest?.allVolunteers || []}
            selectedValue={projectInterest?.volunteer_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Enrollment status:"
            onChange={(value) => handleChange(value, 'enrollment_status')}
            options={enrollmentStatusOptions}
            selectedValue={projectInterest?.enrollment_status || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <Styled.FlexBoxStyled>
            <Styled.LabelWrapper>
              <TextP>Researcher notified</TextP>
            </Styled.LabelWrapper>
            <input type="checkbox" name="notifed" checked={projectInterest?.researcher_notified} disabled />
          </Styled.FlexBoxStyled>
        </Styled.InputWrapper>

        <Styled.InputWrapper>

          <Styled.FlexBoxStyled>
            <Styled.LabelWrapper>
              <TextP font={fonts.font1.H13B}>Interest created at:</TextP>
            </Styled.LabelWrapper>
            {projectInterest?.created_at && (
              <TextP font={fonts.font1.H13}>{formateDate(projectInterest?.created_at)}</TextP>
            )}
          </Styled.FlexBoxStyled>
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${projectInterest?.id}`}
        showNew={false}
      />
    </AppWrapper>
  );
};

export default ProjectInterestEdit;
