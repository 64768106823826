import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import { FileInput } from '../../../components/FileInput';
import FormLabelText from '../../../components/FormLabelText';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputNumber from '../../../components/InputNumber';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import { TextArea } from '../../../components/InputTextArea';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { usePpoResult } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { resultStatusOptions } from '../../../utils/const';

const PpoResultEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    ppoResult,
    toastMessage,
    resourceFile,
    projects,
    handleChange,
    getPpoResult,
    handleSubmitButton,
    setPageType,
    setPpoResult,
    setResourceFile,
    linkProjects,
    errors,
    resourceFileDelect,
    setResourceFileDelect,
  } = usePpoResult();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getPpoResult(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setPpoResult({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.ppoResult,
        { url: '', name: `PPO Result object (${ppoResult?.id})` },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl={`/${baseUrl}/${ppoResult?.id}/history`}
        buttonText="history"
        showButton={pageType === 'change'}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>PPOResult object ({saveName})</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="completed"
            checkedValue={ppoResult?.completed || false}
            handleChange={(value) => handleChange(value, 'completed')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="summary:"
            textValue={ppoResult?.summary || ''}
            handleHandle={(value) => handleChange(value, 'summary')}
            keyName="summary"
            error={errors.summary}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="patient_partners_count"
            label="patient partners count:"
            inputValue={ppoResult?.patient_partners_count}
            handleHandle={handleChange}
            keyName="patient_partners_count"
            padding={false}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="patient partners engagement strategy:"
            textValue={ppoResult?.patient_partners_engagement_strategy || ''}
            handleHandle={(value) => handleChange(value, 'patient_partners_engagement_strategy')}
            keyName="patient_partners_engagement_strategy"
            error={errors.patient_partners_engagement_strategy}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="resource_website_name"
            label="resource website name:"
            inputValue={`${ppoResult?.resource_website_name}` || ''}
            handleHandle={handleChange}
            keyName="resource_website_name"
            padding={false}
            theme="form"
            error={errors.resource_website_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="resource_website_url"
            label="resource website url:"
            placeholder="https://www.example.com"
            inputValue={`${ppoResult?.resource_website_url}` || ''}
            handleHandle={handleChange}
            keyName="resource_website_url"
            padding={false}
            theme="form"
            error={errors.resource_website_url}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="resource_file_name"
            label="resource file name:"
            inputValue={`${ppoResult?.resource_file_name}` || ''}
            handleHandle={handleChange}
            keyName="resource_file_name"
            padding={false}
            theme="form"
            error={errors.resource_file_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FileInput
            label="Resource Filename:"
            handleChange={(value) => setResourceFile(value)}
            field="resourceFile"
            fileLink={ppoResult?.fileLink}
            fileName={resourceFile?.name || `${ppoResult?.resource_file}` || ''}
            isChecked={resourceFileDelect}
            handleCheck={(value: boolean) => {
              setResourceFileDelect(value);
            }}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="comments:"
            textValue={ppoResult?.comments || ''}
            handleHandle={(value) => handleChange(value, 'comments')}
            keyName="comments"
            error={errors.comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="admin comments:"
            textValue={ppoResult?.admin_comments || ''}
            handleHandle={(value) => handleChange(value, 'admin_comments')}
            keyName="admin_comments"
            error={errors.admin_comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="status:"
            onChange={(value) => handleChange(value, 'status')}
            options={resultStatusOptions}
            selectedValue={ppoResult?.status || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Result Created by:"
            textValue={ppoResult?.created_by || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Last Updated by:"
            textValue={ppoResult?.updated_by_user || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Project:"
            onChange={(value) => handleChange(value, 'project_id')}
            options={projects}
            selectedValue={ppoResult?.project_id || ''}
            width="80%"
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Linked study:"
            onChange={(value) => handleChange(value, 'linked_study_id')}
            placeholder="-------------"
            options={linkProjects}
            selectedValue={ppoResult?.linked_study_id || ''}
            width="80%"
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="Admin to Review"
            checkedValue={ppoResult?.admin_to_review || false}
            handleChange={(value) => handleChange(value, 'admin_to_review')}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${ppoResult?.id}`}
        showNew={false}
        showDelete
      />
    </AppWrapper>
  );
};

export default PpoResultEdit;
