import * as Yup from 'yup';

const extensions = Yup.number()
  .integer('Please enter a valid extension number')
  .min(0, 'Please enter a valid extension number')
  .max(99999999, 'Please enter a valid extension number')
  .typeError('Please enter a valid extension number')
  .nullable();

export const NameSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
});

export const healthCategorySchema = Yup.object().shape({
  id: Yup.number(),
  category: Yup.string().required('This field is required.'),
  item: Yup.string().required('This field is required.'),
  dscpt: Yup.string().required('This field is required.'),
  long_dscpt: Yup.string().nullable(),
});

export const projectInterestSchema = Yup.object().shape({
  interest: Yup.string().oneOf([
    'interested',
    'not_interested',
    'not_eligible',
  ]).required(),
  volunteer_id: Yup.number().required('This field is required.'),
  enrollment_status: Yup.string().oneOf([
    'not_contacted',
    'follow_up',
    'enrolled',
    'ineligible',
    'not_interested',
    'too_busy',
    'recruitment_closed',
    'contact_attempt',
    'already_enrolled',
    'participation_unknown',
    'other',
  ]).required('This field is required.'),
  researcher_notified: Yup.boolean(),
  project_id: Yup.number(),
});

export const projectschema = Yup.object().shape({
  title: Yup.string()
    .max(500, 'Please enter a valid title. The field allows up to 500 characters.'),
  lay_title: Yup.string().when('type', {
    is: 'study',
    then: Yup.string()
      .max(90, 'Please enter a valid lay title. The field allows up to 90 characters.'),
  }),
  nick_name: Yup.string()
    .min(0, 'Please enter a valid study headline. This field allows up to 90 characters.')
    .max(90, 'Please enter a valid study headline. This field allows up to 90 characters.'),
  additional_team_member: Yup.array().test(
    'AtLeastOneReceiveEmail',
    'At least one team member must receive automated emails',
    (value) => (value === undefined ? false : value.reduce((acc, current) => acc || current.receiveEmail, false)),
  ),
  is_clinical_trial: Yup.boolean(),
  registration_num: Yup.string().nullable(),
  funding_agency: Yup.array().min(1, 'Please select a funding agency'),
  recruitment_end: Yup.date()
    .typeError('Please enter a valid recruitment end date YYYY-MM-DD'),
  health_authority_region: Yup.array()
    .min(0, 'Please select at least one health authority region option'),
  province: Yup.string()
    .min(1, 'Please enter a valid province')
    .max(2, 'Please enter a valid province. The field allows up to 2 characters.'),
  purpose: Yup.string()
    .min(0, 'Please enter a valid purpose')
    .max(450, 'Please enter a valid purpose. The field allows up to 450 characters.'),
  description: Yup.string()
    .max(
      700,
      "Please enter a valid what's involved description. The field allows up to 700 characters.",
    ),
  commitment: Yup.string()
    .min(0, 'Please enter a valid summary of commitment')
    .max(
      300,
      'Please enter a valid summary of commitment. The field allows up to 300 characters.',
    ),
  procedures_required: Yup.boolean(),
  procedures_description: Yup.string().nullable().when('procedures_required', {
    is: true,
    then: Yup.string()
      .typeError('Please enter a valid procedures description')
      .min(1, 'Please enter a valid procedures description')
      .max(
        450,
        'Please enter a valid procedures description. The field allows up to 450 characters.',
      ),
  }),
  min_age: Yup.number()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid minimum age'),
  max_age: Yup.number()
    .typeError('Please enter a number'),
  sex_eligible: Yup.array().min(0, 'Please select sex'),
  genders_eligible: Yup.array()
    .min(1, 'Please select a gender'),
  inclusion_criteria: Yup.string()
    .min(0, 'Please enter valid inclusion criteria')
    .max(3000, 'Please enter valid inclusion criteria. The field allows up to 3000 characters.'),
  exclusion_criteria: Yup.string()
    .min(0, 'Please enter valid exclusion criteria')
    .max(3000, 'Please enter valid exclusion criteria. The field allows up to 3000 characters.'),
  target_patients: Yup.number()
    .typeError('Please enter a valid number for patients')
    .max(2147483647, 'Please enter a valid number for patients.'),
  target_controls: Yup.number()
    .typeError('Please enter a valid number for controls')
    .max(2147483647, 'Please enter a valid number for controls'),
  contact1_name: Yup.string()
    .min(0, 'Please enter a valid name')
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.'),
  contact1_position: Yup.string(),
  contact1_email: Yup.string()
    .email('Please enter a valid email'),
  contact1_phone: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.'),
  contact1_phone_extension: extensions,
  contact2_name: Yup.string()
    .min(2, 'Please enter a valid name')
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.'),
  contact2_email: Yup.string().email('Please enter a valid email'),
  contact2_phone: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.'),
  contact2_phone_extension: extensions,
  reb_certificate: Yup.boolean(),
  reb_expiry: Yup.string()
    .matches(
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
      'Please enter a valid date YYYY-MM-DD',
    )
    .max(10, 'Please enter a valid reb expiry date YYYY-MM-DD')
    .typeError('Please enter a valid reb expiry date YYYY-MM-DD'),
  reb_number: Yup.string().when('reb_certificate', {
    is: true,
    then: Yup.string()
      .min(1, 'Please enter a valid REB number')
      .max(80, 'Please enter a valid REB number. The field allows up to 80 characters.'),
  }),
  health_categories: Yup.array()
    .min(0, 'Please select a health category'),
  pi_id: Yup.number()
    .typeError('Please enter a registered PI email address'),
  hospital_id: Yup.number()
    .integer(),
  building: Yup.string()
    .min(0, 'Please enter a valid building')
    .max(120, 'Please enter a valid building. The field allows up to 120 characters.'),
  department: Yup.string()
    .min(0, 'Please enter a valid department')
    .max(120, 'Please enter a valid department. The field allows up to 120 characters.'),
  comments: Yup.string()
    .max(5000, 'Please enter a valid comment. The field allows up to 5000 characters.'),
  bc_support_unit: Yup.boolean(),
  is_online: Yup.boolean(),
  regional_centers: Yup.array().when('bc_support_unit', {
    is: true,
    then: Yup.array().min(0, 'Please select a centre'),
  }),
  study_type: Yup.string()
    .when('type', {
      is: 'study',
      then: Yup.string()
        .required('Please select a research study type'),
    }),
  status: Yup.string().required(),
  admin_to_reveiw: Yup.boolean(),
  recruitment_open_date: Yup.date(),
  has_direct_link: Yup.boolean().required(),
  direct_link_url: Yup.string().when('has_direct_link', {
    is: true,
    then: Yup.string().url('Please enter a vaild url link for your online study')
      .required('Please enter a vaild url link for your online study'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  ppo_type: Yup.string(),
});

export const studyResultschema = Yup.object().shape({
  completed: Yup.boolean(),
  summary: Yup.string()
    .required('Please enter a valid summary.')
    .max(900, 'The field allows up to 900 characters.'),
  patient_partners_count: Yup.number().min(0),
  patient_partners_engagement_strategy: Yup.string()
    .max(900, 'The field allows up to 900 characters.'),
  study_limitations: Yup.string()
    .max(450, 'The field allows up to 450 characters.')
    .nullable(),
  resource_website_name: Yup.string()
    .max(256, 'The field allows up to 256 characters.')
    .nullable(),
  resource_website_url: Yup.string()
    .url('Please enter valid URL')
    .max(200, 'The field allows up to 200 characters.')
    .nullable(),
  resource_file_name: Yup.string()
    .max(256, 'The field allows up to 256 characters.')
    .nullable(),
  comments: Yup.string()
    .max(512, 'The field allows up to 512 characters.')
    .nullable(),
  status: Yup.string().oneOf([
    'approved',
    'pending_approval',
  ]),
  admin_comments: Yup.string().nullable(),
  type: Yup.string().required(),
  id: Yup.number().required(),
  admin_to_review: Yup.boolean().required(),
});

export const ppoResultschema = Yup.object().shape({
  completed: Yup.boolean(),
  linked_study_id: Yup.number().nullable(),
  summary: Yup.string()
    .max(900, 'The field allows up to 900 characters.')
    .nullable(),
  patient_partners_count: Yup.number()
    .min(0),
  patient_partners_engagement_strategy: Yup.string()
    .max(900, 'The field allows up to 900 characters.')
    .nullable(),
  resource_website_name: Yup.string()
    .max(256, 'The field allows up to 256 characters.')
    .nullable(),
  resource_website_url: Yup.string()
    .url('Please enter valid URL')
    .max(200, 'The field allows up to 200 characters.')
    .nullable(true),
  resource_file_name: Yup.string()
    .max(256, 'The field allows up to 256 characters.')
    .nullable(),
  comments: Yup.string()
    .max(512, 'The field allows up to 512 characters.')
    .nullable(),
  status: Yup.string().oneOf([
    'approved',
    'pending_approval',
  ]),
  admin_comments: Yup.string().nullable(),
  type: Yup.string().required(),
  id: Yup.number().required(),
  admin_to_review: Yup.boolean().required(),
});

export const coreUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  last_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  is_active: Yup.boolean(),
  is_researcher: Yup.boolean(),
  is_volunteer: Yup.boolean(),
  phone_number: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .required('Please enter a valid phone number'),
  extension: Yup.number()
    .integer('Please enter a valid extension number')
    .min(0, 'Please enter a valid extension number')
    .max(99999999, 'Please enter a valid extension number')
    .typeError('Please enter a valid extension number')
    .nullable(),
  notification_frequency: Yup.string().required(),
  receive_news: Yup.boolean().required(),
  admin_comments: Yup.string().nullable(),
  isEmailUpdated: Yup.boolean(),
  oldEmail: Yup.string().nullable(),
});

export const coreResearcherSchema = Yup.object().shape({
  id: Yup.number().required(),
  user_id: Yup.number()
    .typeError('Please select a valid user')
    .required('Please select a valid user'),
  academic_affiliation_id: Yup.number()
    .typeError('Please select a valid position')
    .required('Please select a valid academic affiliation'),
  academic_affiliation_other: Yup.string()
    .typeError('Please enter a valid other')
    .nullable(),
  ha_affiliation_id: Yup.number()
    .typeError('Please select a valid health authority affiliation')
    .required('Please select a valid health authority affiliation'),
  position: Yup.string().oneOf([
    'Research Assistant',
    'Research Coordinator',
    'Graduate Student',
    'Principal Investigator',
    'Other',
  ]).required('Please select a valid position'),
  position_other: Yup.string().nullable(),
  mobile_phone_number: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .required('Please enter a valid phone number'),
  site_code: Yup.string().nullable(),
  is_pi: Yup.boolean().required(),
});

export const createResearcherSchema = Yup.object().shape({
  user_id: Yup.number().required('Please select a valid user'),
  academic_affiliation_id: Yup.number().required('Please select a valid academic affiliation'),
  academic_affiliation_other: Yup.string()
    .typeError('Please enter a valid other')
    .nullable(),
  ha_affiliation_id: Yup.number().required('Please select a valid health authority affiliation'),
  position: Yup.string().oneOf([
    'Research Assistant',
    'Research Coordinator',
    'Graduate Student',
    'Principal Investigator',
    'Other',
  ]).required('Please select a valid position'),
  position_other: Yup.string().nullable(),
  mobile_phone_number: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .required('Please enter a valid phone number'),
  site_code: Yup.string().nullable(),
  is_pi: Yup.boolean().required(),
});

export const coreVolunteerSchema = Yup.object().shape({
  id: Yup.number().required(),
  user_id: Yup.number().required(),
  affiliated_health_authority_region_id: Yup.number().nullable(),
  birth_date: Yup.date().typeError('Please enter a valid date'),
  for_child: Yup.boolean(),
  child1_birthyear: Yup.number().nullable(),
  child2_birthyear: Yup.number().nullable(),
  child3_birthyear: Yup.number().nullable(),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postal_code: Yup.string().nullable(),
  province: Yup.string().nullable(),
  onsite_presence: Yup.boolean().nullable(),
  gender: Yup.array()
    .min(0, 'Please select a gender')
    .nullable(),
  project_type_preference: Yup.array()
    .min(0, 'Please select a project type preference')
    .nullable(),
  receive_matching_email: Yup.boolean(),
  relationship: Yup.string().nullable(),
  sex: Yup.string().nullable(),
  site_code: Yup.string().nullable(),
  travel_distance: Yup.string().nullable(),
  health_categories: Yup.array()
    .min(0, 'Please select a  health categories')
    .nullable(),
  affilation_health_region: Yup.array()
    .min(0, 'Please select an affilation health region')
    .nullable(),
  ethnicity: Yup.array().min(0, 'Please select a ethnicity').nullable(),
});

export const createVolunteerSchema = Yup.object().shape({
  user_id: Yup.number().required(),
  affiliated_health_authority_region_id: Yup.number().nullable(),
  birth_date: Yup.date().required('Please enter a valid birth date'),
  for_child: Yup.boolean(),
  child1_birthyear: Yup.number().nullable(),
  child2_birthyear: Yup.number().nullable(),
  child3_birthyear: Yup.number().nullable(),
  address: Yup.string().nullable(),
  city: Yup.string(),
  postal_code: Yup.string(),
  province: Yup.string(),
  onsite_presence: Yup.boolean(),
  gender: Yup.array()
    .min(0, 'Please select a gender')
    .nullable(),
  project_type_preference: Yup.array()
    .min(0, 'Please select a project type preference')
    .nullable(),
  receive_matching_email: Yup.boolean(),
  relationship: Yup.string().nullable(),
  sex: Yup.string().nullable(),
  site_code: Yup.string(),
  travel_distance: Yup.string(),
  health_categories: Yup.array()
    .min(0, 'Please select a  health categories')
    .nullable(),
  affilation_health_region: Yup.array()
    .min(0, 'Please select an affilation health region')
    .nullable(),
  ethnicity: Yup.array().min(0, 'Please select a ethnicity'),
});

export const updateAdminUserSchema = Yup.object().shape({
  id: Yup.number().required(),
  first_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  last_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  is_active: Yup.boolean().required(),
});

export const createAdminUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  last_name: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(30, 'Please enter a valid name. The field allows up to 30 characters.')
    .required('Please enter a valid name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  phone_number: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .required('Please enter a valid phone number'),
  is_active: Yup.boolean(),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Your old password was entered incorrectly. Please enter it again.'),
  password: Yup.string()
    .required('Please enter a new password'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'The two password fields didn’t match.')
    .required('Required'),
});

export const ForceChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a new password'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'The two password fields didn’t match.')
    .required('Required'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .required('Please enter a valid password'),
});

export const mfaCodeSchema = Yup.object().shape({
  mfaCode: Yup.string()
    .min(6, 'Please enter a valid code')
    .max(6, 'Please enter a valid code')
    .required('Please enter a valid code'),
});
