import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours } from '../../styles/theme';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';

type BreadcrumbsProps = {
  breadcrumbs: {
    url: string,
    name: string
  }[]
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <Styled.BreadcrumbStyled>
    <Styled.CrumbStyled>
      <LinkComponent theme="breadcrumb" url="/">
        Home
      </LinkComponent>
      <Styled.IconWrapper>
        <FontAwesomeIcon icon="chevron-right" size="xs" />
      </Styled.IconWrapper>
    </Styled.CrumbStyled>
    {breadcrumbs.map((crumb, index) => (
      <Styled.CrumbStyled key={crumb.url}>
        {index !== breadcrumbs.length - 1 ? (
          <>
            <LinkComponent url={crumb.url} theme="breadcrumb">
              {crumb.name}
            </LinkComponent>
            <Styled.IconWrapper>
              <FontAwesomeIcon icon="chevron-right" size="xs" />
            </Styled.IconWrapper>
          </>
        ) : (
          <TextP color={colours.colour24}>
            {crumb.name}
          </TextP>
        )}
      </Styled.CrumbStyled>

    ))}
  </Styled.BreadcrumbStyled>
);

export default Breadcrumbs;
