import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput from '../InputText';
import * as Styled from './styles';
import Button from '../Button';
import { TextP } from '../Text';
import { colours, fonts } from '../../styles/theme';

type SearchBarProps = {
  handleSearch: (searchValue?: string) => void
  searchCount: number
  total: number
  setSearchValue: (serachValue: string) => void
  searchValue: string
}

const SearchBar = ({
  handleSearch, searchCount, total, setSearchValue, searchValue,
}: SearchBarProps) => (
  <Styled.SearchBarStyled>
    <FontAwesomeIcon icon="magnifying-glass" color={colours.colour15} />
    <TextInput
      handleHandle={(value: string) => setSearchValue(value)}
      inputValue={searchValue}
      id="searchInput"
      keyName=""
      handleEnter={() => handleSearch(searchValue.toLowerCase())}
    />
    <Button
      onClick={() => handleSearch(searchValue.toLowerCase())}
      theme="tertiary"
    >
      <TextP>Search</TextP>
    </Button>
    {searchCount !== total && (
      <Styled.TextWrapper>
        <TextP font={fonts.font1.H11}>{searchCount} results (</TextP>
        <Button
          onClick={() => {
            setSearchValue('');
            handleSearch('CLEAR');
          }}
          theme="plainSecondary"
        >
          <TextP font={fonts.font1.H11}>
            {total} total
          </TextP>
        </Button>
        <TextP font={fonts.font1.H11}>)</TextP>
      </Styled.TextWrapper>
    )}
  </Styled.SearchBarStyled>
);

export default SearchBar;
