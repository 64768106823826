import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const ErrorStyled = styled.div`
  border: 1px solid ${colours.colour18};
  padding: 10px 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: ${colours.colour18};
  ${fonts.font1.H14B}
`;
