import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const LabelStyled = styled.label`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const TextinputWrapper = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const TextWrapper = styled.div`
  margin-top: 0.35rem;
  display: inline-block;
`;

export const SubTextWraper = styled.div`
  margin-right: 0.5rem;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
`;
