import { useState } from 'react';

export const useInputAddRemove = () => {
  const [unSelected, setUnselected] = useState<{ label: string, value: number }[]>([]);
  const [filterList, setFilterList] = useState<{ label: string, value: number }[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValueLeft, setSelectedValueLeft] = useState<number[]>([]);
  const [selectedValueRight, setSelectedValueRight] = useState<number[]>([]);

  const setUpList = (
    fullList: { label: string, value: number }[],
    selectedList: { label: string, value: number }[],
  ) => {
    const unselectedUsers = fullList.filter((member) => {
      const list = selectedList.filter((select) => member.value === select.value);
      if (list.length === 0) return true;
      return false;
    });
    setUnselected(unselectedUsers);
    setFilterList(unselectedUsers);
    setSelectedValueLeft([]);
    setSelectedValueRight([]);
  };

  const handleFilterList = () => {
    const list = unSelected.filter((member) => member.label.toLowerCase().includes(searchValue.toLowerCase()));
    setFilterList(list);
  };

  const handleAddRemove = (value: number, side: string) => {
    if (side === 'left') {
      if (selectedValueLeft.includes(value)) {
        const updatedList = selectedValueLeft.filter((item) => item !== value);
        setSelectedValueLeft(updatedList);
      } else {
        setSelectedValueLeft([...selectedValueLeft, value]);
      }
    } else if (selectedValueRight.includes(value)) {
      const updatedList = selectedValueRight.filter((item) => item !== value);
      setSelectedValueRight(updatedList);
    } else {
      setSelectedValueRight([...selectedValueRight, value]);
    }
  };

  return {
    setUpList,
    filterList,
    searchValue,
    setSearchValue,
    handleFilterList,
    selectedValueLeft,
    selectedValueRight,
    handleAddRemove,
  };
};
