import styled from 'styled-components';
import { colours } from '../../styles/theme';

type LinkStyledProps = {
  readonly theme?: string
  readonly linkWidth?: string
};

export const LinkStyled = styled.a<LinkStyledProps>`
  transition: all .3s ease;
  cursor: pointer;
  line-height: 1.33rem;
  width: ${({ linkWidth }) => linkWidth || ''};

  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'headerLink':
        return 'underline';
      default:
        return 'none';
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour6;
      case 'tertiary':
        return colours.colour2;
      case 'breadcrumb':
        return colours.colour2;
      case 'headerLink':
        return colours.colour2;
      case 'button':
        return colours.colour2;
      case 'warning':
        return colours.colour2;
      case 'buttonSecondary':
        return colours.colour2;
      default:
        return colours.colour3;
    }
  }};
div {
  transition: all .3s ease;

  background: ${({ theme }) => {
    switch (theme) {
      case 'warning':
        return colours.colour18;
      case 'buttonSecondary':
        return colours.colour13;
      default:
        break;
    }
  }};
    border: ${({ theme }) => {
    switch (theme) {
      case 'warning':
        return `1px solid ${colours.colour18}`;
      case 'buttonSecondary':
        return `1px solid ${colours.colour13}`;
      default:
        return '';
    }
  }};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'warning':
        return '0.5rem 0.938rem';
      case 'buttonSecondary':
        return '0.5rem 0.938rem';
      default:
        break;
    }
  }};

  border-radius:  ${({ theme }) => {
    switch (theme) {
      case 'warning':
        return '4px';
      case 'buttonSecondary':
        return '4px';
      default:
        return '';
    }
  }};

    &:hover {
    background:  ${({ theme }) => {
    switch (theme) {
      case 'buttonSecondary':
        return colours.colour15;
      case 'warning':
        return colours.colour19;
      default:
        return '';
    }
  }}
}
}

  &:hover {
  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour2;
      case 'tertiary':
        return colours.colour2;
      case 'headerLink':
        return colours.colour4;
      case 'button':
        return colours.colour2;
      case 'buttonSecondary':
        return colours.colour2;
      case 'warning':
        return colours.colour2;
      case 'breadcrumb':
        return colours.colour24;
      default:
        return colours.colour8;
    }
  }};

  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'tertiary':
        return 'underline';
      case 'headerLink':
        return 'underline';
      default:
        return 'none';
    }
  }};
  }
`;
