import { useState } from 'react';
import { sortTableData } from '../utils/sortBy';

export const useTableBasic = (data: {
    id: number
    name: string
  }[]) => {
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showPageNumber, setShowPageNumber] = useState<(number | string)[]>([1, 2, 3, 4]);
  const [perPage, setPerPage] = useState(100);
  const [activeSort, setActiveSort] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');

  const handlePerPage = () => {
    setPerPage(data.length);
    setTotalPages(1);
    setPageNumber(1);
  };

  const handleSort = (sortBy: string) => {
    const sortDir = sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedData = sortTableData(data, sortBy, sortDir);
    setFilterTableData(sortedData.slice(0, perPage));
    setTotalPages(Math.ceil(sortedData.length / perPage));
    setPageNumber(1);
    setActiveSort(sortBy);
    setSortDirection(sortDir);
  };

  const updatedPageList = (newPage: number) => {
    if (newPage === 1 || newPage === 2) {
      setShowPageNumber([1, 2, 3, 4, ' ... ', totalPages - 1, totalPages]);
      return;
    }

    if (newPage === 3 || newPage === 4) {
      setShowPageNumber([1, 2, 3, 4, ' ... ', totalPages - 1, totalPages]);
      return;
    }
    if (newPage > totalPages - 2) {
      setShowPageNumber([1, 2, ' ... ', totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
      return;
    }
    setShowPageNumber([1, 2, ' ... ', newPage - 2, newPage - 1, newPage, newPage + 1, ' .... ', totalPages - 1, totalPages]);
  };

  return {
    pageNumber,
    setPageNumber,
    totalPages,
    setTotalPages,
    perPage,
    setPerPage,
    filterTableData,
    setFilterTableData,
    handlePerPage,
    handleSort,
    activeSort,
    sortDirection,
    showPageNumber,
    setShowPageNumber,
    updatedPageList,
  };
};
