import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type TextInputProps = {
  readonly isError?: boolean
  readonly theme?: string
}

type InputWrapperProps = {
  readonly padding: boolean
  readonly theme?: string
}

export const InputWrapper = styled.div<InputWrapperProps>`
  padding: ${({ padding }) => (padding ? '0.625rem' : '0')};
  display: flex;
  align-items: center;
`;

export const LabelStyled = styled.label`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const TextInputStyled = styled.input<TextInputProps>`
  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '0';
      default:
        return '4px';
    }
  }};
  padding: 0.35rem;
  box-shadow: none;
  border: 1px solid ${({ isError }) => (isError ? colours.colour18 : colours.colour23)};
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
`;
