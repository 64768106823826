import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import FilterBar from '../../../components/FilterBar';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { useStudyResult } from '../../../hooks';
import { resultsColumns, resultStatusFilterOptions } from '../../../utils/const';
import * as Styled from '../../../styles/IndexStyles';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const StudyResults = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterStudyResults,
    studyResults,
    selectStudyResults,
    getStudyResults,
    handleCheck,
    handleSearch,
    resultStatusFilter,
    searchFilter,
    setSearchFilter,
    handleUpdateFilter,
  } = useStudyResult();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getStudyResults(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  useEffect(() => {
    handleSearch();
  }, [resultStatusFilter]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.studyResult]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterStudyResults.length}
        total={studyResults.length}
      />
      <Styled.FlexboxStyled>
        <FilterBar
          filterOptions={resultStatusFilterOptions}
          keyName="result_status"
          handleChange={handleUpdateFilter}
          selectedValue={resultStatusFilter}
        />
      </Styled.FlexboxStyled>
      <TableBasic
        data={filterStudyResults || []}
        columns={resultsColumns}
        selectedList={selectStudyResults}
        handleCheck={handleCheck}
        footerText={`${filterStudyResults.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default StudyResults;
