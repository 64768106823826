import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { ppoResultschema } from '../utils/validations';
import { useApi } from './useApi';

export const usePpoResult = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, updateData, deleteMany,
  } = useApi();

  const baseUrl = 'core/ppoResult';
  const controllerName = 'results';
  const name = 'PPO results';
  const nameSingle = 'PPO result';

  const [ppoResults, setPpoResults] = useState<IIndexResult[] | []>([]);
  const [filterPpoResults, setFilterPpoResults] = useState<IIndexResult[] | []>([]);

  const [ppoResult, setPpoResult] = useState<IPpoResult>();
  const [saveName, setSavedName] = useState('');

  const [projects, setProjects] = useState<{value: number, label: string }[]>([]);
  const [linkProjects, setLinkProjects] = useState<{value: number, label: string }[]>([]);
  const [status, setStatus] = useState('');

  const [selectPpoResults, setSelectPpoResults] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [selectedValue, setSelectedValue] = useState('');

  const [resultStatusFilter, setResultStatusFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [resourceFile, setResourceFile] = useState<File>();
  const [resourceFileDelect, setResourceFileDelect] = useState(false);

  const [history, setHistory] = useState<IHistoryLog[]>([]);

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.ppoResult,
    ];
    if (projects.length === 1) {
      crumbs.push({
        url: `${breachcrumbPath.ppoResult.url}/${ppoResults[0].id}/change`,
        name: `${ppoResults[0].id}` || '',
      });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    setPpoResult((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectPpoResults.includes(id)) {
      setSelectPpoResults((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectPpoResults((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectPpoResults } });
    }
  };

  // API Calls
  const getPpoResults = async (isIndex = false) => {
    try {
      const ppoResultInfo = await getIndex(`${controllerName}/ppo`);
      setPpoResults(ppoResultInfo);
      if (isIndex) {
        setFilterPpoResults(ppoResultInfo);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getPpoResult = async (id: number) => {
    try {
      const ppoResultInfo = await getOne(id, `admin/${controllerName}`, '/ppo');
      ppoResultInfo.result.type = 'ppo';
      setPpoResult(ppoResultInfo.result);
      setSavedName(ppoResultInfo.result.id);
      setSelectPpoResults(ppoResultInfo.result.id);
      setProjects(ppoResultInfo.projects);
      setStatus(ppoResultInfo.result.status);
      setLinkProjects(ppoResultInfo.linkedProjects);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getPpoResultsById = async (ids: number[]) => {
    try {
      const ppoResultInfo = await getManyById(ids, `admin/${controllerName}`);
      setPpoResults(ppoResultInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const deletePpoResults = async (id: number[]) => {
    try {
      const results = await deleteMany(id, `admin/${controllerName}`, { type: 'ppo' });
      return results;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const updatePpoResult = async (values: any, id: number) => {
    try {
      const updatedPpoResult = await updateData(values, `admin/${controllerName}/${id}`);
      return updatedPpoResult;
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHistoryLog = async (id: string) => {
    try {
      const resResult = await getIndex(`admin/${controllerName}/history/${id}/ppo`);
      setHistory(resResult.historyLogs);
      setPpoResult(resResult.resultInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!ppoResult?.id) throw new Error(`Failed to update ${nameSingle}`);
      setErrors({});

      const resultsInfo = { ...ppoResult };
      if (resultsInfo.resource_website_url === 'null') {
        delete resultsInfo.resource_website_url;
      }
      const validedValues: any = await ppoResultschema.validate({
        ...resultsInfo,
      }, { abortEarly: false, stripUnknown: true });

      validedValues.updateStatus = status !== validedValues.status;

      if (resourceFileDelect) {
        validedValues.deleteFile = resourceFileDelect;
        validedValues.deletedFileName = resultsInfo.resource_file;
      }

      const data = new FormData();
      Object.keys(validedValues).forEach((key) => {
        data.append(key, validedValues[key]);
      });

      if (resourceFile) {
        data.append('resourceFile', resourceFile);
      }

      data.append('changedFields', changedFields.filter((change: string) => change !== 'status').join(', '));

      const ppoResultInfo = await updatePpoResult(data, ppoResult?.id);
      if (ppoResultInfo.error) throw new Error(ppoResultInfo.error);

      ppoResultInfo.type = 'ppo';
      setPpoResult({
        ...ppoResult,
        ...ppoResultInfo,
      });
      setStatus(ppoResultInfo.status);
      setResourceFileDelect(false);
      setResourceFile(undefined);

      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage('edit', nameSingle, `PpoResult object (${ppoResultInfo.id})`, `/${baseUrl}/${ppoResultInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `PpoResult object (${ppoResultInfo.id})`, `/${baseUrl}/${ppoResultInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'save') {
        setPpoResult({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        const message = JSON.parse(error.message);
        setErrors(message);
      }
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deletePpoResults(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, `${ppoResults[0]?.id}` || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    handleUpdate(type);
  };

  const handleUpdateFilter = (value: string | number, key: string) => {
    if (typeof value === 'number') return;
    switch (key) {
      case 'result_status':
        setResultStatusFilter(value);
        break;
      case 'search':
        setSearchFilter(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    if (!ppoResults) return [];
    const searchValue = ppoResults.filter((res) => {
      let result = false;
      const id = `${res.id}`;
      const projectId = `${res.project_id}`;
      if (id?.includes(searchFilter)) {
        result = true;
      }
      if (projectId?.includes(searchFilter)) {
        result = true;
      }
      if (res.project_name?.toLowerCase().includes(searchFilter)) {
        result = true;
      }
      if (resultStatusFilter !== '' && resultStatusFilter !== res.result_status) return false;

      if (res.project_name?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.pi_user?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.created_by_user?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.team_members) {
        const filterTeamMembers = res.team_members.filter((member) => member.toLowerCase().includes(searchFilter.toLowerCase()));
        if (filterTeamMembers.length > 0) return true;
      }
      return result;
    });
    setFilterPpoResults(searchValue);
    return searchValue;
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    ppoResult,
    filterPpoResults,
    ppoResults,
    selectPpoResults,
    pageType,
    setPageType,
    toastMessage,
    getPpoResult,
    setSelectPpoResults,
    getPpoResults,
    handleSubmitButton,
    setPpoResult,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getPpoResultsById,
    handleCheck,
    handleChange,
    handleUpdate,
    handleSearch,
    handleDelete,
    errors,
    handleUpdateFilter,
    searchFilter,
    setSearchFilter,
    resultStatusFilter,
    resourceFile,
    setResourceFile,
    projects,
    linkProjects,
    getBreadcrumbs,
    history,
    getHistoryLog,
    resourceFileDelect,
    setResourceFileDelect,
  };
};
