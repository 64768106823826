import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useCookies, useCheckLogin } from '../../hooks';
import Breadcrumbs from '../Breadcrumbs';
import HeaderToast from '../HeaderToast';
import MainHeader from '../MainHeader';
import * as Styled from './styles';
import environment from '../../config/environment';

type AppWrapperProps = {
  children: ReactNode,
  toastMessage?: IToastMessage
  breadcrumbs?: {
    url: string,
    name: string
  }[]
}
const AppWrapper = ({ toastMessage, breadcrumbs, children }: AppWrapperProps) => {
  const navigate = useNavigate();
  const { deleteCookie, getCookie } = useCookies();
  const { hookRenewCookie } = useCheckLogin();

  const [userName, setUserName] = useState('user');

  const getName = () => {
    const user = getCookie(environment.app.cookieName);
    if (!user) return;
    const decodedUser: any = jwtDecode(user);

    setUserName(decodedUser.first_name);
  };

  const handleLogout = async () => {
    await deleteCookie(environment.app.cookieName);
    navigate('/login');
  };

  useEffect(() => {
    document.addEventListener('keypress', hookRenewCookie);
    document.addEventListener('mousemove', hookRenewCookie);

    return () => {
      document.removeEventListener('keypress', hookRenewCookie);
      document.removeEventListener('mousemove', hookRenewCookie);
    };
  }, []);

  useEffect(() => {
    getName();
  }, []);
  return (
    <Styled.AppWrapperStyle>
      <MainHeader userName={userName} handleLogout={handleLogout} />
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

      {toastMessage && (
        <HeaderToast message={toastMessage} />
      )}
      <Styled.BodyWrapper>
        {children}
      </Styled.BodyWrapper>
    </Styled.AppWrapperStyle>
  );
};

export default AppWrapper;
