import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type CellProps = {
  readonly width?: string
  readonly selected?: boolean
}

type HeaderProps = {
  readonly width?: string
  readonly selected?: boolean
}

export const TableStyled = styled.table`
  width: 100%;
  ${fonts.font1.H13};
  line-height: 1rem;
  border-bottom: 2px solid ${colours.colour17};
  table-layout: fixed;
  margin-top: 0.685rem;

  tr:nth-child(odd){
    background: ${colours.colour16};
  }
`;

export const TableRowStyled = styled.tr`
  border-bottom: 1px solid ${colours.colour17};
`;

export const TableHeaderStyled = styled.th<HeaderProps>`
  color: ${colours.colour1};
  ${fonts.font1.H11B}
  padding: 0.25rem 0.625rem;
  text-transform: capitalize;
  text-align: left;
  width: ${({ width }) => width};
`;

export const TableCellStyled = styled.td<CellProps>`
  text-align: left;
  padding: 0.5rem 0 0.5rem 0.625rem;
  width: ${({ width }) => width || 'auto'};
  background: ${({ selected }) => (selected ? colours.colour7 : '')};
`;

export const TableCellFooterStyled = styled.td`
  padding: 0.625rem;
  border-bottom: 1px solid ${colours.colour17};
  text-transform: capitalize;
  color: ${colours.colour1};
  background: white;
  ${fonts.font1.H13};
`;

export const CheckBoxStyled = styled.input`
  margin: 0;
`;

export const FooterButtonWrapper = styled.div`
  margin-right: 0.35rem;
  display: flex;
  align-items: center;
`;

export const FlexBoxStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CellLabelStyled = styled.span`
  ${fonts.font1.H11B};
  text-decoration: underline;
`;

export const FooterTextWrapper = styled.div`
  margin: 0 0.5rem;
`;

export const PageTextWrapper = styled.div`
  margin: 0 0.5rem 0 0;
`;
