import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import { TextH1 } from '../../components/Text';
import { fonts } from '../../styles/theme';
import { dashboardTables } from '../../utils/dashboardConst';
import DashboardTable from './component/DashboardTable';
import * as Styled from '../../styles/DashboardStyles';

const Dashboard = () => (
  <AppWrapper>
    <Styled.DashboardTitleWrapper>
      <TextH1 font={fonts.font1.H20} textAlgin="left">
        Site Administration
      </TextH1>
    </Styled.DashboardTitleWrapper>
    <Styled.DashboardStyled>
      <Styled.TableWrapper>
        {dashboardTables.map((table) => (
          <DashboardTable key={table.tableName} tableInfo={table} />
        ))}
      </Styled.TableWrapper>
    </Styled.DashboardStyled>
  </AppWrapper>
);

export default Dashboard;
