import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type InputNumberProps = {
  handleHandle: (value: number, field: string) => void
  handleEnter?: () => void
  label?: string
  keyName: string
  inputValue?: number
  id: string
  padding?: boolean
  error?: string
  theme?: string
}
const InputNumber = ({
  handleHandle, handleEnter, label, keyName, inputValue, id, error, padding = true, theme,
}: InputNumberProps) => (
  <Styled.InputWrapper padding={padding}>
    {label && (
      <Styled.LabelStyled htmlFor={id}>
        {label}
      </Styled.LabelStyled>
    )}
    <Styled.TextAreaWrapper theme={theme}>
      {error && (
        <Styled.ErrorWrapper>
          <TextP>This field is required.</TextP>
        </Styled.ErrorWrapper>
      )}
      <Styled.TextInputStyled
        type="number"
        id={id}
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleHandle(parseInt(event.target.value, 10), keyName);
        }}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter' && handleEnter) {
            event.preventDefault();
            handleEnter();
          }
        }}
        theme={theme}
        isError={!!error}
      />
    </Styled.TextAreaWrapper>
  </Styled.InputWrapper>
);

export default InputNumber;
