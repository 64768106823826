import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import { LinkComponent } from '../../../components/Link';
import { TextH3, TextP } from '../../../components/Text';
import { useHospital } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { fonts } from '../../../styles/theme';

const HospitalDelete = () => {
  const location: any = useLocation();
  const params = useParams();

  const {
    nameSingle,
    name,
    baseUrl,
    hospitals,
    selectHospitals,
    setSelectHospitals,
    handleDelete,
    getHospitalsById,
    getBreadcrumbs,
  } = useHospital();

  useEffect(() => {
    if (location.state?.ids) {
      setSelectHospitals(location.state.ids);
      getHospitalsById(location.state.ids);
    }
  }, [location]);

  useEffect(() => {
    if (params.id) {
      setSelectHospitals([parseInt(params.id, 10)]);
      getHospitalsById([parseInt(params.id, 10)]);
    }
  }, [params]);

  return (
    <AppWrapper breadcrumbs={getBreadcrumbs()}>
      <Styled.TextWrapper>
        <TextH3 font={fonts.font1.H20}>Are you sure?</TextH3>
      </Styled.TextWrapper>
      {hospitals.length === 1 ? (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the {nameSingle} “{hospitals[0].name}”? All of the following related items will be deleted:
        </TextP>
      ) : (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the selected {name}? All of the following objects and their related items will be deleted:
        </TextP>
      )}
      <div>
        <Styled.TextWrapper>
          <TextP font={fonts.font1.H16B}>Summary</TextP>
          <Styled.ListStyled>
            <li>
              {name}: {hospitals.length}
            </li>
          </Styled.ListStyled>
        </Styled.TextWrapper>
      </div>
      <div>
        <TextP font={fonts.font1.H16B}>Objects</TextP>
        {hospitals && (
          <Styled.ListStyled>
            {hospitals?.map((authority: { name?: string, id?: number }) => (
              <li key={authority.id}>
                {nameSingle}: {' '}
                <LinkComponent url={`/${baseUrl}}/${authority.id}`}>
                  {authority.name}
                </LinkComponent>
              </li>
            ))}
          </Styled.ListStyled>
        )}
        <Styled.FlexBoxStyled>
          <Button
            onClick={() => handleDelete(selectHospitals)}
            theme="warning"
          >
            <TextP font={fonts.font1.H12}>Yes, I&apos;m sure</TextP>
          </Button>
          <Styled.ButtonWrapper>
            <LinkComponent
              url={hospitals.length === 1 ? `/${baseUrl}/${hospitals[0].id}/change` : `/${baseUrl}`}
              theme="buttonSecondary"
            >
              <div>
                <TextP font={fonts.font1.H12}>
                  No, take me back
                </TextP>
              </div>
            </LinkComponent>
          </Styled.ButtonWrapper>
        </Styled.FlexBoxStyled>
      </div>
    </AppWrapper>
  );
};

export default HospitalDelete;
