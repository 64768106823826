import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { coreUserSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useUser = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update, deleteMany, checkEmailExist,
  } = useApi();

  const baseUrl = 'core/user';
  const controllerName = 'admin/users';
  const name = 'users';
  const nameSingle = 'user';

  const [users, setUsers] = useState<ICoreUserIndex[] | []>([]);
  const [filterUsers, setFilterUsers] = useState<ICoreUserIndex[] | []>([]);

  const [user, setUser] = useState<ICoreUser>({
    notification_frequency: 'weekly',
    receive_news: false,
    is_active: true,
    is_researcher: false,
    is_volunteer: false,
  });
  const [saveName, setSavedName] = useState('');

  const [selectUsers, setSelectUsers] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const [searchFilter, setSearchFilter] = useState('');

  const [filterSelectedValue, setFilterSelectedValue] = useState('');

  const [isActive, setIsActive] = useState(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [editEmail, setEditEmail] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.user,
    ];
    if (users.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.user.url}/${users[0].id}/change`, name: users[0].first_name });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number| boolean, field: string) => {
    if (field === 'newEmail') {
      setUpdatedEmail(`${value}`);
    } else {
      setUser((prev) => ({
        ...prev,
        [field]: value,
      }));
    }

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectUsers.includes(id)) {
      setSelectUsers((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectUsers((oldArray) => [...oldArray, id]);
    }
  };

  const handleEmailChange = async () => {
    try {
      setErrors({});
      await checkEmailExist(updatedEmail);
      setUpdatedEmail(updatedEmail);
      setEditEmail(false);
    } catch (error: any) {
      setErrors({ updatedEmail: error });
    }
  };

  // API Calls
  const getUsers = async (isIndex = false) => {
    try {
      const userList = await getIndex(controllerName);
      setUsers(userList);
      if (isIndex) {
        setFilterUsers(userList);
      }
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getUser = async (id: number) => {
    try {
      const userInfo = await getOne(id, controllerName);
      setUser(userInfo);
      setSavedName(userInfo.email);
      setSelectUsers(userInfo.id);
      setIsActive(userInfo.is_active);
      setUpdatedEmail(userInfo.email);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getUsersById = async (ids: number[]) => {
    try {
      const HospitalInfo = await getManyById(ids, controllerName);
      setUsers(HospitalInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const createNewUser = async (values: any) => {
    const newUser = await create(values, controllerName);
    if (newUser.error) throw newUser.error;
    return newUser;
  };

  const updateUser = async (values: any, id: number) => {
    const updatedUser = await update(values, id, controllerName);
    if (updatedUser.error) throw updatedUser.error;
    return updatedUser;
  };

  const deleteUser = async (ids: number[]) => {
    const results = await deleteMany(ids, controllerName);
    if (results.error) throw results;
    return results;
  };

  const handleCreate = async (type: string) => {
    setErrors({});
    try {
      const validedValues = await coreUserSchema.validate({
        ...user,
      }, { abortEarly: false });
      const userInfo = await createNewUser(validedValues);
      if (!userInfo?.id) throw new Error('Failed to create user');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, userInfo.email, `/${baseUrl}/${userInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, userInfo.email, `/${baseUrl}/${userInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, userInfo.email, `/${baseUrl}/${userInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${userInfo.id}/change`);
      }
      if (type === 'add') {
        setUser({
          notification_frequency: 'weekly',
          receive_news: false,
          is_active: true,
          is_researcher: false,
          is_volunteer: false,
        });
        setSavedName('');
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setUser({
          notification_frequency: 'weekly',
          receive_news: false,
          is_active: true,
          is_researcher: false,
          is_volunteer: false,
        });
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (typeof error === 'string') {
        const err = await JSON.parse(error);
        setErrors(err);
      } else if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      const userInfo = { ...user };
      if (!userInfo.id) throw new Error(`Failed to update ${nameSingle}`);
      if (updatedEmail !== user.email) {
        userInfo.oldEmail = userInfo.email;
        userInfo.email = updatedEmail;
        userInfo.isEmailUpdated = true;
      } else {
        userInfo.isEmailUpdated = false;
      }
      const validedValues: any = await coreUserSchema.validate({
        ...userInfo,
      }, { abortEarly: false });

      validedValues.updateActive = validedValues.is_active !== isActive;

      const updateUserInfo = await updateUser(validedValues, userInfo.id);
      if (!updateUserInfo?.email) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, updateUserInfo.email, `/${baseUrl}/${updateUserInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, updateUserInfo.email, `/${baseUrl}/${updateUserInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, updateUserInfo.email, `/${baseUrl}/${updateUserInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setUser({
          notification_frequency: 'weekly',
          receive_news: false,
          is_active: true,
          is_researcher: false,
          is_volunteer: false,
        });
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setUser({
          notification_frequency: 'weekly',
          receive_news: false,
          is_active: true,
          is_researcher: false,
          is_volunteer: false,
        });
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
      setUpdatedEmail(updateUserInfo.email);
    } catch (error: any) {
      if (typeof error === 'string') {
        const err = await JSON.parse(error);
        setErrors(err);
      } else if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteUser(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, users[0]?.first_name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      setErrors({ message: error.error });
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  const handleUpdateFilter = (value: string | number) => {
    if (typeof value === 'number') return;
    setFilterSelectedValue(value);
  };

  const handleSearch = () => {
    if (!users) return [];

    let searchValue = users;
    if (filterSelectedValue !== '') searchValue = searchValue.filter((res) => res.account_type === filterSelectedValue);

    searchValue = searchValue.filter((res) => {
      let result = false;
      const id = `${res.id}`;
      const volunteerId = `${res.volunteer_id}`;
      if (id?.includes(searchFilter)) {
        result = true;
      }
      if (volunteerId?.includes(searchFilter)) {
        result = true;
      }
      if (res.first_name?.toLowerCase().includes(searchFilter.toLowerCase())) {
        result = true;
      }

      if (res.last_name?.toLowerCase().includes(searchFilter.toLowerCase())) {
        return true;
      }

      if (`${res.first_name?.toLowerCase().trim()} ${res.last_name?.toLowerCase().trim()}`.includes(searchFilter.toLowerCase())) {
        return true;
      }

      if (res.email?.toLowerCase().includes(searchFilter.toLowerCase())) {
        return true;
      }

      return result;
    });
    setFilterUsers(searchValue);
    return searchValue;
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectUsers } });
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    user,
    users,
    selectUsers,
    pageType,
    setPageType,
    toastMessage,
    filterUsers,
    getUser,
    setSelectUsers,
    getUsers,
    createNewUser,
    handleSubmitButton,
    setUser,
    filterSelectedValue,
    setFilterSelectedValue,
    handleUpdateFilter,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getUsersById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
    handleSearch,
    setSearchFilter,
    searchFilter,
    errors,
    editEmail,
    setEditEmail,
    updatedEmail,
    handleEmailChange,
  };
};
