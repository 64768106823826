import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type SelectStyledProps = {
  readonly width?: string
}

export const SelectWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  display: flex;
`;

export const SelectStyled = styled.select<SelectStyledProps>`
  margin-right: 0.5rem;
  padding: 0.25rem 0.635rem;
  border-radius: 4px;
  border: 1px solid ${colours.colour23};
  max-width: ${({ width }) => (width || '100%')};
`;

export const SelectOptionStyled = styled.option`
  text-transform: capitalize;
  overflow: hidden;
`;

export const LabelStyled = styled.label`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
  flex-shrink: 0;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
  ${fonts.font1.H14B}
`;

export const InputWrapper = styled.div`
  max-width: 100%;
`;
