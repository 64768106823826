import environment from '../config/environment';
import { useCookies } from './useCookies';

export const useApi = () => {
  const { getCookie } = useCookies();

  const getIndex = async (path: string, query?: string) => {
    try {
      const params = query ? `${environment.api.baseUrl}/${path}${query}` : `${environment.api.baseUrl}/${path}`;
      const response = await fetch(params, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
      });

      if (response.ok) {
        return await response.json();
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getOne = async (id: number | string, path: string, postPath = '') => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}/${id}${postPath}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getManyById = async (ids: number[], path: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}/ids/${ids.join(',')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const create = async (values: any, path: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
        body: JSON.stringify(values),
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const update = async (values: any, id: number, path: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
        body: JSON.stringify(values),
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const updateData = async (values: any, path: string) => {
    const response = await fetch(`${environment.api.baseUrl}/${path}`, {
      method: 'PATCH',
      headers: {
        Auth: getCookie(environment.app.cookieName) || '',
      },
      body: values,
    });
    const res = await response.json();
    if (res.error) throw res.error;
    return res;
  };

  const uploadData = async (values: any, path: string) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}`, {
        method: 'POST',
        headers: {
          Auth: getCookie(environment.app.cookieName) || '',
        },
        body: values,
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const deleteMany = async (ids: number[], path: string, otherValues = {}) => {
    const valueBody = {
      ids,
      ...otherValues,
    };
    try {
      const response = await fetch(`${environment.api.baseUrl}/${path}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
        body: JSON.stringify({ ...valueBody }),
      });
      const body = await response.json();
      return body;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const login = async (body: any) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/admin/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const updatePassword = async (body: any) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/admin/auth/changePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
          cognitoToken: getCookie(environment.app.authCookieName) || '',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const authChallenge = async (body: any) => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/admin/auth/authChallenge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie('authChallenge') || '',
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const generatorEmailList = async () => {
    try {
      const response = await fetch(`${environment.api.baseUrl}/admin/user/emailList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Auth: getCookie(environment.app.cookieName) || '',
        },
      });
      return await response.json();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const checkEmailExist = async (email: string) => {
    const response = await fetch(`${environment.api.baseUrl}/check-email/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    if (result.error) throw result.error;
  };

  return {
    getIndex,
    getManyById,
    getOne,
    update,
    create,
    deleteMany,
    updateData,
    uploadData,
    login,
    updatePassword,
    authChallenge,
    generatorEmailList,
    checkEmailExist,
  };
};
