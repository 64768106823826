export const TABLE_TYPES = [
  {
    label: 'Project',
    db_name: 'core_project',
  },
  {
    label: 'Heath Authority',
    db_name: 'core_healthauthority',
  },
  {
    label: 'Health Authority Region',
    db_name: 'core_healthauthorityregion',
  },
  {
    label: 'Hospital',
    db_name: 'core_hospital',
  },
  {
    label: 'University',
    db_name: 'core_university',
  },
  {
    label: 'Health Category',
    db_name: 'core_healthcategory',
  },
  {
    label: 'Researcher',
    db_name: 'core_researcher',
  },
  {
    label: 'User',
    db_name: 'core_user',
  },
  {
    label: 'Volunteer',
    db_name: 'core_volunteer',
  },
  {
    label: 'Project Interest',
    db_name: 'core_projectinterest',
  },
  {
    label: 'Study Result',
    db_name: 'core_studyresult',
  },
  {
    label: 'PPO Result',
    db_name: 'core_pporesult',
  },
];
