export const dashboardTables = [
  {
    tableName: 'core',
    tableNamePath: '/core',
    tableData: [
      {
        name: 'projects',
        namePath: '/core/project',
        addPath: '',
        changePath: '/core/project',
      },
      {
        name: 'project interests',
        namePath: '/core/projectinterest',
        addPath: '',
        changePath: '/core/projectinterest',
      },
      {
        name: 'Study results',
        namePath: '/core/studyresult',
        addPath: '',
        changePath: '/core/studyresult',
      },
      {
        name: 'PPO results',
        namePath: '/core/pporesult',
        addPath: '',
        changePath: '/core/pporesult',
      },
      {
        name: 'users',
        namePath: '/core/user',
        addPath: '/core/user/add',
        changePath: '/core/user',
      },
      {
        name: 'Volunteers',
        namePath: '/core/volunteer',
        addPath: '/core/volunteer/add',
        changePath: '/core/volunteer',
      },
      {
        name: 'researchers',
        namePath: '/core/researcher',
        addPath: '/core/researcher/add',
        changePath: '/core/researcher',
      },
      {
        name: 'health categories',
        namePath: '/core/healthcategory',
        addPath: '/core/healthcategory/add',
        changePath: '/core/healthcategory',
      },
      {
        name: 'hospitals',
        namePath: '/core/hospital',
        addPath: '/core/hospital/add',
        changePath: '/core/hospital',
      },
      {
        name: 'universities',
        namePath: '/core/university',
        addPath: '/core/university/add',
        changePath: '/core/university',
      },
      {
        name: 'health authorities',
        namePath: '/core/healthauthority',
        addPath: '/core/healthauthority/add',
        changePath: '/core/healthauthority',
      },
      {
        name: 'health authority regions',
        namePath: '/core/healthauthorityregion',
        addPath: '/core/healthauthorityregion/add',
        changePath: '/core/healthauthorityregion',
      },
    ],
  },
  {
    tableName: 'admin',
    tableNamePath: '/admin',
    tableData: [
      {
        name: 'admin user',
        namePath: '/admin/adminUser',
        addPath: '/admin/adminUser/add',
        changePath: '/admin/adminUser',
      },
      {
        name: 'generator email List',
        namePath: '/admin/generatorList/add',
        addPath: '',
        changePath: '',
      },
      {
        name: 'upload user csv file',
        namePath: '/admin/csvUploader/add',
        addPath: '/admin/csvUploader/add',
        changePath: '',
      },
      {
        name: 'generator Table csv file',
        namePath: '/admin/generatorTableCvs/add',
        addPath: '',
        changePath: '',
      },
    ],
  },
];
