import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextH1 } from '../Text';
import { fonts } from '../../styles/theme';
import * as Styled from './styles';
import { LinkComponent } from '../Link';

type PageTitleProps = {
  title: string
  linkUrl: string
  buttonText: string
  useIcon?: boolean
  showButton?: boolean
  disabled?: boolean
}

const PageTitle = ({
  title, linkUrl, buttonText, useIcon, showButton, disabled,
}: PageTitleProps) => (
  <Styled.PageTitleStyled>
    <TextH1 textAlgin="left" font={fonts.font1.H20}>{title}</TextH1>
    {showButton && !disabled && (
      <LinkComponent url={linkUrl} theme="button">
        <Styled.LinkButtonStyled>
          {buttonText}
          {useIcon
            && (
              <Styled.IconWrapper>
                <FontAwesomeIcon icon="plus" />
              </Styled.IconWrapper>
            )}
        </Styled.LinkButtonStyled>
      </LinkComponent>
    )}
  </Styled.PageTitleStyled>
);

export default PageTitle;
