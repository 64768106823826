import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';

type TableCellStyledProps = {
  readonly width?: string;
}

export const TableStyled = styled.table`
  width: 100%;
  margin-bottom: 1.875rem;
  ${fonts.font1.H14}
`;

export const TableHeaderStyled = styled.th.attrs({
  colSpan: 3,
})`
  color: ${colours.colour2};
  background: ${colours.colour4};
  text-align: left;
  text-transform: uppercase;
  padding: 0.5rem;
`;

export const TableRowStyled = styled.tr`
  border-bottom: 1px solid ${colours.colour9};
  `;

export const TableCellStyled = styled.td<TableCellStyledProps>`
  padding: 0.5rem;
  width: ${({ width }) => width || 'auto'};
  text-transform: capitalize;
`;

export const TableIconWrapper = styled.span`
  margin-right: 0.5rem;
`;

export const TextWrapper = styled.div`
  width: fit-content;
`;
