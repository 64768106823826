import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import EditFooterBar from '../../components/EditFooterBar';
import ErrorMessage from '../../components/ErrorMessage';
import { FileInput } from '../../components/FileInput';
import PageTitle from '../../components/PageTitle';
import { TextP } from '../../components/Text';
import { useUploadCsv } from '../../hooks';
import * as Styled from '../../styles/EditDeleteStyles';
import { colours, fonts } from '../../styles/theme';
import { breachcrumbPath } from '../../utils/breadcrumbConst';

const UploadCsv = () => {
  const {
    nameSingle,
    file,
    setFile,
    handleUploadFile,
    faildedUsers,
    toastMessage,
    errors,
  } = useUploadCsv();

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.admin,
        breachcrumbPath.uploadCsv,
      ]}
    >
      <PageTitle
        title={`Add ${nameSingle}`}
        linkUrl="/"
        buttonText="history"
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>Upload CSV File</TextP>

      <Styled.FormWrapper>
        {errors.fetch && (
          <ErrorMessage message={errors.fetch} />
        )}

        {faildedUsers && (
          <Styled.FailedWrapper>
            {faildedUsers.map((falidUser) => (
              <TextP>
                {falidUser.email}:{' '}
                {falidUser.error}
              </TextP>
            ))}
          </Styled.FailedWrapper>
        )}

        <Styled.InputWrapper>
          <FileInput
            label="CSV File:"
            handleChange={(value) => setFile(value)}
            field="file"
            fileName={file?.name}
            useSubLabel={false}
            fileType=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleUploadFile}
        urlPath="/"
        showNew={false}
        showEdit={false}
      />
    </AppWrapper>
  );
};

export default UploadCsv;
