import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import TextInput from '../../../components/InputText';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useHospital } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const HospitalEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    hospital,
    toastMessage,
    handleChange,
    getHospital,
    handleSubmitButton,
    setPageType,
    setHospital,
  } = useHospital();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getHospital(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setHospital({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.hospitals,
        { url: '', name: hospital.name || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl=""
        buttonText=""
        showButton={false}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      <Styled.FormWrapper>
        <TextInput
          id="name"
          label="name:"
          handleHandle={handleChange}
          inputValue={hospital.name || ''}
          keyName="name"
        />
      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${hospital.id}`}
        showDelete={pageType === 'change'}
      />
    </AppWrapper>
  );
};

export default HospitalEdit;
