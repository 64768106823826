import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const CheckboxGroupStyled = styled.div`
  display: flex;
`;

export const MainLabelStyled = styled.div`
  width: 170px;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const CheckboxWrapper = styled.div`
  margin: .25rem 0;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
  ${fonts.font1.H14B};
  margin-top: 0.875rem;
`;
