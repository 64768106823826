import styled from 'styled-components';

export const BodyWrapper = styled.main`
  padding: 0 1rem;
  margin: auto;
`;

export const AppWrapperStyle = styled.div`
  max-width: 100%;
`;
