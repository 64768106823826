export const createToastMessage = (messageType: string, type: string, name: string, path = '') => {
  let message;
  switch (messageType) {
    case 'add':
      message = {
        start: `The ${type} “`,
        end: `” was changed successfully. You may add another ${type} below.`,
        link: path,
        linkText: name,
      };
      break;
    case 'edit':
      message = {
        start: `The ${type} “`,
        end: '” was changed successfully. You may edit it again below.',
        link: path,
        linkText: name,
      };
      break;
    case 'delete':
      message = {
        start: `The ${type} “${name}” was deleted successfully.`,
        end: '',
      };
      break;
    case 'deletes':
      message = {
        start: `Successfully deleted ${name} ${type}.`,
        end: '',
      };
      break;
    case 'save':
      message = {
        start: `The ${type} “`,
        end: '” was changed successfully.',
        link: path,
        linkText: name,
      };
      break;
    case 'upload':
      message = {
        start: 'File successfully uploaded',
        end: '',
        link: '',
        linkText: '',
      };
      break;
    default:
      message = {
        start: '',
        end: '',
        link: '',
        linkText: '',
      };
      break;
  }
  return message;
};
