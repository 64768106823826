import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import InputSelect from '../../../components/InputSelect';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { TextArea } from '../../../components/InputTextArea';
import { useProject } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import {
  fundingAgencyOptions, gendersEligibleOptions, levelsOfEngagementsOptions,
  projectStatus, projectStatusOptions, projectTypeOptions, regionalCentersOptions,
  researcherPositionOptions, sexEligibleOptions, TypeOfPpo, TypeOfResearchStudy,
} from '../../../utils/const';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputText from '../../../components/InputText';
import InputAddRemove from '../../../components/InputAddRemove';
import CheckboxGroup from '../../../components/CheckboxGroup';
import InputNumber from '../../../components/InputNumber';
import { FileInput } from '../../../components/FileInput';
import FormLabelText from '../../../components/FormLabelText';
import { formateDate } from '../../../utils/formateDate';
import { colours, fonts } from '../../../styles/theme';

const ProjectEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    project,
    toastMessage,
    researcherList,
    teamMembers,
    checkedTeamMembers,
    healthCategories,
    healthAuthorityRegions,
    hospitals,
    rebFile,
    additionalDoc,
    errors,
    handleChange,
    handleCheckArray,
    handleChangeArray,
    getProject,
    handleSubmitButton,
    setPageType,
    setProject,
    addRemoveTeamMembers,
    addRemoveAllTeamMembers,
    handleCheckedTeamMembers,
    handleChangeTeamMember,
    addRemoveListMembers,
    addRemoveAllListMembers,
    handleFile,
    setSearchQuery,
    searchQuery,
    handleGetSignedUrl,
  } = useProject();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getProject(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setProject({});
    }

    if (location?.search) {
      const searchInfo = (location.search.split('='))[1];
      setSearchQuery(searchInfo);
    }
  }, [location]);

  useEffect(() => {
    handleCheckedTeamMembers(teamMembers);
  }, [teamMembers]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        searchQuery ? {
          name: breachcrumbPath.project.name,
          url: `${breachcrumbPath.project.url}?search=${searchQuery}`,
        } : breachcrumbPath.project,
        { url: '', name: project?.title || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl={`/${baseUrl}/${project?.id}/history`}
        buttonText="history"
        disabled={!project?.id}
        showButton={pageType === 'change'}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      <Styled.FormWrapper>
        <Styled.InputWrapper>
          <InputSelect
            label="Type:"
            onChange={() => ''}
            options={projectTypeOptions}
            selectedValue={project?.type || ''}
            disabled
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <InputSelect
              label="Study Type:"
              onChange={(value) => handleChange(value, 'study_type')}
              options={TypeOfResearchStudy}
              selectedValue={project?.study_type || ''}
              placeholder="----------"
              error={errors.study_type}
            />
          </Styled.InputWrapper>
        )}

        {project?.type === 'ppo' && (
          <Styled.InputWrapper>
            <InputSelect
              label="Ppo type:"
              onChange={(value) => handleChange(value, 'ppo_type')}
              options={TypeOfPpo}
              selectedValue={project?.ppo_type || ''}
              placeholder="----------"
              error={errors.ppo_type}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <TextArea
            label="Title"
            textValue={project?.title || ''}
            handleHandle={(value) => handleChange(value, 'title')}
            keyName="title"
            error={errors.title}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <TextArea
              label="Lay Title"
              textValue={project?.lay_title || ''}
              handleHandle={(value) => handleChange(value, 'lay_title')}
              keyName="lay_title"
              error={errors.lay_title}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <TextArea
            label="headline"
            textValue={project?.nick_name || ''}
            handleHandle={(value) => handleChange(value, 'nick_name')}
            keyName="nick_name"
            error={errors.nick_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Principal Investigator:"
            onChange={(value) => handleChange(value, 'pi_id')}
            options={researcherList}
            placeholder="----------"
            selectedValue={project?.pi_id || ''}
            error={errors.pi_id}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <InputCheckbox
              label="patient oriented project"
              checkedValue={project?.patient_oriented_project || false}
              handleChange={(value) => handleChange(value, 'patient_oriented_project')}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <InputText
            id="pp_co_pi"
            label="Patient Partner Co-Investigator Name:"
            inputValue={`${project?.pp_co_pi}` || ''}
            handleHandle={handleChange}
            keyName="pp_co_pi"
            padding={false}
            theme="form"
            error={errors.pp_co_pi}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="pp_affiliated_organization"
            label="Patient Partner Affiliated Organization:"
            inputValue={`${project?.pp_affiliated_organization}` || ''}
            handleHandle={handleChange}
            keyName="pp_affiliated_organization"
            padding={false}
            theme="form"
            error={errors.pp_affiliated_organization}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="collaborators:"
            textValue={project?.collaborators?.join(', ') || ''}
            handleHandle={(value) => handleChangeArray(value, 'collaborators')}
            keyName="collaborators"
            error={errors.collaborators}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="collaborating organizations:"
            textValue={project?.collaborating_organizations?.join(', ') || ''}
            handleHandle={(value) => handleChangeArray(value, 'collaborating_organizations')}
            keyName="collaborating_organizations"
            error={errors.collaborating_organizations}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={researcherList}
            selectOptions={teamMembers}
            handleUpdate={addRemoveTeamMembers}
            handleAllUpdate={addRemoveAllTeamMembers}
            label="additional team members"
            error={errors.teamMembers}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={teamMembers.map((member) => ({ ...member, value: member.value.toString() }))}
            selectValues={checkedTeamMembers}
            mainLabel="Manage Email Notifications for Team Members"
            handleChange={(checked, value) => handleChangeTeamMember(checked, value)}
            error={errors.additional_team_member}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="BC Support Unit Affilation"
            checkedValue={project?.bc_support_unit || false}
            handleChange={(value) => handleChange(value, 'bc_support_unit')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={regionalCentersOptions}
            selectValues={project?.regional_centers || []}
            mainLabel="Regional Centers:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'regional_centers')
            }
            error={errors.regional_centers}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <InputCheckbox
              label="this is a clinical trial"
              checkedValue={project?.is_clinical_trial || false}
              handleChange={(value) => handleChange(value, 'is_clinical_trial')}
            />
          </Styled.InputWrapper>
        )}

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <InputText
              id="registration_num"
              label="Clinical Trials registration number:"
              inputValue={`${project?.registration_num}` || ''}
              handleHandle={handleChange}
              keyName="registration_num"
              padding={false}
              theme="form"
              error={errors.registration_num}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={fundingAgencyOptions}
            selectValues={project?.funding_agency || []}
            mainLabel="Funding Agency:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'funding_agency')
            }
            error={errors.funding_agency}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="Study Purpose:"
            textValue={project?.purpose || ''}
            handleHandle={(value) => handleChange(value, 'purpose')}
            keyName="purpose"
            error={errors.purpose}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="What is Involved:"
            textValue={project?.description || ''}
            handleHandle={(value) => handleChange(value, 'description')}
            keyName="description"
            error={errors.description}
          />
        </Styled.InputWrapper>

        {project?.type === 'ppo' && (
          <>
            <Styled.InputWrapper>
              <CheckboxGroup
                checkboxOptions={levelsOfEngagementsOptions}
                selectValues={project?.level_of_engagement || []}
                mainLabel="level of engagement:"
                handleChange={
                  (checked, value) => handleCheckArray(checked, value, 'level_of_engagement')
                }
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <TextArea
                label="orientation provided:"
                textValue={project?.orientation_provided || ''}
                handleHandle={(value) => handleChange(value, 'orientation_provided')}
                keyName="orientation_provided"
                error={errors.orientation_provided}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <TextArea
                label="background:"
                textValue={project?.background || ''}
                handleHandle={(value) => handleChange(value, 'background')}
                keyName="background"
                error={errors.background}
              />
            </Styled.InputWrapper>
          </>
        )}

        <Styled.InputWrapper>
          <TextArea
            label="Estimated Commitment:"
            textValue={project?.commitment || ''}
            handleHandle={(value) => handleChange(value, 'commitment')}
            keyName="commitment"
            error={errors.commitment}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <>
            <Styled.InputWrapper>
              <InputCheckbox
                label="procedures required"
                checkedValue={project?.procedures_required || false}
                handleChange={(value) => handleChange(value, 'procedures_required')}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <TextArea
                label="procedures description:"
                textValue={project?.procedures_description || ''}
                handleHandle={(value) => handleChange(value, 'procedures_description')}
                keyName="procedures_description"
                error={errors.procedures_description}
              />
            </Styled.InputWrapper>
          </>
        )}

        <Styled.InputWrapper>
          <InputCheckbox
            label="Compensation/Gifts Offered"
            checkedValue={project?.compensation || false}
            handleChange={(value) => handleChange(value, 'compensation')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="Other Expenses Reimbursed"
            checkedValue={project?.expenses || false}
            handleChange={(value) => handleChange(value, 'expenses')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="min_age"
            label="Minimum Age (months):"
            inputValue={project?.min_age}
            handleHandle={handleChange}
            keyName="min_age"
            padding={false}
            error={errors.min_age}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="max_age"
            label="Maximum Age (months):"
            inputValue={project?.max_age}
            handleHandle={handleChange}
            keyName="max_age"
            padding={false}
            error={errors.max_age}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <Styled.InputWrapper>
            <InputCheckbox
              label="Are Healthy controls eligible"
              checkedValue={project?.healthy_controls || false}
              handleChange={(value) => handleChange(value, 'healthy_controls')}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={gendersEligibleOptions}
            selectValues={project?.genders_eligible || []}
            mainLabel="Gender Eligibility:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'genders_eligible')
            }
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={sexEligibleOptions}
            selectValues={project?.sex_eligible || []}
            mainLabel="Sex Eligibility:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'sex_eligible')
            }
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <>
            <Styled.InputWrapper>
              <TextArea
                label="inclusion criteria:"
                textValue={project?.inclusion_criteria || ''}
                handleHandle={(value) => handleChange(value, 'inclusion_criteria')}
                keyName="inclusion_criteria"
                error={errors.inclusion_criteria}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <TextArea
                label="exclusion criteria:"
                textValue={project?.exclusion_criteria || ''}
                handleHandle={(value) => handleChange(value, 'exclusion_criteria')}
                keyName="exclusion_criteria"
                error={errors.exclusion_criteria}
              />
            </Styled.InputWrapper>
          </>
        )}

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={healthCategories}
            selectOptions={project?.health_categories || []}
            handleUpdate={(list, add) => addRemoveListMembers(list, add, 'health_categories')}
            handleAllUpdate={(add) => addRemoveAllListMembers(add, 'health_categories')}
            label="health categories"
            error={errors.health_categories}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={healthAuthorityRegions}
            selectOptions={project?.health_authority_region || []}
            handleUpdate={(list, add) => addRemoveListMembers(list, add, 'health_authority_region')}
            handleAllUpdate={(add) => addRemoveAllListMembers(add, 'health_authority_region')}
            label="region"
            error={errors.health_authority_region}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="online"
            checkedValue={project?.is_online || false}
            handleChange={(value) => handleChange(value, 'is_online')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="has Online study link"
            checkedValue={project?.has_direct_link || false}
            handleChange={(value) => handleChange(value, 'has_direct_link')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="direct_link_url"
            label="Online Study Link URL:"
            inputValue={`${project?.direct_link_url}` || ''}
            handleHandle={handleChange}
            keyName="direct_link_url"
            padding={false}
            theme="form"
            error={errors.direct_link_url}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="target_patients"
            label="Patients:"
            inputValue={project?.target_patients}
            handleHandle={handleChange}
            keyName="target_patients"
            padding={false}
            error={errors.target_patients}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputNumber
            id="target_controls"
            label="Controls:"
            inputValue={project?.target_controls}
            handleHandle={handleChange}
            keyName="target_controls"
            padding={false}
            error={errors.target_controls}
          />
        </Styled.InputWrapper>

        {project?.type === 'ppo' && (
          <Styled.InputWrapper>
            <TextArea
              label="additional eligibility:"
              textValue={project?.additional_eligibility || ''}
              handleHandle={(value) => handleChange(value, 'additional_eligibility')}
              keyName="additional_eligibility"
              error={errors.additional_eligibility}
            />
          </Styled.InputWrapper>
        )}

        <Styled.InputWrapper>
          <InputSelect
            label="hospitals:"
            onChange={(value) => handleChange(value, 'hospital_id')}
            options={hospitals}
            placeholder="----------"
            selectedValue={project?.hospital_id || ''}
            error={errors.hospital_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="building"
            label="building name:"
            inputValue={`${project?.building}` || ''}
            handleHandle={handleChange}
            keyName="building"
            padding={false}
            theme="form"
            error={errors.building}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="department"
            label="department:"
            inputValue={`${project?.department}` || ''}
            handleHandle={handleChange}
            keyName="department"
            padding={false}
            theme="form"
            error={errors.department}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="street_address"
            label="street_address:"
            inputValue={`${project?.street_address}` || ''}
            handleHandle={handleChange}
            keyName="street_address"
            padding={false}
            theme="form"
            error={errors.street_address}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="city"
            label="city:"
            inputValue={`${project?.city}` || ''}
            handleHandle={handleChange}
            keyName="city"
            padding={false}
            theme="form"
            error={errors.city}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="postal_code"
            label="post code:"
            inputValue={`${project?.postal_code}` || ''}
            handleHandle={handleChange}
            keyName="postal_code"
            padding={false}
            theme="form"
            error={errors.postal_code}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="contact1_name"
            label="contact 1 name:"
            inputValue={`${project?.contact1_name}` || ''}
            handleHandle={handleChange}
            keyName="contact1_name"
            padding={false}
            theme="form"
            error={errors.contact1_name}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputSelect
            label="contact 1 position:"
            onChange={(value) => handleChange(value, 'contact1_position')}
            options={researcherPositionOptions}
            placeholder="----------"
            selectedValue={project?.contact1_position || ''}
            error={errors.contact1_position}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact1_email"
            label="contact 1 email:"
            inputValue={`${project?.contact1_email}` || ''}
            handleHandle={handleChange}
            keyName="contact1_email"
            padding={false}
            theme="form"
            error={errors.contact1_email}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact1_phone"
            label="contact 1 phone:"
            inputValue={`${project?.contact1_phone}` || ''}
            handleHandle={handleChange}
            keyName="contact1_phone"
            padding={false}
            theme="form"
            error={errors.contact1_phone}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact1_phone_extension"
            label="contact 1 phone extension:"
            inputValue={`${project?.contact1_phone_extension}` || ''}
            handleHandle={handleChange}
            keyName="contact1_phone_extension"
            padding={false}
            theme="form"
            error={errors.contact1_phone_extension}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="contact2_name"
            label="contact 2 name:"
            inputValue={`${project?.contact2_name}` || ''}
            handleHandle={handleChange}
            keyName="contact2_name"
            padding={false}
            theme="form"
            error={errors.contact2_name}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputSelect
            label="contact 2 position:"
            onChange={(value) => handleChange(value, 'contact2_position')}
            options={researcherPositionOptions}
            placeholder="----------"
            selectedValue={project?.contact2_position || ''}
            error={errors.contact2_position}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact2_email"
            label="contact 2 email:"
            inputValue={`${project?.contact2_email}` || ''}
            handleHandle={handleChange}
            keyName="contact2_email"
            padding={false}
            theme="form"
            error={errors.contact2_email}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact2_phone"
            label="contact 2 phone:"
            inputValue={`${project?.contact2_phone}` || ''}
            handleHandle={handleChange}
            keyName="contact2_phone"
            padding={false}
            theme="form"
            error={errors.contact2_phone}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="contact2_phone_extension"
            label="contact 2 phone extension:"
            inputValue={`${project?.contact2_phone_extension}` || ''}
            handleHandle={handleChange}
            keyName="contact2_phone_extension"
            padding={false}
            theme="form"
            error={errors.contact2_phone_extension}
          />
        </Styled.InputWrapper>

        {project?.type === 'study' && (
          <>
            <Styled.InputWrapper>
              <InputCheckbox
                label="REB approved"
                checkedValue={project?.reb_certificate || false}
                handleChange={(value) => handleChange(value, 'reb_certificate')}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <InputText
                id="reb_number"
                label="REB Certificate Number:"
                inputValue={`${project?.reb_number}` || ''}
                handleHandle={handleChange}
                keyName="reb_number"
                padding={false}
                theme="form"
                error={errors.reb_number}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <FileInput
                label="REB Filename:"
                handleChange={handleFile}
                fileLink={project?.rebFileLink}
                field="reb_file"
                fileName={rebFile?.name || project?.reb_file}
                getSignedUrl={handleGetSignedUrl}
              />
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <InputText
                id="reb_expiry"
                label="REB Expiry Date:"
                inputValue={`${project?.reb_expiry}` || ''}
                handleHandle={handleChange}
                keyName="reb_expiry"
                padding={false}
                theme="form"
                error={errors.reb_expiry}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <FileInput
                label="Additional Supporting Document:"
                handleChange={handleFile}
                fileLink={project?.additionalDocLink}
                field="additional_doc"
                fileName={additionalDoc?.name || project?.additional_doc}
                getSignedUrl={handleGetSignedUrl}
              />
            </Styled.InputWrapper>
          </>
        )}

        <Styled.InputWrapper>
          <TextArea
            label="Comments:"
            textValue={project?.comments || ''}
            handleHandle={(value) => handleChange(value, 'comments')}
            keyName="comments"
            error={errors.comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="Admin Comments:"
            textValue={project?.admin_comments || ''}
            handleHandle={(value) => handleChange(value, 'admin_comments')}
            keyName="admin_comments"
            error={errors.admin_comments}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="status:"
            onChange={(value) => handleChange(value, 'status')}
            options={projectStatusOptions}
            placeholder="----------"
            selectedValue={project?.status || ''}
            error={errors.status}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper capitalize>
          <FormLabelText
            label="desired status:"
            textValue={projectStatus[project?.desired_status || ''] || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="recruitment_end"
            label="Recruitment End Date:"
            inputValue={`${project?.recruitment_end}` || ''}
            handleHandle={handleChange}
            keyName="recruitment_end"
            padding={false}
            theme="form"
            error={errors.recruitment_end}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Project expiry actions last performed date:"
            textValue={
              project?.will_expire_actions_last_performed
                ? formateDate(new Date(project?.will_expire_actions_last_performed))
                : '-'
            }
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Last updated by:"
            textValue={project?.updated_by || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Project created at:"
            textValue={project?.created_at ? new Date(project?.created_at).toDateString() : '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Date Open for Recruitment:"
            textValue={project?.recruitment_open_date ? new Date(project?.recruitment_open_date).toDateString() : '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Created by:"
            textValue={project?.created_by || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Volunteer Matches"
            textValue={project?.volunteerCount || '-'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="Admin to Review"
            checkedValue={project?.admin_to_review || false}
            handleChange={(value) => handleChange(value, 'admin_to_review')}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>

      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${project?.id}`}
        showNew={false}
        showDelete
      />
    </AppWrapper>
  );
};

export default ProjectEdit;
