export const formateDate = (date: Date) => {
  const year = new Date(date).getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
  const day = String(new Date(date).getUTCDate()).padStart(2, '0');
  return `${year}-${(`0${month}`).slice(-2)}-${(`0${day}`).slice(-2)}`;
};

export const formateTime = (date: Date) => {
  const hour = new Date(date).getHours();
  const minutes = String(new Date(date).getMinutes() + 1).padStart(2, '0');
  const seconds = String(new Date(date).getSeconds()).padStart(2, '0');
  return `${hour}:${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}`;
};

export const nameTime = (date: Date) => new Date(date).toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const nameDate = (date: Date) => new Date(date).toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const numericalDate = (date: Date) => new Date(date).toLocaleDateString('en-CA', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});
