import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { researchersColumns } from '../../../utils/const';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useResearcher } from '../../../hooks';

const Researcher = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterResearchers,
    researchers,
    selectResearchers,
    getResearchers,
    handleCheck,
    handleSearch,
    searchFilter,
    setSearchFilter,
  } = useResearcher();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getResearchers(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.researcher]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterResearchers.length}
        total={researchers.length}
      />
      <TableBasic
        data={filterResearchers || []}
        columns={researchersColumns}
        selectedList={selectResearchers}
        handleCheck={handleCheck}
        footerText={`${filterResearchers.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default Researcher;
