import styled from 'styled-components';
import { fonts } from '../../styles/theme';

type LabelStyledProps = {
  readonly theme?: string
}

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  width: ${({ theme }) => (theme === 'secondary' ? 'auto' : '170px')};
  display: inline-block;
  text-transform: capitalize;
  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.font1.H13;
      default:
        return fonts.font1.H13B;
    }
  }}
`;

export const PostLabelStyled = styled.label`
  display: inline-block;
  ${fonts.font1.H13}
`;
