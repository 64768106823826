export const newVolunteerConst = {
  for_child: false,
  onsite_presence: false,
  receive_matching_email: true,
  travel_distance: 'any',
};

export const newResearcherConst = {
  is_pi: false,
};
