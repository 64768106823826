import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import TableHistory from '../../../components/TableHistory';
import { useProjectInterest } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { historyColumns } from '../../../utils/const';

const ProjectInterestHistory = () => {
  const params = useParams();

  const {
    nameSingle,
    baseUrl,
    projectInterest,
    getHistoryLog,
    history,
  } = useProjectInterest();

  useEffect(() => {
    if (params.id) {
      getHistoryLog(params.id);
    }
  }, [params]);

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.projectInterest,
        {
          url: `${breachcrumbPath.projectInterest.url}/${projectInterest?.id}/change`,
          name: `ProjectInterest object (${projectInterest?.id})`,
        },
        { url: '', name: 'history' },
      ]}
    >
      <PageTitle
        title="Change history: "
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
      />
      <div>
        <TableHistory
          columns={historyColumns}
          data={history}
          footerText=""
        />
      </div>
    </AppWrapper>
  );
};

export default ProjectInterestHistory;
