import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import FormLabelText from '../../../components/FormLabelText';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputNumber from '../../../components/InputNumber';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import { TextArea } from '../../../components/InputTextArea';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useUser } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { notificationFrequencyOptions } from '../../../utils/const';
import { formateDate, formateTime } from '../../../utils/formateDate';

const UserEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    user,
    toastMessage,
    handleChange,
    getUser,
    handleSubmitButton,
    setPageType,
    setUser,
    errors,
    editEmail,
    setEditEmail,
    updatedEmail,
    handleEmailChange,
  } = useUser();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getUser(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
      setEditEmail(true);
    }
    if (!params?.id) {
      setUser({
        notification_frequency: 'weekly',
        receive_news: false,
        is_active: true,
        is_researcher: false,
        is_volunteer: false,
      });
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.user,
        { url: '', name: user?.email || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl=""
        buttonText=""
        showButton={false}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="last login:"
            textValue={user?.last_login ? `Date: ${formateDate(new Date(user?.last_login))}` : 'Date: -'}
            secondaryTextValue={user?.last_login ? `Time: ${formateTime(new Date(user?.last_login))}` : 'Time: -'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="phone_number"
            label="Phone number:"
            inputValue={`${user?.phone_number}` || ''}
            handleHandle={handleChange}
            keyName="phone_number"
            padding={false}
            theme="form"
            error={errors.phone_number}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="extension"
            label="extension:"
            inputValue={user?.extension}
            handleHandle={handleChange}
            keyName="extension"
            padding={false}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="notification:"
            onChange={(value) => handleChange(value, 'notification_frequency')}
            options={notificationFrequencyOptions}
            selectedValue={user?.notification_frequency || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="receive news:"
            checkedValue={user?.receive_news || false}
            handleChange={(value) => handleChange(value, 'receive_news')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="volunteer status:"
            checkedValue={user?.is_volunteer || false}
            handleChange={() => ''}
            disabled
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="researcher status:"
            checkedValue={user?.is_researcher || false}
            handleChange={() => ''}
            disabled
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="first_name"
            label="first name:"
            inputValue={`${user?.first_name}` || ''}
            handleHandle={handleChange}
            keyName="first_name"
            padding={false}
            theme="form"
            error={errors.first_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="last_name"
            label="last name:"
            inputValue={`${user?.last_name}` || ''}
            handleHandle={handleChange}
            keyName="last_name"
            padding={false}
            theme="form"
            error={errors.last_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <Styled.FlexBoxStyled>
            <InputText
              id="email"
              label="email:"
              inputValue={pageType === 'add' ? user.email || '' : updatedEmail}
              handleHandle={handleChange}
              keyName={pageType === 'add' ? 'email' : 'newEmail'}
              padding={false}
              theme="form"
              error={errors.email || errors.updatedEmail}
              disabled={!editEmail}
            />
            {pageType !== 'add' && (
              <Button
                onClick={() => {
                  if (editEmail) {
                    handleEmailChange();
                  } else {
                    setEditEmail(!editEmail);
                  }
                }}
                theme="plain"
              >
                <Styled.FlexBoxStyled>
                  {editEmail ? (
                    <>
                      Done
                    </>
                  ) : (
                    <>
                      <Styled.IconWrapper>
                        <FontAwesomeIcon icon="pencil" color={colours.colour10} />
                      </Styled.IconWrapper>
                      Change
                    </>
                  )}
                </Styled.FlexBoxStyled>
              </Button>
            )}
            {updatedEmail !== user.email && pageType !== 'add' && (
              <Button
                onClick={() => {
                  handleChange(user?.email || '', 'newEmail');
                  setEditEmail(false);
                }}
                theme="plain"
              >
                <Styled.FlexBoxStyled>
                  Reset
                </Styled.FlexBoxStyled>
              </Button>
            )}
          </Styled.FlexBoxStyled>
          {updatedEmail !== user.email && !editEmail && pageType !== 'add' && (
            <Styled.MessageWrapper>
              <TextP>
                Click save to update user&apos;s email.
              </TextP>
              <TextP>
                This will cause an email to be sent to the new email address with a new password.
              </TextP>
            </Styled.MessageWrapper>
          )}
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <FormLabelText
            label="Learned about:"
            textValue={user?.learned_about_reachbc ? user.learned_about_reachbc : ''}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <FormLabelText
            label="Learned about Other:"
            textValue={user?.learned_about_reachbc_other ? user.learned_about_reachbc_other : '-'}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputCheckbox
            label="active:"
            checkedValue={user?.is_active || false}
            handleChange={(value) => handleChange(value, 'is_active')}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputCheckbox
            label="confirmed:"
            checkedValue={user?.confirmed || false}
            handleChange={(value) => handleChange(value, 'confirmed')}
            disabled
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <FormLabelText
            label="Date joined:"
            textValue={user?.date_joined ? `Date: ${formateDate(new Date(user?.date_joined))}` : 'Date: -'}
            secondaryTextValue={user?.date_joined ? `Time: ${formateTime(new Date(user?.date_joined))}` : 'Time: -'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <TextArea
            label="Admin Comments:"
            textValue={user.admin_comments || ''}
            handleHandle={(value) => handleChange(value, 'admin_comments')}
            keyName="summary"
            error={errors.summary}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${user?.id}`}
        showNew
        showDelete
        disableAll={pageType !== 'add' && editEmail}
      />
    </AppWrapper>
  );
};

export default UserEdit;
