import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import { TextH1, TextP } from '../../components/Text';
import { fonts } from '../../styles/theme';
import { usePasswordChange } from '../../hooks';
import * as Styled from '../../styles/changePasswordStyles';
import { breachcrumbPath } from '../../utils/breadcrumbConst';
import Button from '../../components/Button';
import PasswordInput from '../../components/InputPassword';

const ChangePassword = () => {
  const {
    password,
    setPassword,
    oldPassword,
    setOldPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    handleChangePassword,
    successful,
    errors,
  } = usePasswordChange();

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.changePassword,
      ]}
    >
      <Styled.PageWraper>
        {successful ? (
          <div>
            <TextH1 textAlgin="left" font={fonts.font1.H20}>
              Password change successful
            </TextH1>
            <Styled.TextWrapper>
              <TextP font={fonts.font1.H13}>
                Your password was changed.
              </TextP>
            </Styled.TextWrapper>
          </div>
        ) : (
          <>
            <TextH1 textAlgin="left" font={fonts.font1.H20}>
              Password change
            </TextH1>
            <Styled.TextWrapper>
              {Object.keys(errors).length > 0
                && (
                  <Styled.ErrorStyled>
                    <TextP font={fonts.font1.H11}>
                      Please correct the errors below.
                    </TextP>
                  </Styled.ErrorStyled>
                )}
              <TextP font={fonts.font1.H13}>
                Please enter your old password, for security’s sake, and then enter your new password twice so we can verify you typed it in correctly.
              </TextP>
            </Styled.TextWrapper>

            <Styled.InputWrapper>
              <PasswordInput
                id="oldPassword"
                label="old password:"
                handleHandle={(value) => setOldPassword(value)}
                inputValue={oldPassword}
                error={errors?.oldPassword}
              />
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <PasswordInput
                id="newPassword"
                label="new password:"
                handleHandle={(value) => setPassword(value)}
                inputValue={password}
                error={errors?.password}
              />
              <Styled.DiscriptionWrapper>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password can’t be too similar to your other personal information.
                </TextP>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password must contains at least 1 number.
                </TextP>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password must contains at least 1 uppercase letter
                </TextP>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password must contains at least 1 lowercase letter
                </TextP>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password must contains at least 1 special character
                </TextP>
                <TextP font={fonts.font1.H11} lineHeight="2">
                  Your password must contain at least 8 characters.
                </TextP>
              </Styled.DiscriptionWrapper>
            </Styled.InputWrapper>

            <Styled.InputWrapper>
              <PasswordInput
                id="newPasswordConfirmation:"
                label="New password confirmation:"
                handleHandle={(value) => setPasswordConfirmation(value)}
                inputValue={passwordConfirmation}
                error={errors?.passwordConfirmation}
              />
            </Styled.InputWrapper>

            <Styled.ButtonWrapper>
              <Button
                onClick={handleChangePassword}
              >
                Change my password
              </Button>
            </Styled.ButtonWrapper>
          </>
        )}
      </Styled.PageWraper>
    </AppWrapper>
  );
};

export default ChangePassword;
