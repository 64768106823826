import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type TextAreaInputProps = {
  readonly isError?: boolean
}

export const LabelStyled = styled.label`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const TextAreaStyled = styled.textarea<TextAreaInputProps>`
  width: 610px;
  border-radius: 4px;
  padding: 0.35rem;
  box-shadow: none;
  border: 1px solid ${({ isError }) => (isError ? colours.colour18 : colours.colour23)};
  ${fonts.font1.H13}

  &:disabled {
    cursor: not-allowed;
    background: ${colours.colour1};
    color: ${colours.colour6};
    opacity: 0.9;
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
  ${fonts.font1.H14B}
`;
