import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { coreVolunteerSchema, createVolunteerSchema } from '../utils/validations';
import { newVolunteerConst } from '../utils/userConst';
import { useApi } from './useApi';

export const useVolunteer = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, update, deleteMany, create,
  } = useApi();

  const baseUrl = 'core/volunteer';
  const controllerName = 'admin/volunteers';
  const name = 'volunteers';
  const nameSingle = 'volunteer';

  const [volunteers, setVolunteers] = useState<ICoreVolunteerIndex[] | []>([]);
  const [filterVolunteers, setFilterVolunteers] = useState<ICoreVolunteerIndex[] | []>([]);

  const [volunteer, setVolunteer] = useState<ICoreVolunteer>({});
  const [saveName, setSavedName] = useState('');

  const [allUsers, setAllUsers] = useState([]);
  const [healthAffilation, setHealthAffilation] = useState<{ label: string, value: number }[]>([]);
  const [healthCategories, setHealthCategories] = useState<{ label: string, value: number }[]>([]);
  const [siteCodes, setSiteCodes] = useState([]);

  const [selectVolunteers, setSelectVolunteers] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');
  const [selectedSiteCore, setSelectedSiteCore] = useState('');

  const [searchFilter, setSearchFilter] = useState('');
  const [healthAffilationFilter, setHealthAffilationFilter] = useState('');

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(true);

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.volunteer,
    ];
    if (volunteers.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.volunteer.url}/${volunteers[0].id}/change`, name: volunteers[0].email });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number| boolean, field: string) => {
    setVolunteer((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectVolunteers.includes(id)) {
      setSelectVolunteers((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectVolunteers((oldArray) => [...oldArray, id]);
    }
  };

  const handleCheckArray = (checked: boolean, value: string, keyName: string) => {
    let currentArray: string[] = [];
    switch (keyName) {
      case 'gender':
        currentArray = volunteer?.gender || [];
        break;
      case 'project_type_preference':
        currentArray = volunteer?.project_type_preference || [];
        break;
      case 'ethnicity':
        currentArray = volunteer?.ethnicity || [];
        break;
      default:
        break;
    }
    if (!currentArray) return;
    if (checked) {
      currentArray.push(value);
    } else {
      currentArray = currentArray.filter((item: string) => item !== value);
    }
    setVolunteer({
      ...volunteer,
      [keyName]: currentArray,
    });
  };

  const addRemoveListMembers = (ids: number[], add: boolean, keyName: string) => {
    if (keyName === 'health_categories') {
      if (add) {
        const newMember = healthCategories?.filter((cate) => ids.includes(cate.value)) || [];
        setVolunteer({
          ...volunteer,
          health_categories: [...volunteer?.health_categories || [], ...newMember],
        });
      } else {
        const newList = volunteer?.health_categories?.filter((member) => !ids.includes(member.value)) || [];
        setVolunteer({
          ...volunteer,
          health_categories: [...newList],
        });
      }
    }

    if (keyName === 'affilation_health_region') {
      if (add) {
        const newMember = healthAffilation?.filter((cate) => ids.includes(cate.value)) || [];
        setVolunteer({
          ...volunteer,
          affilation_health_region: [...volunteer?.affilation_health_region || [], ...newMember],
        });
      } else if (volunteer.travel_distance === 'any' && !volunteer.affilation_health_region?.length) {
        const newList = healthAffilation.filter((member) => !ids.includes(member.value)) || [];
        setVolunteer({
          ...volunteer,
          affilation_health_region: [...newList],
          travel_distance: 'health_authority',
        });
      } else {
        const newList = volunteer?.affilation_health_region?.filter((member) => !ids.includes(member.value)) || [];
        setVolunteer({
          ...volunteer,
          affilation_health_region: [...newList],
          travel_distance: volunteer.travel_distance === 'any' && newList.length < healthAffilation.length ? 'health_authority' : volunteer.travel_distance,
        });
      }
    }
  };

  const addRemoveAllListMembers = (add: boolean, keyName: string) => {
    if (keyName === 'health_categories') {
      if (add) {
        setVolunteer({
          ...volunteer,
          health_categories: [...healthCategories],
        });
      } else {
        setVolunteer({
          ...volunteer,
          health_categories: [],
        });
      }
    }
    if (keyName === 'affilation_health_region') {
      if (add) {
        setVolunteer({
          ...volunteer,
          affilation_health_region: [...healthAffilation],
        });
      } else {
        setVolunteer({
          ...volunteer,
          affilation_health_region: [],
        });
      }
    }
  };

  // API Calls
  const getVolunteers = async (isIndex = false) => {
    try {
      const volunteerList = await getIndex(controllerName);
      setVolunteers(volunteerList.volunteers);
      setSiteCodes(volunteerList.siteCodes);
      setHealthAffilation(volunteerList.healthAuthorities || []);
      if (isIndex) {
        setFilterVolunteers(volunteerList.volunteers);
      }
      setIsLoading(false);
    } catch (error: any) {
      setErrors({ message: error.message });
      setIsLoading(false);
    }
  };

  const getVolunteer = async (id: number | string) => {
    try {
      const volunteerInfo = await getOne(id, controllerName);
      if (volunteerInfo.volunteer) {
        setVolunteer(volunteerInfo.volunteer);
        setSavedName(`${volunteerInfo.volunteer.first_name} ${volunteerInfo.volunteer.last_name}(${volunteerInfo.volunteer.email})`);
        setSelectVolunteers(volunteerInfo.id);
      } else {
        setVolunteer(newVolunteerConst);
      }
      setAllUsers(volunteerInfo.users || []);
      setHealthAffilation(volunteerInfo.healthAuthorities || []);
      setHealthCategories(volunteerInfo.healthCategories || []);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getVolunteersById = async (ids: number[]) => {
    try {
      const volunteerInfo = await getManyById(ids, controllerName);
      if (volunteerInfo.error) throw volunteerInfo;
      setVolunteers(volunteerInfo);
    } catch (error: any) {
      setErrors({ message: error.message || error.error });
    }
  };

  const createNewVolunteer = async (values: any) => {
    try {
      const newVolunteer = await create(values, controllerName);
      return newVolunteer;
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const updateVolunteer = async (values: any, id: number) => {
    try {
      const updatedVolunteer = await update(values, id, controllerName);
      return updatedVolunteer;
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const deleteVolunteer = async (ids: number[]) => {
    const results = await deleteMany(ids, controllerName);
    if (results.error) throw results;
    return results;
  };

  const handleCreate = async (type: string) => {
    setErrors({});
    try {
      const validedValues = await createVolunteerSchema.validate({
        ...volunteer,
      }, { abortEarly: false });
      const volunteerInfo = await createNewVolunteer(validedValues);
      if (volunteerInfo.error) throw new Error(volunteerInfo.error);
      if (!volunteerInfo?.id) throw new Error('Failed to create volunteer');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, volunteerInfo.email, `/${baseUrl}/${volunteerInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, volunteerInfo.email, `/${baseUrl}/${volunteerInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, volunteerInfo.email, `/${baseUrl}/${volunteerInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${volunteerInfo.id}/change`);
      }
      if (type === 'save') {
        setVolunteer({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!volunteer.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues = await coreVolunteerSchema.validate({
        ...volunteer,
      }, { abortEarly: false, stripUnknown: true });
      const updateVolunteerInfo = await updateVolunteer(validedValues, volunteer.id);
      if (updateVolunteerInfo?.error) throw updateVolunteerInfo.error;
      if (!updateVolunteerInfo?.id) throw new Error(`Failed to update ${nameSingle}`);
      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage(
            'edit',
            nameSingle,
            `${volunteer.first_name} ${volunteer.last_name}(${volunteer.email})`,
            `/${baseUrl}/${updateVolunteerInfo.id}/change`,
          );
          break;
        case 'save':
          message = createToastMessage(
            'save',
            nameSingle,
            `${volunteer.first_name} ${volunteer.last_name}(${volunteer.email})`,
            `/${baseUrl}/${updateVolunteerInfo.id}/change`,
          );
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'save') {
        setVolunteer({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else if (error.message) {
        setErrors({ fetch: error.message });
      } else {
        const errorMessage = await JSON.parse(error);
        setErrors({ ...errorMessage });
      }
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteVolunteer(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, `${volunteers[0].first_name} ${volunteers[0].last_name}(${volunteers[0].email})` || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      setErrors({ message: error.error });
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  const handleUpdateFilter = (value: string | number, key: string) => {
    if (typeof value === 'number') return;
    switch (key) {
      case 'healthAffilation':
        setHealthAffilationFilter(value);
        break;
      case 'siteCode':
        setSelectedSiteCore(value);
        break;
      case 'search':
        setSearchFilter(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = async (query?: any) => {
    if (query === 'CLEAR') {
      setIsLoading(true);
      setSelectedSiteCore('');
      setHealthAffilationFilter('');
      setSearchFilter('');
      const searchValue = await getIndex(controllerName);
      setFilterVolunteers(searchValue.volunteers);
      setIsLoading(false);
      return searchValue;
    }

    let searchQuerySearch: any = searchFilter || query;
    if (searchQuerySearch?.includes('&')) searchQuerySearch = searchQuerySearch.replaceAll('&', 'ANDSYMBOL');
    if (searchQuerySearch?.includes("'")) searchQuerySearch = searchQuerySearch.replace("'", '&apos;');
    if (searchQuerySearch?.includes('+')) searchQuerySearch = searchQuerySearch.replaceAll('+', 'PLUSSYMBOL');
    if (searchQuerySearch?.includes('#')) searchQuerySearch = searchQuerySearch.replaceAll('#', 'HASHTAG');
    if (searchQuerySearch?.includes('%')) searchQuerySearch = searchQuerySearch.replaceAll('%', 'PERCENTSYBMOL');

    const params = selectedSiteCore !== '' ? ['search=true', `site_code=${selectedSiteCore}`] : ['search=true'];
    if (healthAffilationFilter) params.push(`health_affilation=${healthAffilationFilter}`);
    if (searchQuerySearch) {
      params.push(`searchQuery=${searchQuerySearch}`);
    }
    const searchValue = await getIndex(controllerName, `?${params.join('&')}`);
    setFilterVolunteers(searchValue.volunteers);
    return searchValue;
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectVolunteers } });
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    volunteer,
    volunteers,
    selectVolunteers,
    pageType,
    setPageType,
    toastMessage,
    filterVolunteers,
    getVolunteer,
    setSelectVolunteers,
    getVolunteers,
    createNewVolunteer,
    handleSubmitButton,
    setVolunteer,
    selectedValue,
    setSelectedValue,
    getVolunteersById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
    handleSearch,
    setSearchFilter,
    handleMenuAction,
    handleCheckArray,
    searchFilter,
    errors,
    allUsers,
    siteCodes,
    healthAffilation,
    handleUpdateFilter,
    setHealthAffilationFilter,
    healthAffilationFilter,
    selectedSiteCore,
    healthCategories,
    addRemoveListMembers,
    addRemoveAllListMembers,
    isLoading,
  };
};
