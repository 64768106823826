import styled from 'styled-components';
import { colours, fonts } from './theme';

export const PageWraper = styled.section`
  padding: 1.25rem 2.5rem;
`;

export const InputWrapper = styled.div`
  padding: 0.625rem 0;
  border-bottom: 1px solid ${colours.colour17};
`;

export const TextWrapper = styled.div`
  padding: 1.25rem 0 0;
  ${fonts.font1.H14};
`;

export const DiscriptionWrapper = styled.div`
  padding-left: 180px;
`;

export const ButtonWrapper = styled.div`
  padding: 0.75rem 0.875rem;
  margin: 0 0 1.25rem;
  background: ${colours.colour12};
  border: 1px solid ${colours.colour17};
  border-radius: 4px;
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const ErrorStyled = styled.div`
  ${fonts.font1.H14B};
  padding: 0.75rem 0.875rem;
  margin: 0 0 0.75rem 0;
  color: ${colours.colour19};
  border: 1px solid ${colours.colour19};
  border-radius: 4px;
`;
