import React from 'react';
import { fonts } from '../../styles/theme';
import { TextP } from '../Text';
import * as Styled from './styles';

type TextInputProps = {
  label: string
  textValue: string
  secondaryTextValue?: string
}
const FormLabelText = ({ label, textValue, secondaryTextValue }: TextInputProps) => (
  <Styled.InputWrapper>
    <Styled.LabelStyled>
      {label}
    </Styled.LabelStyled>
    <div>
      <TextP font={fonts.font1.H13}>{textValue}</TextP>
      {secondaryTextValue
        && (
          <Styled.TextWrapper>
            <TextP font={fonts.font1.H13}>{secondaryTextValue}</TextP>
          </Styled.TextWrapper>
        )}
    </div>
  </Styled.InputWrapper>
);

export default FormLabelText;
