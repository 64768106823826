export const projectStatus: {[key: string]: string} = {
  on_hold: 'On Hold',
  draft: 'Draft',
  reb_review: 'REB Review',
  approved: 'Currently Recruiting',
  closed_recruitment: 'Closed Recruitment',
  completed_project: 'Project Completed',
  pending_approval: 'Pending Approval',
};

export const resultStatus: {[key: string]: string} = {
  approved: 'Results Approved',
  pending_approval: 'Results Pending Approval',
};

export const interestStatus: {[key: string]: string} = {
  interested: 'interested',
  not_interested: 'not interested',
  not_eligible: 'not eligible',
  withdraw_interest: 'withdrawn interest',
};

export const enrollmentStatus: {[key: string]: string} = {
  not_contacted: 'Have not been contacted',
  follow_up: 'Follow up in progress',
  enrolled: 'Enrolled',
  ineligible: 'Not Enrolled - Ineligible',
  not_interested: 'Not Enrolled - Not interested',
  too_busy: 'Not Enrolled - Too busy',
  recruitment_closed: 'Not enrolled - Closed Recruitment',
  contact_attempt: 'Not Enrolled - Attempted contact, no response',
  already_enrolled: 'Not Enrolled - Already enrolled in another research study',
  participation_unknown: 'Participation unknown - Study link sent',
  other: 'Not Enrolled - Other',
};

export const projectInterestTableColumns = [
  {
    name: 'project id', key: 'project_id', width: '7%', type: 'text',
  },
  {
    name: 'headline', key: 'project_name', width: '30%', type: 'text',
  },
  {
    name: 'project status', key: 'status', width: '10%', const: projectStatus, type: 'text',
  },
  {
    name: 'Volunteer ID', key: 'volunteer_id', width: '8%', type: 'text',
  },
  {
    name: 'interest', key: 'interest', width: '8%', const: interestStatus, type: 'link',
  },
  {
    name: 'date of interest', key: 'created_at', width: '8%', type: 'date',
  },
  {
    name: 'enrollment status', key: 'enrollment_status', width: '15%', const: enrollmentStatus, type: 'text',
  },
  {
    name: 'researcher notified', key: 'researcher_notified', width: '10%', type: 'icon',
  },
];

export const projectTableColumns = [
  {
    name: 'id', key: 'id', width: '3%', type: 'link',
  },
  {
    name: 'project result', key: 'result_id', width: '9%', type: 'link', linkPath: 'core/',
  },
  {
    name: 'type', key: 'type', width: '5%', type: 'text',
  },
  {
    name: 'headline', key: 'project_name', width: '25%', type: 'text',
  },
  {
    name: 'status', key: 'status', width: '7%', const: projectStatus, type: 'text',
  },
  {
    name: 'project team', key: 'project_team', multiKeys: ['pi', 'created_id', 'team_members'], width: '25%', type: 'multi', multiLabels: ['PI', 'created by', 'team member'],
  },
  {
    name: 'recruitment end date', key: 'recruitment_end', width: '15%', type: 'date',
  },
  {
    name: 'Admin to Review', key: 'admin_to_review', width: '10%', type: 'circle',
  },
  {
    name: 'updated at', key: 'updated_at', width: '10%', type: 'date', dateType: 'all',
  },
  {
    name: 'last updated by', key: 'updated_by', width: '15%', type: 'text',
  },
];

export const resultsColumns = [
  {
    name: 'id', key: 'id', width: '3%', type: 'link',
  },
  {
    name: 'project id', key: 'project_id', width: '8%', type: 'projectlink',
  },
  {
    name: 'headline', key: 'project_name', width: '25%', type: 'text',
  },
  {
    name: 'project team', key: 'project_team', multiKeys: ['pi', 'created_by_user', 'team_members'], width: '20%', type: 'multi', multiLabels: ['PI', 'created by', 'team member'],
  },
  {
    name: 'project status', key: 'project_status', width: '9%', const: projectStatus, type: 'text',
  },
  {
    name: 'completed Project', key: 'completed', width: '10%', type: 'checkIcon',
  },
  {
    name: 'status', key: 'result_status', width: '10%', const: resultStatus, type: 'text',
  },
  {
    name: 'Admin to Review', key: 'admin_to_review', width: '10%', type: 'circle',
  },
  {
    name: 'updated at', key: 'result_updated_at', width: '7%', type: 'date',
  },
  {
    name: 'Last updated by', key: 'updated_by_user', width: '15%', type: 'text',
  },
];

export const usersColumns = [
  {
    name: 'volunteer ID', key: 'volunteer_id', width: '8%', type: 'text',
  },
  {
    name: 'first name', key: 'first_name', width: '20%', type: 'link',
  },
  {
    name: 'last name', key: 'last_name', width: '20%', type: 'text',
  },
  {
    name: 'account type', key: 'account_type', width: '12%', type: 'text',
  },
  {
    name: 'email', key: 'email', width: '40%', type: 'text',
  },
  {
    name: 'date joined', key: 'date_joined', width: '20%', type: 'date',
  },
  {
    name: 'active', key: 'is_active', width: '8%', type: 'icon',
  },
];

export const researchersColumns = [
  {
    name: 'email', key: 'email', width: '30%', type: 'link',
  },
  {
    name: 'position', key: 'position', width: '15%', type: 'text',
  },
  {
    name: 'health authority affiliation', key: 'ha_affiliation', width: '20%', type: 'text',
  },
  {
    name: 'academic affiliation', key: 'academic_affiliation', width: '25%', type: 'text',
  },
];

export const volunteersColumns = [
  {
    name: 'id', key: 'id', width: '5%', type: 'text',
  },
  {
    name: 'user', key: 'email', width: '25%', type: 'link',
  },
  {
    name: 'Date Joined', key: 'date_joined', width: '10%', type: 'date', dateType: 'numeric',
  },
  {
    name: 'affiliated health authority region', key: 'affiliated_health_authority_region', width: '20%', type: 'text',
  },
  {
    name: 'site registration code', key: 'site_code', width: '15%', type: 'text',
  },
  {
    name: 'user is active', key: 'is_active', width: '8%', type: 'icon',
  },
];

export const adminUsersColumns = [
  {
    name: 'first name', key: 'first_name', width: '20%', type: 'link',
  },
  {
    name: 'last name', key: 'last_name', width: '20%', type: 'text',
  },
  {
    name: 'email', key: 'email', width: '50%', type: 'text',
  },
  {
    name: 'active', key: 'is_active', width: '8%', type: 'icon',
  },
];

export const researcherProjectsColumns = [
  {
    name: 'id', key: 'project_id', width: '5%', type: 'text',
  },
  {
    name: 'nick name', key: 'nick_name', width: '50%', type: 'text',
  },
  {
    name: 'project status', key: 'status', width: '10%', const: projectStatus, type: 'text',
  },
  {
    name: 'updated At', key: 'updated_at', width: '8%', type: 'date',
  },
];

export const historyColumns = [
  {
    name: 'Date/Time', key: 'updated_at', width: '20%', type: 'date',
  },
  {
    name: 'user', key: 'user_info', width: '25%', type: 'text',
  },
  {
    name: 'action', key: 'log', width: '50%', type: 'text',
  },
];
export const projectStatusFilterOptions = [
  { value: '', label: 'Project Status' },
  { value: '', label: 'All' },
  { value: 'on_hold', label: 'On Hold' },
  { value: 'reb_review', label: 'REB Review' },
  { value: 'approved', label: 'Currently Recruiting' },
  { value: 'closed_recruitment', label: 'Closed Recruitment' },
  { value: 'completed_project', label: 'Project Completed' },
  { value: 'pending_approval', label: 'Pending Approval' },
  { value: 'draft', label: 'Draft' },
];

export const resultStatusFilterOptions = [
  { value: '', label: 'Result Status' },
  { value: '', label: 'All' },
  { value: 'approved', label: 'Results Approved' },
  { value: 'pending_approval', label: 'Results Pending Approval' },
];

export const enrollmentStatusOptions = [
  { value: '', label: 'Enrollment Status' },
  { value: '', label: 'All' },
  { value: 'not_contacted', label: 'Have not been contacted' },
  { value: 'follow_up', label: 'Follow up in progress' },
  { value: 'enrolled', label: 'Enrolled' },
  { value: 'ineligible', label: 'Not Enrolled - Ineligible' },
  { value: 'not_interested', label: 'Not Enrolled - Not interested' },
  { value: 'too_busy', label: 'Not Enrolled - Too busy' },
  { value: 'recruitment_closed', label: 'Not enrolled - Closed Recruitment' },
  { value: 'contact_attempt', label: 'Not Enrolled - Attempted contact, no response' },
  { value: 'already_enrolled', label: 'Not Enrolled - Already enrolled in another research study' },
  { value: 'participation_unknown', label: 'Participation unknown - Study link sent' },
  { value: 'other', label: 'Not Enrolled - Other' },
];

export const userAccountTypeOptions = [
  { value: '', label: 'Account Type' },
  { value: '', label: 'All' },
  { value: 'Researcher', label: 'Researcher' },
  { value: 'Volunteer', label: 'Volunteer' },
];

export const projectInterestOptions = [
  { value: 'interested', label: 'Interested' },
  { value: 'not_interested', label: 'Not Interested' },
  { value: 'not_eligible', label: 'Not Eligible' },
  { value: 'withdraw_interest', label: 'Withdrawn Interest' },
];

export const projectTypeOptions = [
  { value: 'study', label: 'Study' },
  { value: 'ppo', label: 'PPO' },
];

export const TypeOfResearchStudy = [
  { value: 'Biomedical', label: 'Biomedical' },
  { value: 'Clinical', label: 'Clinical' },
  { value: 'Health systems and services', label: 'Health systems and services' },
  { value: 'Social, cultural, environmental and population health', label: 'Social, cultural, environmental and population health' },
  { value: 'Other', label: 'Other' },
];

export const TypeOfPpo = [
  { value: 'Advisory Boards and Committees', label: 'Advisory Boards and Committees' },
  { value: 'Patient-Centered Outcomes Research (PCOR)', label: 'Patient-Centered Outcomes Research (PCOR)' },
  { value: 'Focus Groups and Interviews', label: 'Focus Groups and Interviews' },
  { value: 'Community-Based Participatory Research (CBPR)', label: 'Community-Based Participatory Research (CBPR)' },
  { value: 'Online Patient Communities', label: 'Online Patient Communities' },
  { value: 'Other', label: 'Other' },
];

export const regionalCentersOptions = [
  { value: 'fraser', label: 'Fraser Centre' },
  { value: 'vancouver', label: 'Vancouver Centre' },
  { value: 'northern', label: 'Northern Centre' },
  { value: 'interior', label: 'Interior Centre' },
  { value: 'vancouver_island', label: 'Vancouver Island Centre' },
];

export const fundingAgencyOptions = [
  {
    value: 'For-profit company (e.g. industry sponsor)',
    label: 'For-profit company (e.g. industry sponsor)',
  },
  {
    value: 'Michael Smith Health Research BC',
    label: 'Michael Smith Health Research BC',
  },
  {
    value: 'Government or non-profit foundation (e.g. Canadian Institutes of Health Research)',
    label: 'Government or non-profit foundation (e.g. Canadian Institutes of Health Research)',
  },
  { value: 'Not funded', label: 'Not funded' },
];

export const gendersEligibleOptions = [
  { value: 'Woman', label: 'Woman' },
  { value: 'Man', label: 'Man' },
  { value: 'Non-binary', label: 'Non-binary' },
  { value: 'Trans woman', label: 'Trans woman' },
  { value: 'Trans man', label: 'Trans man' },
  { value: 'Two-spirit', label: 'Two-spirit' },
];

export const userGendersEligibleOptions = [
  { value: 'Woman', label: 'Woman' },
  { value: 'Man', label: 'Man' },
  { value: 'Non-binary', label: 'Non-binary' },
  { value: 'Trans woman', label: 'Trans woman' },
  { value: 'Trans man', label: 'Trans man' },
  { value: 'Two-spirit', label: 'Two-spirit' },
  { value: 'Other', label: 'Other' },
  { value: 'Decline to State', label: 'Decline to state' },
];

export const sexEligibleOptions = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

export const userSexEligibleOptions = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Inter-sex', label: 'Inter-sex' },
  { value: 'Decline to State', label: 'Decline to state' },
];

export const projectTypePreferenceOptions = [
  { value: 'study', label: 'Study' },
  { value: 'ppo', label: 'PPO' },
];

export const researcherPositionOptions = [
  {
    value: 'Research Assistant',
    label: 'Research Assistant',
  },
  {
    value: 'Research Coordinator',
    label: 'Research Coordinator',
  },
  {
    value: 'Graduate Student',
    label: 'Graduate Student',
  },
  {
    value: 'Principal Investigator',
    label: 'Principal Investigator',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const projectStatusOptions = [
  { value: 'on_hold', label: 'On Hold' },
  { value: 'draft', label: 'Draft' },
  { value: 'reb_review', label: 'REB Review' },
  { value: 'approved', label: 'Currently Recruiting' },
  { value: 'closed_recruitment', label: 'Closed Recruitment' },
  { value: 'completed_project', label: 'Project Completed' },
  { value: 'pending_approval', label: 'Pending Approval' },
];

export const resultStatusOptions = [
  { value: 'approved', label: 'Results Approved' },
  { value: 'pending_approval', label: 'Results Pending Approval' },
];

export const levelsOfEngagementsOptions = [
  {
    value: 'inform',
    label:
      'inform: To provide the public with balanced and objective information to assist them in understanding the problems, alternatives and/or solutions.',
  },
  {
    value: 'consult',
    label: 'consult: To obtain public feedback on analysis, alternatives and/or decision.',
  },
  {
    value: 'involve',
    label:
      'involve: To work directly with the public throughout the process to ensure that public concerns and aspirations are consistently understood and considered.',
  },
  {
    value: 'collaborate',
    label:
      'collaborate: To partner with the public in each aspect of the decision including the development of alternatives and the identification of the preferred solution.',
  },
  {
    value: 'empower',
    label: 'empower: To place final decision-making in the hands of the public.',
  },
];

export const notificationFrequencyOptions = [
  { value: 'weekly', label: 'Once a week' },
  { value: 'biweekly', label: 'Once every two weeks' },
  { value: 'monthly', label: 'Once a month' },
];

export const travelDistanceOptions = [
  { value: 'any', label: 'Travel to any study location' },
  { value: 'health_authority', label: 'Selected health authority' },
  { value: 'remote_only', label: 'online only' },
];

export const relationshipToUserOptions = [
  { value: 'Myself', label: 'Myself' },
  { value: 'Parent', label: 'Parent' },
  { value: 'Child', label: 'Child' },
  { value: 'Spouse/Partner', label: 'Spouse/Partner' },
  { value: 'Other Family Member', label: 'Other Family Member' },
  { value: 'GrandParent', label: 'GrandParent' },
  { value: 'Grandchild', label: 'Grandchild' },
  { value: 'Caregiver', label: 'Caregiver' },
  { value: 'Health care provider', label: 'Health care provider' },
  { value: 'Support worker', label: 'Support worker' },
  { value: 'Friend', label: 'Friend' },
  { value: 'Unknown', label: 'Unknown' },
  { value: 'Decline to state', label: 'Decline to state' },
  { value: 'Other', label: 'Other' },
];

export const profileEthnicityOptions = [
  { value: 'Arab', label: 'Arab' },
  { value: 'Black', label: 'Black' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Filipino', label: 'Filipino' },
  {
    value: 'Indigenous (First Nations, Metis, Inuit within North America)',
    label: 'Indigenous (First Nations, Metis, Inuit within North America)',
  },
  {
    value: 'Indigenous (outside North America)',
    label: 'Indigenous (outside North America)',
  },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Latin American', label: 'Latin American' },
  {
    value: 'South Asian (e.g., Indian, Pakistani, Sri Lankan)',
    label: 'South Asian (e.g., Indian, Pakistani, Sri Lankan)',
  },
  {
    value: 'Southeast Asian (e.g., Vietnamese, Cambodian, Laotian, Thai)',
    label: 'Southeast Asian (e.g., Vietnamese, Cambodian, Laotian, Thai)',
  },
  {
    value: 'West Asian (e.g., Iranian, Afghan)',
    label: 'West Asian (e.g., Iranian, Afghan)',
  },
  { value: 'White', label: 'White' },
  {
    value: 'Other group - specify in the text box below',
    label: 'Other group - specify in the text box below',
  },
  { value: 'Prefer not to answer', label: 'Prefer not to answer' },
];
