import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { projectschema } from '../utils/validations';
import { useApi } from './useApi';

export const StatusRecruitmentEndUpdate = ['closed_recruitment', 'completed_project'];

export const useProject = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, updateData, deleteMany,
  } = useApi();

  const baseUrl = 'core/project';
  const controllerName = 'admin/projects';
  const name = 'project';
  const nameSingle = 'project';

  const [projects, setProjects] = useState<IIndexProject[] | []>([]);
  const [filterProjects, setFilterProjects] = useState<IIndexProject[] | []>([]);
  const [status, setStatus] = useState('');

  const [project, setProject] = useState<IProject>();
  const [saveName, setSavedName] = useState('');

  const [researcherList, setResearcherList] = useState<{ label: string, value: number }[]>([]);
  const [teamMembers, setTeamMembers] = useState<{ label: string, value: number, receiveEmail?: boolean }[]>([]);
  const [checkedTeamMembers, setCheckedTeamMembers] = useState<string[]>([]);

  const [healthCategories, setHealthCategories] = useState<{ label: string, value: number }[]>([]);
  const [healthAuthorityRegions, setHealthAuthorityRegions] = useState<{ label: string, value: number }[]>([]);
  const [hospitals, setHospitals] = useState<{ label: string, value: number }[]>([]);

  const [selectProjects, setSelectProjects] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [selectedValue, setSelectedValue] = useState('');

  const [projectStatusFilter, setProjectStatusFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [rebFile, setRebFile] = useState<File>();
  const [additionalDoc, setAdditionalDoc] = useState<File>();

  const [history, setHistory] = useState<IHistoryLog[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(true);

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.project,
    ];
    if (projects.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.project.url}/${projects[0].id}/change`, name: projects[0].project_name || '' });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    if (project === undefined) return;
    setProject((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleChangeArray = (value: string, field: string) => {
    if (project === undefined) return;
    const arrayValue = value.split(', ');
    setProject((prev) => ({
      ...prev,
      [field]: arrayValue,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectProjects.includes(id)) {
      setSelectProjects((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectProjects((oldArray) => [...oldArray, id]);
    }
  };

  const handleCheckArray = (checked: boolean, value: string, keyName: string) => {
    let currentArray: string[] = [];
    switch (keyName) {
      case 'regional_centers':
        currentArray = project?.regional_centers || [];
        break;
      case 'level_of_engagement':
        currentArray = project?.level_of_engagement || [];
        break;
      case 'genders_eligible':
        currentArray = project?.genders_eligible || [];
        break;
      case 'sex_eligible':
        currentArray = project?.sex_eligible || [];
        break;
      case 'funding_agency':
        currentArray = project?.funding_agency || [];
        break;
      default:
        break;
    }
    if (!currentArray) return;
    if (checked) {
      currentArray.push(value);
    } else {
      currentArray = currentArray.filter((item) => item !== value);
    }
    setProject({
      ...project,
      [keyName]: currentArray,
    });

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  const handleChangeTeamMember = (checked: boolean, value: string) => {
    const newTeamMembers = teamMembers.map((member) => (member.value.toString() === value ? { ...member, receiveEmail: checked } : member));
    setTeamMembers(newTeamMembers);

    if (!changedFields.includes('additional_team_member')) {
      setChangedField((oldArray) => [...oldArray, 'additional_team_member']);
    }
  };

  // API Calls
  const getProjects = async (isIndex = false, queryInfo = false) => {
    try {
      const projectInfo = await getIndex(controllerName);
      setProjects(projectInfo);
      if (isIndex && !queryInfo) {
        setFilterProjects(projectInfo);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getProject = async (id: number) => {
    try {
      const projectInfo = await getOne(id, controllerName);
      projectInfo.projectInfo.is_online = projectInfo.projectInfo.is_online || false;
      projectInfo.projectInfo.recruitment_end = projectInfo.projectInfo.recruitment_end?.slice(0, 10);
      setProject(projectInfo.projectInfo);
      setSavedName(projectInfo.nick_name);
      setSelectProjects(projectInfo.id);
      setResearcherList(projectInfo.researchers);
      setTeamMembers(projectInfo.teamMembers);
      setHealthCategories(projectInfo.healthCategories);
      setHealthAuthorityRegions(projectInfo.healthAuthorityRegions);
      setHospitals(projectInfo.hospitals);
      setStatus(projectInfo.projectInfo.status);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getProjectsById = async (ids: number[]) => {
    try {
      const projectInfo = await getManyById(ids, controllerName);
      setProjects(projectInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const updateProject = async (values: any) => {
    const updatedProject = await updateData(values, controllerName);
    return updatedProject;
  };

  const deleteProject = async (ids: number[]) => {
    const results = await deleteMany(ids, controllerName);
    if (results.error) throw new Error(results.error);
    return results;
  };

  const getHistoryLog = async (id: string) => {
    try {
      const projectInfo = await getIndex(`${controllerName}/history/${id}`);
      setHistory(projectInfo.historyLogs);
      setProject(projectInfo.projectInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!project?.id) throw new Error(`Failed to update ${nameSingle}`);
      setErrors({});
      setToastMessage(undefined);
      const updatedValues: any = Object.fromEntries(Object.entries(project).filter(([_, v]) => v !== null));

      if (updatedValues.hospital_id === '----------') {
        delete updatedValues.hospital_id;
      }

      if (updatedValues.contact1_phone === '') {
        delete updatedValues.contact1_phone;
      }

      if (updatedValues.contact1_phone_extension === '') {
        delete updatedValues.contact1_phone_extension;
      }

      if (updatedValues.contact2_phone === '') {
        delete updatedValues.contact2_phone;
      }

      if (updatedValues.contact2_phone_extension === '') {
        delete updatedValues.contact2_phone_extension;
      }

      if (updatedValues.contact2_name === '') {
        delete updatedValues.contact2_name;
      }

      if (updatedValues.contact2_email === '') {
        delete updatedValues.contact2_email;
      }
      if (updatedValues.registration_num === '') {
        updatedValues.registration_num = null;
      }

      if (Number.isNaN(updatedValues.target_controls)) {
        delete updatedValues.target_controls;
      }

      if (Number.isNaN(updatedValues.target_patients)) {
        delete updatedValues.target_patients;
      }

      const changedFieldsData = [...changedFields];
      if (StatusRecruitmentEndUpdate.includes(updatedValues.status)) {
        updatedValues.recruitment_end = new Date();

        if (!changedFieldsData.includes('recruitment_end')) {
          changedFieldsData.push('recruitment_end');
        }
      }

      const vaildingTextArea: { [key: string]: string } = {};
      if (updatedValues.background) vaildingTextArea.background = updatedValues.background.replace(/\r?\n|\r/g, ' ');
      if (updatedValues.orientation_provided) vaildingTextArea.orientation_provided = updatedValues.orientation_provided.replace(/\r?\n|\r/g, ' ');
      if (updatedValues.additional_eligibility) vaildingTextArea.additional_eligibility = updatedValues.additional_eligibility.replace(/\r?\n|\r/g, ' ');
      if (updatedValues.inclusion_criteria) vaildingTextArea.inclusion_criteria = updatedValues.inclusion_criteria.replace(/\r?\n|\r/g, ' ');
      if (updatedValues.exclusion_criteria) vaildingTextArea.exclusion_criteria = updatedValues.exclusion_criteria.replace(/\r?\n|\r/g, ' ');
      if (updatedValues.procedures_description) vaildingTextArea.procedures_description = updatedValues.procedures_description.replace(/\r?\n|\r/g, ' ');

      await projectschema.validate({
        ...updatedValues,
        additional_team_member: teamMembers,
        purpose: updatedValues.purpose.replace(/\r?\n|\r/g, ' '),
        description: updatedValues.description.replace(/\r?\n|\r/g, ' '),
        commitment: updatedValues.commitment.replace(/\r?\n|\r/g, ' '),
        ...vaildingTextArea,
      }, { abortEarly: false, stripUnknown: true });

      const data = new FormData();

      updatedValues.health_categories = JSON.stringify(updatedValues.health_categories);
      updatedValues.health_authority_region = JSON.stringify(updatedValues.health_authority_region);
      updatedValues.statusChange = updatedValues.status !== status;
      if (updatedValues.status !== status) {
        updatedValues.oldStatus = status;
      }

      Object.keys(updatedValues).forEach((key) => {
        data.append(key, updatedValues[key]);
      });

      if (rebFile) {
        data.append('rebFile', rebFile);
      }
      if (additionalDoc) {
        data.append('additionalDoc', additionalDoc);
      }

      if (teamMembers.length > 0) {
        data.append('additional_team_member', JSON.stringify(teamMembers));
      }
      data.append('changedFields', changedFieldsData.filter((change: string) => change !== 'status').join(', '));

      const projectInfo = await updateProject(data);
      if (projectInfo.error) throw new Error(projectInfo.error);
      setChangedField([]);
      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage('edit', nameSingle, `Project object (${projectInfo.id})`, `/${baseUrl}/${projectInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `Project object (${projectInfo.id})`, `/${baseUrl}/${projectInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'save') {
        setProject({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message, searchQuery } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages: { [key: string]: string } = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else if (typeof error === 'string') {
        const err = await JSON.parse(error);
        setErrors({ ...err });
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteProject(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, projects[0]?.project_name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleSubmitButton = (type: string) => {
    handleUpdate(type);
  };

  const handleUpdateFilter = (value: string | number, key: string) => {
    if (typeof value === 'number') return;
    switch (key) {
      case 'status':
        setProjectStatusFilter(value);
        break;
      case 'search':
        setSearchFilter(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = async (query?: any) => {
    if (query === 'CLEAR') {
      const searchValue = await getIndex(controllerName);
      setFilterProjects(searchValue);
      return searchValue;
    }

    let searchQuerySearch: any = searchFilter || query;
    if (searchQuerySearch?.includes('&')) searchQuerySearch = searchQuerySearch.replaceAll('&', 'ANDSYMBOL');
    if (searchQuerySearch?.includes("'")) searchQuerySearch = searchQuerySearch.replace("'", '&apos;');
    if (searchQuerySearch?.includes('+')) searchQuerySearch = searchQuerySearch.replaceAll('+', 'PLUSSYMBOL');
    if (searchQuerySearch?.includes('#')) searchQuerySearch = searchQuerySearch.replaceAll('#', 'HASHTAG');
    if (searchQuerySearch?.includes('%')) searchQuerySearch = searchQuerySearch.replaceAll('%', 'PERCENTSYBMOL');
    const params = projectStatusFilter !== '' ? [`status=${projectStatusFilter}`] : [];
    if (searchQuerySearch) {
      params.push(`searchQuery=${searchQuerySearch}`);
    }

    const searchValue = await getIndex(controllerName, `?${params.join('&')}`);
    setFilterProjects(searchValue);
    return searchValue;
  };

  const addRemoveTeamMembers = (ids: number[], add: boolean) => {
    if (add) {
      let newMember = researcherList.filter((researcher) => ids.includes(researcher.value));
      newMember = newMember.map((member) => ({ ...member, receiveEmail: true }));
      setTeamMembers([...teamMembers, ...newMember]);
      const err = { ...errors };
      delete err.teamMembers;
      setErrors(err);
    } else {
      const newList = teamMembers.filter((member) => !ids.includes(member.value));
      if (newList.length < 1) {
        setErrors({
          ...errors,
          teamMembers: 'A new Additional Team Member must be added before you can remove this team member(s)',
        });
        return;
      }
      setTeamMembers(newList);
    }

    if (!changedFields.includes('additional_team_member')) {
      setChangedField((oldArray) => [...oldArray, 'additional_team_member']);
    }
  };

  const addRemoveAllTeamMembers = (add: boolean) => {
    if (add) {
      setTeamMembers([...teamMembers, ...researcherList]);
    } else {
      setTeamMembers([]);
    }
    if (!changedFields.includes('additional_team_member')) {
      setChangedField((oldArray) => [...oldArray, 'additional_team_member']);
    }
  };

  const addRemoveListMembers = (ids: number[], add: boolean, keyName: string) => {
    if (keyName === 'health_categories') {
      if (add) {
        const newMember = healthCategories?.filter((cate) => ids.includes(cate.value)) || [];
        setProject({
          ...project,
          health_categories: [...project?.health_categories || [], ...newMember],
        });
      } else {
        const newList = project?.health_categories?.filter((member) => !ids.includes(member.value)) || [];
        setProject({
          ...project,
          health_categories: [...newList],
        });
      }
    }

    if (keyName === 'health_authority_region') {
      if (add) {
        const newMember = healthAuthorityRegions?.filter((cate) => ids.includes(cate.value)) || [];
        setProject({
          ...project,
          health_authority_region: [...project?.health_authority_region || [], ...newMember],
        });
      } else {
        const newList = project?.health_authority_region?.filter((member) => !ids.includes(member.value)) || [];
        setProject({
          ...project,
          health_authority_region: [...newList],
        });
      }
    }

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  const addRemoveAllListMembers = (add: boolean, keyName: string) => {
    if (keyName === 'health_categories') {
      if (add) {
        setProject({
          ...project,
          health_categories: [...healthCategories],
        });
      } else {
        setProject({
          ...project,
          health_categories: [],
        });
      }
    }

    if (keyName === 'health_authority_region') {
      if (add) {
        setProject({
          ...project,
          health_authority_region: [...healthAuthorityRegions],
        });
      } else {
        setProject({
          ...project,
          health_authority_region: [],
        });
      }
    }

    if (!changedFields.includes(keyName)) {
      setChangedField((oldArray) => [...oldArray, keyName]);
    }
  };

  const handleFile = (value: File, field: string) => {
    switch (field) {
      case 'reb_file':
        setRebFile(value);
        break;
      case 'additional_doc':
        setAdditionalDoc(value);
        break;
      default:
        break;
    }
  };

  const handleGetSignedUrl = async (field: string) => {
    if (!project?.id) return;
    const results = await getOne(`${project.id}`, 'projects/signedUrl', `/${field}`);

    if (results) {
      const fileName = field === 'additional_doc' ? additionalDoc?.name || project?.additional_doc || 'reachBC_additional_doc' : rebFile?.name || project?.reb_file || 'reachBC_reb_file';
      const link = document.createElement('a');
      link.href = results;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleCheckedTeamMembers = (members: { label: string, value: number, receiveEmail?: boolean }[]) => {
    const checkedMembers = members.filter((member) => member.receiveEmail).map((member) => member.value.toString());
    setCheckedTeamMembers(checkedMembers);
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    project,
    filterProjects,
    projects,
    selectProjects,
    pageType,
    setPageType,
    toastMessage,
    getProject,
    setSelectProjects,
    getProjects,
    handleSubmitButton,
    setProject,
    selectedValue,
    setSelectedValue,
    getProjectsById,
    handleCheck,
    handleChange,
    handleUpdate,
    handleSearch,
    handleDelete,
    errors,
    handleUpdateFilter,
    searchFilter,
    setSearchFilter,
    projectStatusFilter,
    researcherList,
    handleChangeArray,
    handleCheckArray,
    teamMembers,
    addRemoveTeamMembers,
    addRemoveAllTeamMembers,
    checkedTeamMembers,
    handleCheckedTeamMembers,
    handleChangeTeamMember,
    healthCategories,
    addRemoveListMembers,
    addRemoveAllListMembers,
    healthAuthorityRegions,
    hospitals,
    handleFile,
    rebFile,
    additionalDoc,
    getBreadcrumbs,
    history,
    getHistoryLog,
    setSearchQuery,
    searchQuery,
    isLoading,
    setIsLoading,
    handleGetSignedUrl,
  };
};
