import { useState } from 'react';
import { createToastMessage } from '../utils/createToastMessage';
import { useApi } from './useApi';

export const useUploadCsv = () => {
  const { uploadData } = useApi();

  const controllerName = 'admin/user/uploadFile';
  const name = 'upload CSV';
  const nameSingle = 'upload CSV';

  const [file, setFile] = useState<File>();
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [faildedUsers, setFailedUsers] = useState<any[]>();

  const handleUploadFile = async () => {
    setErrors({});
    try {
      if (!file) throw new Error('Please select a csv file to upload');
      const data = new FormData();
      data.append('csvFile', file);

      const uploadRes = await uploadData(data, controllerName);
      if (uploadRes.error) throw new Error(uploadRes.error);
      if (uploadRes.failedUser) {
        setFailedUsers(uploadRes.failedUser);
        return;
      }
      if (uploadRes.success) {
        const message = createToastMessage('upload', '', '', '');
        setToastMessage(message);
      }
    } catch (error: any) {
      setErrors({ fetch: error.message });
    }
  };

  return {
    name,
    nameSingle,
    file,
    setFile,
    toastMessage,
    errors,
    handleUploadFile,
    faildedUsers,
  };
};
