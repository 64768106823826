import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import CheckboxGroup from '../../../components/CheckboxGroup';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import InputAddRemove from '../../../components/InputAddRemove';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputNumber from '../../../components/InputNumber';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useVolunteer } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import {
  profileEthnicityOptions,
  projectTypePreferenceOptions, relationshipToUserOptions, travelDistanceOptions, userGendersEligibleOptions, userSexEligibleOptions,
} from '../../../utils/const';

const VolunteerEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    volunteer,
    toastMessage,
    allUsers,
    healthAffilation,
    healthCategories,
    addRemoveListMembers,
    handleChange,
    getVolunteer,
    handleSubmitButton,
    setPageType,
    setVolunteer,
    handleCheckArray,
    addRemoveAllListMembers,
    errors,
  } = useVolunteer();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getVolunteer(parseInt(params.id, 10));
    } else {
      getVolunteer('info');
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setVolunteer({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.volunteer,
        { url: '', name: `${volunteer.first_name} ${volunteer.last_name}(${volunteer.email})` || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl={`${baseUrl}/add`}
        buttonText=""
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="user:"
            onChange={(value) => handleChange(value, 'user_id')}
            placeholder="----------"
            options={allUsers}
            selectedValue={volunteer?.user_id || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="birth_date"
            label="birth date:"
            inputValue={`${volunteer?.birth_date}` || ''}
            handleHandle={handleChange}
            keyName="birth_date"
            padding={false}
            theme="form"
            error={errors.birth_date}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={userGendersEligibleOptions}
            selectValues={volunteer?.gender || []}
            mainLabel="Gender:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'gender')
            }
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={profileEthnicityOptions}
            selectValues={volunteer?.ethnicity || []}
            mainLabel="ethnicity:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'ethnicity')
            }
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Sex:"
            onChange={(value) => handleChange(value, 'sex')}
            placeholder="----------"
            options={userSexEligibleOptions}
            selectedValue={volunteer?.sex || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <CheckboxGroup
            checkboxOptions={projectTypePreferenceOptions}
            selectValues={volunteer?.project_type_preference || []}
            mainLabel="project type preference:"
            handleChange={
              (checked, value) => handleCheckArray(checked, value, 'project_type_preference')
            }
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="site_code"
            label="site registration code:"
            inputValue={`${volunteer?.site_code}` || ''}
            handleHandle={handleChange}
            keyName="site_code"
            padding={false}
            theme="form"
            error={errors.site_code}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="relationship to user:"
            onChange={(value) => handleChange(value, 'relationship')}
            placeholder="----------"
            options={relationshipToUserOptions}
            selectedValue={volunteer?.relationship || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={healthCategories}
            selectOptions={volunteer.health_categories || []}
            handleUpdate={(list, add) => addRemoveListMembers(list, add, 'health_categories')}
            handleAllUpdate={(add) => addRemoveAllListMembers(add, 'health_categories')}
            label="Health categories"
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Affiliated Health Authority Region:"
            onChange={(value) => handleChange(value, 'affiliated_health_authority_region_id')}
            placeholder="----------"
            options={healthAffilation}
            selectedValue={volunteer?.affiliated_health_authority_region_id || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputAddRemove
            allOptionsList={healthAffilation}
            selectOptions={volunteer.travel_distance === 'any' ? healthAffilation : volunteer.affilation_health_region || []}
            handleUpdate={(list, add) => addRemoveListMembers(list, add, 'affilation_health_region')}
            handleAllUpdate={(add) => addRemoveAllListMembers(add, 'affilation_health_region')}
            label="Health Authority Region"
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="address"
            label="address:"
            inputValue={`${volunteer?.address}` || ''}
            handleHandle={handleChange}
            keyName="address"
            padding={false}
            theme="form"
            error={errors.address}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="postal_code"
            label="postal code:"
            inputValue={`${volunteer?.postal_code}` || ''}
            handleHandle={handleChange}
            keyName="postal_code"
            padding={false}
            theme="form"
            error={errors.postal_code}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="city"
            label="city:"
            inputValue={`${volunteer?.city}` || ''}
            handleHandle={handleChange}
            keyName="city"
            padding={false}
            theme="form"
            error={errors.city}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <InputText
            id="province"
            label="province:"
            inputValue={`${volunteer?.province}` || ''}
            handleHandle={handleChange}
            keyName="province"
            padding={false}
            theme="form"
            error={errors.province}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="For child/children"
            checkedValue={volunteer?.for_child || false}
            handleChange={(value) => handleChange(value, 'for_child')}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="child1_birthyear"
            label="Child #1 Birth Year:"
            inputValue={volunteer?.child1_birthyear}
            handleHandle={handleChange}
            keyName="child1_birthyear"
            padding={false}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="child2_birthyear"
            label="Child #2 Birth Year:"
            inputValue={volunteer?.child2_birthyear}
            handleHandle={handleChange}
            keyName="child2_birthyear"
            padding={false}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputNumber
            id="child3_birthyear"
            label="Child #3 Birth Year:"
            inputValue={volunteer?.child3_birthyear}
            handleHandle={handleChange}
            keyName="child3_birthyear"
            padding={false}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Travel Distance:"
            onChange={(value) => handleChange(value, 'travel_distance')}
            placeholder="----------"
            options={travelDistanceOptions}
            selectedValue={volunteer?.travel_distance || ''}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="receive matching email"
            checkedValue={volunteer?.receive_matching_email || false}
            handleChange={(value) => handleChange(value, 'receive_matching_email')}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${volunteer?.id}`}
        showNew={false}
        showDelete
      />
    </AppWrapper>
  );
};

export default VolunteerEdit;
