import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageAction from '../../../components/PageAction';
import PageTitle from '../../../components/PageTitle';
import TableBasic from '../../../components/Tablebasic';
import { useUniversity } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const University = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    universities,
    selectecUniversities,
    getUniversities,
    handleCheck,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
  } = useUniversity();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getUniversities();
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.university]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <PageAction
        name={nameSingle}
        count={universities.length}
        selectedCount={selectecUniversities.length}
        selectedValue={selectedValue}
        onChange={setSelectedValue}
        handleClick={handleMenuAction}
      />
      <TableBasic
        data={universities || []}
        selectedList={selectecUniversities}
        handleCheck={handleCheck}
        footerText={`${universities.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default University;
