import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import './utils/faLibrary';
import './styles/reset.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Router />,
);
