import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const PageTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  color: ${colours.colour15};
  padding-top: 1.25rem;
`;

export const LinkButtonStyled = styled.span`
  background: ${colours.colour13};
  padding: 0.4rem 0.875rem;
  border-radius: 500px;
  text-transform: uppercase;
  ${fonts.font1.H11}
  transition: all .3s ease;

  &:hover {
    background: ${colours.colour14};
  }
`;

export const IconWrapper = styled.span`
  margin-left: 0.5rem;
`;
