import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { coreResearcherSchema, createResearcherSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useResearcher = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, update, deleteMany, create,
  } = useApi();

  const baseUrl = 'core/researcher';
  const controllerName = 'admin/researchers';
  const name = 'researchers';
  const nameSingle = 'researcher';

  const [researchers, setResearchers] = useState<ICoreResearcherIndex[] | []>([]);
  const [filterResearchers, setFilterResearchers] = useState<ICoreResearcherIndex[] | []>([]);

  const [researcher, setResearcher] = useState<ICoreResearcher>({});
  const [saveName, setSavedName] = useState('');

  const [allUsers, setAllUsers] = useState([]);
  const [healthAffilation, setHealthAffilation] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [researcherProjects, setResearcherProjects] = useState<ICoreResearcherProject[] | []>([]);

  const [selectResearchers, setSelectResearchers] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const [searchFilter, setSearchFilter] = useState('');

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.researcher,
    ];
    if (researchers.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.researcher.url}/${researchers[0].id}/change`, name: researchers[0].email });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | number| boolean, field: string) => {
    setResearcher((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectResearchers.includes(id)) {
      setSelectResearchers((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectResearchers((oldArray) => [...oldArray, id]);
    }
  };

  // API Calls
  const getResearchers = async (isIndex = false) => {
    try {
      const researcherList = await getIndex(controllerName);
      setResearchers(researcherList);
      if (isIndex) {
        setFilterResearchers(researcherList);
      }
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getResearcher = async (id: number | string) => {
    try {
      const researcherInfo = await getOne(id, controllerName);
      if (researcherInfo.researcher) {
        setResearcher(researcherInfo.researcher);
        setSavedName(`${researcherInfo.researcher.first_name} ${researcherInfo.researcher.last_name}(${researcherInfo.researcher.email})`);
        setSelectResearchers(researcherInfo.id);
        setResearcherProjects(researcherInfo.projects);
        setPhoneNumber(researcherInfo.phone_number);
      }
      setAllUsers(researcherInfo.users || []);
      setUniversities(researcherInfo.universities || []);
      setHealthAffilation(researcherInfo.healthAuthorities || []);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const getResearchersById = async (ids: number[]) => {
    try {
      const researcherInfo = await getManyById(ids, controllerName);
      setResearchers(researcherInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const createNewResearcher = async (values: any) => {
    try {
      const newResearcher = await create(values, controllerName);
      return newResearcher;
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const updateResearcher = async (values: any, id: number) => {
    try {
      const updatedResearcher = await update(values, id, controllerName);
      return updatedResearcher;
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const deleteResearcher = async (ids: number[]) => {
    try {
      const results = await deleteMany(ids, controllerName);
      return results;
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleCreate = async (type: string) => {
    setErrors({});
    try {
      const validedValues = await createResearcherSchema.validate({
        ...researcher,
      }, { abortEarly: false });
      const researcherInfo = await createNewResearcher(validedValues);
      if (researcherInfo.error) throw new Error(researcherInfo.error);
      if (!researcherInfo?.id) throw new Error('Faild to create researcher');
      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage('edit', nameSingle, researcherInfo.email, `/${baseUrl}/${researcherInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, researcherInfo.email, `/${baseUrl}/${researcherInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${researcherInfo.id}/change`);
      }
      if (type === 'save') {
        setResearcher({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleUpdate = async (type: string) => {
    setErrors({});
    try {
      if (!researcher.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues = await coreResearcherSchema.validate({
        ...researcher,
        updatePhoneNumber: researcher.mobile_phone_number !== phoneNumber,
      }, { abortEarly: false });

      const updateResearcherInfo = await updateResearcher(validedValues, researcher.id);
      if (!updateResearcherInfo?.id) throw new Error('');
      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage(
            'edit',
            nameSingle,
            `${researcher.first_name} ${researcher.last_name}(${researcher.email})`,
            `/${baseUrl}/${updateResearcherInfo.id}/change`,
          );
          break;
        case 'save':
          message = createToastMessage(
            'save',
            nameSingle,
            `${researcher.first_name} ${researcher.last_name}(${researcher.email})`,
            `/${baseUrl}/${updateResearcherInfo.id}/change`,
          );
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setResearcher({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setResearcher({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteResearcher(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, `${researchers[0].first_name} ${researchers[0].last_name}(${researchers[0].email})` || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    if (!researchers) return [];
    const searchValue = researchers.filter((res) => {
      let result = false;
      const id = `${res.id}`;
      if (id?.includes(searchFilter)) {
        result = true;
      }
      if (res.email?.toLowerCase().includes(searchFilter.toLowerCase())) {
        result = true;
      }

      if (res.position?.toLowerCase().includes(searchFilter.toLowerCase())) {
        return true;
      }

      if (res.academic_affiliation?.toLowerCase().includes(searchFilter.toLowerCase())) {
        return true;
      }

      if (res.ha_affiliation?.toLowerCase().includes(searchFilter.toLowerCase())) {
        return true;
      }
      if (res.first_name?.toLowerCase().includes(searchFilter.toLowerCase())) {
        result = true;
      }
      if (res.last_name?.toLowerCase().includes(searchFilter.toLowerCase())) {
        result = true;
      }
      if (`${res.first_name} ${res.last_name}`?.toLowerCase().includes(searchFilter.toLowerCase())) {
        result = true;
      }

      return result;
    });
    setFilterResearchers(searchValue);
    return searchValue;
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectResearchers } });
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    researcher,
    researchers,
    selectResearchers,
    pageType,
    setPageType,
    toastMessage,
    filterResearchers,
    getResearcher,
    setSelectResearchers,
    getResearchers,
    handleSubmitButton,
    setResearcher,
    selectedValue,
    setSelectedValue,
    getResearchersById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
    handleSearch,
    setSearchFilter,
    handleMenuAction,
    searchFilter,
    errors,
    allUsers,
    universities,
    healthAffilation,
    researcherProjects,
  };
};
