import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const ButtonsContainerWrapper = styled.section`
  padding: 0.75rem  0.875rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${colours.colour12};
  border-radius: 8px;
  margin: 1.25rem 0;
  border: 1px solid ${colours.colour17};
  ${fonts.font1.H13};
`;

export const ButtonWrapper = styled.div`
  margin-left: 0.875rem;
`;

export const FlexBoxStyled = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`;
