import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type TextInputProps = {
  handleHandle: (value: string, field: string) => void
  handleEnter?: () => void
  label?: string
  keyName: string
  inputValue: string
  id: string
  padding?: boolean
  error?: string
  theme?: string
  placeholder?: string
  disabled?: boolean
}
const TextInput = ({
  handleHandle, handleEnter, label, keyName, inputValue, id, error, padding = true, theme, disabled, placeholder,
}: TextInputProps) => (
  <Styled.InputWrapper padding={padding} theme={theme}>
    {label && (
      <Styled.LabelStyled htmlFor={id} theme={theme}>
        {label}
      </Styled.LabelStyled>
    )}
    <Styled.TextAreaWrapper theme={theme}>
      {error && (
        <Styled.ErrorWrapper>
          <TextP>{error}</TextP>
        </Styled.ErrorWrapper>
      )}
      <Styled.TextInputStyled
        type="text"
        id={id}
        placeholder={placeholder}
        value={inputValue === 'null' || inputValue === 'undefined' ? '' : inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleHandle(event.target.value, keyName);
        }}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter' && handleEnter) {
            event.preventDefault();
            handleEnter();
          }
        }}
        theme={theme}
        isError={!!error}
        disabled={disabled}
      />
    </Styled.TextAreaWrapper>
  </Styled.InputWrapper>
);

export default TextInput;
