import React from 'react';
import Button from '../Button';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';

type IEditFooterBar = {
  urlPath: string
  handleSubmit: (type: string) => void
  showDelete?: boolean
  showNew?: boolean
  showEdit?: boolean
  disableAll?: boolean
}

const EditFooterBar = ({
  urlPath, showDelete, showNew = true, showEdit = true, handleSubmit, disableAll,
}: IEditFooterBar) => (
  <Styled.ButtonsContainerWrapper>
    <div>
      {showDelete && (
        <LinkComponent
          url={`/${urlPath}/delete`}
          theme="warning"
        >
          <div>
            <TextP>Delete</TextP>
          </div>
        </LinkComponent>
      )}
    </div>
    <Styled.FlexBoxStyled>
      {showNew && (
        <Button
          onClick={() => handleSubmit('add')}
          theme="secondary"
          disabled={disableAll}
        >
          <p>Save and add another</p>
        </Button>
      )}

      <Styled.ButtonWrapper>
        {showEdit && (
          <Button
            onClick={() => handleSubmit('edit')}
            theme="secondary"
            disabled={disableAll}
          >
            <p>Save and continue editing</p>
          </Button>
        )}
      </Styled.ButtonWrapper>
      <Styled.ButtonWrapper>

        <Button
          onClick={() => handleSubmit('save')}
          disabled={disableAll}
        >
          <p>SAVE</p>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.FlexBoxStyled>
  </Styled.ButtonsContainerWrapper>

);

export default EditFooterBar;
