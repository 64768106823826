import React from 'react';
import { TextH1, TextP } from '../../components/Text';
import { fonts } from '../../styles/theme';
import { usePasswordChange } from '../../hooks';
import * as Styled from '../../styles/changePasswordStyles';
import Button from '../../components/Button';
import PasswordInput from '../../components/InputPassword';

const ForcePasswordChange = () => {
  const {
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    handleAuthChallenge,
    successful,
    errors,
  } = usePasswordChange();

  return (
    <Styled.PageWraper>
      {successful ? (
        <div>
          <TextH1 textAlgin="left" font={fonts.font1.H20}>
            Password change successful
          </TextH1>
          <Styled.TextWrapper>
            <TextP>
              Your password was changed.
            </TextP>
          </Styled.TextWrapper>
        </div>
      ) : (
        <>
          <TextH1 textAlgin="left" font={fonts.font1.H20}>
            Password change
          </TextH1>
          <Styled.TextWrapper>
            {Object.keys(errors).length > 0
              && (
                <Styled.ErrorStyled>
                  <TextP>
                    Please correct the errors below.
                  </TextP>
                </Styled.ErrorStyled>
              )}
            <TextP>
              Please enter your new password twice so we can verify you typed it in correctly.
            </TextP>
          </Styled.TextWrapper>

          <Styled.InputWrapper>
            <PasswordInput
              id="newPassword"
              label="new password:"
              handleHandle={(value) => setPassword(value)}
              inputValue={password}
              error={errors?.password}
            />
            <Styled.DiscriptionWrapper>
              <TextP font={fonts.font1.H11} lineHeight="2">
                Your password can’t be too similar to your other personal information.
              </TextP>
              <TextP font={fonts.font1.H11} lineHeight="2">
                Your password can’t be a commonly used password.
              </TextP>
              <TextP font={fonts.font1.H11} lineHeight="2">
                Your password can’t be entirely numeric.
              </TextP>
              <TextP font={fonts.font1.H11} lineHeight="2">
                Your password must contain at least 8 characters.
              </TextP>
            </Styled.DiscriptionWrapper>
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <PasswordInput
              id="newPasswordConfirmation:"
              label="New password confirmation:"
              handleHandle={(value) => setPasswordConfirmation(value)}
              inputValue={passwordConfirmation}
              error={errors?.passwordConfirmation}
            />
          </Styled.InputWrapper>

          <Styled.ButtonWrapper>
            <Button
              onClick={handleAuthChallenge}
            >
              Change my password
            </Button>
          </Styled.ButtonWrapper>
        </>
      )}
    </Styled.PageWraper>
  );
};

export default ForcePasswordChange;
