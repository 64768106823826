import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import TextInput from '../../../components/InputText';
import { TextArea } from '../../../components/InputTextArea';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useHealthCategory } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const HealthCategoryEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    healthCategory,
    toastMessage,
    handleChange,
    getHealthCategory,
    handleSubmitButton,
    setPageType,
    setHealthCategory,
    errors,
  } = useHealthCategory();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getHealthCategory(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setHealthCategory({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.healthCategory,
        { url: '', name: `${healthCategory.category}: ${healthCategory.item}` || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl=""
        buttonText=""
        showButton={false}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>
        <TextInput
          id="category"
          label="Health Category:"
          handleHandle={handleChange}
          inputValue={healthCategory.category || ''}
          keyName="category"
          error={errors.category}
        />
        <TextInput
          id="item"
          label="Health Category Item:"
          handleHandle={handleChange}
          inputValue={healthCategory.item || ''}
          keyName="item"
          error={errors.item}

        />
        <TextInput
          id="description"
          label="Description:"
          handleHandle={handleChange}
          inputValue={healthCategory.dscpt || ''}
          keyName="dscpt"
          error={errors.dscpt}
        />
        <TextArea
          id="long_description"
          label="Full Description:"
          handleHandle={handleChange}
          textValue={healthCategory.long_dscpt || ''}
          keyName="long_dscpt"
          error={errors.long_description}

        />
      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${healthCategory.id}`}
        showDelete={pageType === 'change'}
      />
    </AppWrapper>
  );
};

export default HealthCategoryEdit;
