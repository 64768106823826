import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkComponent } from '../../../../components/Link';
import { TextP } from '../../../../components/Text';
import { colours, fonts } from '../../../../styles/theme';
import * as Styled from './styles';

type DashBoardTableProps = {
  tableInfo: {
    tableName: string
    tableNamePath: string
    tableData: {
      name: string
      namePath: string
      addPath: string
      changePath: string
    }[]
  }
}

const DashboardTable = ({ tableInfo }: DashBoardTableProps) => (
  <Styled.TableStyled>
    <tbody>
      <Styled.TableRowStyled>
        <Styled.TableHeaderStyled>
          <LinkComponent url={tableInfo.tableNamePath} theme="tertiary">
            {tableInfo.tableName}
          </LinkComponent>
        </Styled.TableHeaderStyled>
      </Styled.TableRowStyled>
      {tableInfo.tableData.map((row) => (
        <Styled.TableRowStyled key={row.name}>
          <Styled.TableCellStyled width="70%">
            <Styled.TextWrapper>
              <LinkComponent url={row.namePath}>
                <TextP font={fonts.font1.H14B}>
                  {row.name}
                </TextP>
              </LinkComponent>
            </Styled.TextWrapper>
          </Styled.TableCellStyled>
          <Styled.TableCellStyled width="fit-content">
            {row.addPath && (
              <LinkComponent url={row.addPath}>
                <Styled.TableIconWrapper>
                  <FontAwesomeIcon icon="plus" color={colours.colour11} />
                </Styled.TableIconWrapper>
                Add
              </LinkComponent>
            )}
          </Styled.TableCellStyled>
          <Styled.TableCellStyled width="fit-content">
            {row.changePath && (
              <LinkComponent url={row.changePath}>
                <Styled.TableIconWrapper>
                  <FontAwesomeIcon icon="pencil" color={colours.colour10} />
                </Styled.TableIconWrapper>
                Change
              </LinkComponent>
            )}
          </Styled.TableCellStyled>
        </Styled.TableRowStyled>
      ))}
    </tbody>
  </Styled.TableStyled>
);

export default DashboardTable;
