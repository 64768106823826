import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import { dashboardTables } from '../../utils/dashboardConst';
import DashboardTable from '../Dashboard/component/DashboardTable';
import * as Styled from '../../styles/DashboardStyles';
import { fonts } from '../../styles/theme';
import { TextH1 } from '../../components/Text';
import { breachcrumbPath } from '../../utils/breadcrumbConst';

const CoreIndex = () => (
  <AppWrapper breadcrumbs={[breachcrumbPath.core]}>
    <Styled.DashboardTitleWrapper>
      <TextH1 font={fonts.font1.H20} textAlgin="left">
        Core Administration
      </TextH1>
    </Styled.DashboardTitleWrapper>
    <DashboardTable tableInfo={dashboardTables[0]} />
  </AppWrapper>
);

export default CoreIndex;
