import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { adminUsersColumns } from '../../../utils/const';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useAdminUser } from '../../../hooks';

const AdminUser = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterAdminUsers,
    adminUsers,
    selectAdminUsers,
    getAdminUsers,
    handleCheck,
    handleSearch,
    searchFilter,
    setSearchFilter,
  } = useAdminUser();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getAdminUsers(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.admin, breachcrumbPath.adminUser]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterAdminUsers.length}
        total={adminUsers.length}
      />
      <TableBasic
        data={filterAdminUsers || []}
        columns={adminUsersColumns}
        selectedList={selectAdminUsers}
        handleCheck={handleCheck}
        footerText={`${filterAdminUsers.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default AdminUser;
