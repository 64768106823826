import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import { LinkComponent } from '../../../components/Link';
import { TextH3, TextP } from '../../../components/Text';
import { useHealthAuthority } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { fonts } from '../../../styles/theme';

const HealthAuthorityDelete = () => {
  const location: any = useLocation();
  const params = useParams();

  const {
    healthAuthority,
    healthAuthorities,
    selectedHealthAuthorities,
    setSelectedHealthAuthorities,
    handleDelete,
    getHealthAuthoritiesById,
    getBreadcrumbs,
  } = useHealthAuthority();

  useEffect(() => {
    if (location.state?.ids) {
      setSelectedHealthAuthorities(location.state.ids);
      getHealthAuthoritiesById(location.state.ids);
    }
  }, [location]);

  useEffect(() => {
    if (params.id) {
      setSelectedHealthAuthorities([parseInt(params.id, 10)]);
      getHealthAuthoritiesById([parseInt(params.id, 10)]);
    }
  }, [params]);

  return (
    <AppWrapper breadcrumbs={getBreadcrumbs()}>
      <Styled.TextWrapper>
        <TextH3 font={fonts.font1.H20}>Are you sure?</TextH3>
      </Styled.TextWrapper>
      {healthAuthorities.length === 1 ? (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the health authority “{healthAuthorities[0].name}”? All of the following related items will be deleted:
        </TextP>
      ) : (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the selected Health Authorities? All of the following objects and their related items will be deleted:
        </TextP>
      )}
      <div>
        <Styled.TextWrapper>
          <TextP font={fonts.font1.H16B}>Summary</TextP>
          <Styled.ListStyled>
            <li>
              Health Authorities: {healthAuthorities.length}
            </li>
          </Styled.ListStyled>
        </Styled.TextWrapper>
      </div>
      <div>
        <TextP font={fonts.font1.H16B}>Objects</TextP>
        {healthAuthorities && (
          <Styled.ListStyled>
            {healthAuthorities?.map((authority: { name?: string, id?: number }) => (
              <li key={authority.id}>
                Health Authority: {' '}
                <LinkComponent url={`/core/healthauthority/${authority.id}`}>
                  {authority.name}
                </LinkComponent>
              </li>
            ))}
          </Styled.ListStyled>
        )}
        <Styled.FlexBoxStyled>
          <Button
            onClick={() => handleDelete(selectedHealthAuthorities)}
            theme="warning"
          >
            <TextP font={fonts.font1.H12}>Yes, I&apos;m sure</TextP>
          </Button>
          <Styled.ButtonWrapper>
            <LinkComponent
              url={healthAuthorities.length > 1 ? '/core/healthauthority' : `/core/healthauthority/${healthAuthority.id}`}
              theme="buttonSecondary"
            >
              <div>
                <TextP font={fonts.font1.H12}>
                  No, take me back
                </TextP>
              </div>
            </LinkComponent>
          </Styled.ButtonWrapper>
        </Styled.FlexBoxStyled>
      </div>
    </AppWrapper>
  );
};

export default HealthAuthorityDelete;
