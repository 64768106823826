import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../config/environment';
import { changePasswordSchema, ForceChangePasswordSchema } from '../utils/validations';
import { useApi } from './useApi';
import { useCookies } from './useCookies';

export const usePasswordChange = () => {
  const navigate = useNavigate();

  const { createCookie, deleteCookie } = useCookies();
  const { updatePassword, authChallenge } = useApi();

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [forceChange, setForceChange] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChangePassword = async () => {
    setErrors({});
    try {
      await changePasswordSchema.validate({
        oldPassword,
        password,
        passwordConfirmation,
      }, { abortEarly: false });

      const user = await updatePassword({ oldPassword, password });
      if (user.error) throw new Error(user.error);
      if (user.passwordChange) {
        setSuccessful(user.passwordChange);
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleAuthChallenge = async () => {
    setErrors({});
    try {
      await ForceChangePasswordSchema.validate({
        password,
        passwordConfirmation,
      }, { abortEarly: false });

      const user = await authChallenge({ password, type: 'NEW_PASSWORD_REQUIRED' });
      if (user.error) throw new Error(user.error);
      if (user) {
        deleteCookie('authChallenge');
        createCookie(environment.app.cookieName, JSON.stringify(user));
        navigate('/dashboard');
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  return {
    oldPassword,
    setOldPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    password,
    setPassword,
    forceChange,
    successful,
    setForceChange,
    handleChangePassword,
    handleAuthChallenge,
    errors,
  };
};
