import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faPencil,
  faPlus,
  faCheckCircle,
  faXmarkCircle,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faChevronCircleRight,
  faChevronCircleLeft,
  faCircle,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPencil,
  faPlus,
  faCheckCircle,
  faXmarkCircle,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faQuestionCircle,
  faQuestionCircle,
  faChevronCircleRight,
  faChevronCircleLeft,
  faCircle,
  faCheck,
  faXmark,
);
