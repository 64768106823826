import React from 'react';
import Button from '../../components/Button';
import PasswordInput from '../../components/InputPassword';
import TextInput from '../../components/InputText';
import { TextH1, TextP } from '../../components/Text';
import { useLogin } from '../../hooks';
import * as Styled from '../../styles/LoginStyles';
import { fonts } from '../../styles/theme';

const Login = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    mfaCode,
    setMFACode,
    mfa,
    handleLogin,
    handleMFACode,
    errors,
    disableButton,
  } = useLogin();

  return (
    <Styled.LoginPageWrapper>
      <div>
        <Styled.LoginWrapper>
          <Styled.HeaderStyled>
            <TextH1 font={fonts.font1.H18}>ReachBC Administration</TextH1>
          </Styled.HeaderStyled>
          <Styled.LoginBodyWrapper>
            {errors.fetch
              && (
                <Styled.ErrorStyled>
                  <TextP>
                    {errors.fetch}
                  </TextP>
                </Styled.ErrorStyled>
              )}
            {mfa ? (
              <TextInput
                id="mfaCode"
                label="Enter the MFA code here:"
                handleHandle={(value) => setMFACode(value)}
                handleEnter={handleMFACode}
                inputValue={mfaCode}
                keyName="mfaCode"
                theme="column"
                error={errors.mfaCode}
              />
            ) : (
              <>
                <TextInput
                  id="email"
                  label="email:"
                  handleHandle={(value) => setEmail(value)}
                  inputValue={email}
                  keyName="email"
                  theme="column"
                  error={errors.email}
                />
                <PasswordInput
                  id="password"
                  label="password:"
                  handleHandle={(value) => setPassword(value)}
                  handleEnter={handleLogin}
                  inputValue={password}
                  theme="column"
                  error={errors.password}
                />
              </>
            )}
            <Styled.ButtonWrapper>
              <Button
                onClick={() => (mfa ? handleMFACode() : handleLogin())}
                disabled={disableButton}
              >
                <TextP>{mfa ? 'Submit' : 'Log In'}</TextP>
              </Button>
            </Styled.ButtonWrapper>
          </Styled.LoginBodyWrapper>
        </Styled.LoginWrapper>
      </div>
    </Styled.LoginPageWrapper>
  );
};

export default Login;
