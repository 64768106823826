import { useState } from 'react';
import { csvGenerator } from '../utils/csvGenerator';
import { useApi } from './useApi';

export const useGeneratorEmailList = () => {
  const { generatorEmailList } = useApi();

  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const name = 'Email CSV';
  const nameSingle = 'Email CSV';
  const handleGeneratorList = async () => {
    setErrors({});
    try {
      const emailList = await generatorEmailList();
      if (emailList.error || !emailList.file) throw new Error(emailList.error);
      csvGenerator(emailList.file);
    } catch (error: any) {
      setErrors({ fetch: error.message });
    }
  };
  return {
    name,
    nameSingle,
    handleGeneratorList,
    errors,
  };
};
