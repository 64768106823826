import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createToastMessage } from '../utils/createToastMessage';
import { projectInterestSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useProjectInterest = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update,
  } = useApi();

  const baseUrl = 'core/projectinterest';
  const controllerName = 'projectinterests';
  const name = 'project interests';
  const nameSingle = 'project interest';

  const [projectinterests, setProjectinterests] = useState<IProjectInterest[] | []>([]);
  const [filterProjectInterests, setFilterProjectInterests] = useState<IProjectInterest[] | []>([]);

  const [projectInterest, setProjectInterest] = useState<IProjectInterest>();
  const [saveName, setSavedName] = useState('');

  const [selectProjectinterests, setSelectProjectinterests] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [selectedIdtype, setSelectedIdType] = useState('project_id');

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [selectedValue, setSelectedValue] = useState('');

  const [projectStatusFilter, setProjectStatusFilter] = useState('');
  const [enrollmentStatusFilter, setEnrollmentStatusFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [history, setHistory] = useState<IHistoryLog[]>([]);

  const handleChange = (value: string | number, field: string) => {
    setProjectInterest((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectProjectinterests.includes(id)) {
      setSelectProjectinterests((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectProjectinterests((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectProjectinterests } });
    }
  };

  // API Calls
  const getProjectInterests = async (isIndex = false) => {
    try {
      const projectInterestInfo = await getIndex(controllerName);
      setProjectinterests(projectInterestInfo);
      if (isIndex) {
        setFilterProjectInterests(projectInterestInfo);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getProjectInterest = async (id: number) => {
    try {
      const projectInterestInfo = await getOne(id, controllerName);
      setProjectInterest(projectInterestInfo);
      setSavedName(projectInterestInfo.item);
      setSelectProjectinterests(projectInterestInfo.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getProjectInterestsById = async (ids: number[]) => {
    try {
      const projectInterestInfo = await getManyById(ids, controllerName);
      setProjectinterests(projectInterestInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewProjectInterest = async (values: any) => {
    try {
      const newProjectInterest = await create(values, controllerName);
      return newProjectInterest;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateProjectInterest = async (values: any, id: number) => {
    try {
      const updatedProjectInterest = await update(values, id, controllerName);
      return updatedProjectInterest;
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHistoryLog = async (id: string) => {
    try {
      const projectInterestInfo = await getIndex(`admin/${controllerName}/history/${id}`);
      const createDate = new Date(projectInterestInfo.historyLogs[projectInterestInfo.historyLogs.length - 1].updated_at);
      projectInterestInfo.historyLogs[projectInterestInfo.historyLogs.length - 1].updated_at = new Date(createDate.setHours(createDate.getHours() + 17));
      setHistory(projectInterestInfo.historyLogs);
      setProjectInterest(projectInterestInfo.projectInfo);
    } catch (error: any) {
      setErrors({ message: error.message });
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!projectInterest?.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues = await projectInterestSchema.validate({
        ...projectInterest,
      }, { abortEarly: false, stripUnknown: true });
      const projectInterestInfo = await updateProjectInterest(validedValues, projectInterest?.id);
      if (projectInterestInfo.error) throw new Error(projectInterestInfo.error);
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, `ProjectInterest object (${projectInterestInfo.id})`, `/${baseUrl}/${projectInterestInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, `ProjectInterest object (${projectInterestInfo.id})`, `/${baseUrl}/${projectInterestInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `ProjectInterest object (${projectInterestInfo.id})`, `/${baseUrl}/${projectInterestInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setProjectInterest({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setProjectInterest({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleSubmitButton = (type: string) => {
    handleUpdate(type);
  };

  const handleUpdateFilter = (value: string | number, key: string) => {
    if (typeof value === 'number') return;
    switch (key) {
      case 'status':
        setProjectStatusFilter(value);
        break;
      case 'enrollment':
        setEnrollmentStatusFilter(value);
        break;
      case 'search':
        setSearchFilter(value);
        break;
      case 'idType':
        setSelectedIdType(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = async (query?: any) => {
    if (query === 'CLEAR') {
      setSelectedIdType('project_id');
      setProjectStatusFilter('');
      setSearchFilter('');
      setEnrollmentStatusFilter('');
      const searchValue = await getIndex(controllerName);
      setFilterProjectInterests(searchValue);
      return searchValue;
    }

    let searchQuerySearch: any = searchFilter || query;
    if (searchQuerySearch?.includes('&')) searchQuerySearch = searchQuerySearch.replaceAll('&', 'ANDSYMBOL');
    if (searchQuerySearch?.includes("'")) searchQuerySearch = searchQuerySearch.replace("'", '&apos;');
    if (searchQuerySearch?.includes('+')) searchQuerySearch = searchQuerySearch.replaceAll('+', 'PLUSSYMBOL');
    if (searchQuerySearch?.includes('#')) searchQuerySearch = searchQuerySearch.replaceAll('#', 'HASHTAG');
    if (searchQuerySearch?.includes('%')) searchQuerySearch = searchQuerySearch.replaceAll('%', 'PERCENTSYBMOL');

    const params = projectStatusFilter !== '' ? ['search=true', `searchIdType=${selectedIdtype}`, `projectStatus=${projectStatusFilter}`] : ['search=true', `searchIdType=${selectedIdtype}`];

    if (enrollmentStatusFilter !== '') params.push(`enrollmentStatus=${enrollmentStatusFilter}`);
    if (searchFilter !== '') params.push(`searchQuery=${searchQuerySearch}`);

    const searchValue = await getIndex(controllerName, `?${params.join('&')}`);

    setFilterProjectInterests(searchValue || []);
    return searchValue;
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    projectInterest,
    filterProjectInterests,
    projectinterests,
    selectProjectinterests,
    pageType,
    setPageType,
    toastMessage,
    getProjectInterest,
    setSelectProjectinterests,
    getProjectInterests,
    createNewProjectInterest,
    handleSubmitButton,
    setProjectInterest,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getProjectInterestsById,
    handleCheck,
    handleChange,
    handleUpdate,
    handleSearch,
    errors,
    handleUpdateFilter,
    enrollmentStatusFilter,
    searchFilter,
    setSearchFilter,
    projectStatusFilter,
    getHistoryLog,
    history,
    selectedIdtype,
    setSelectedIdType,
  };
};
