import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import { dashboardTables } from '../../utils/dashboardConst';
import DashboardTable from '../Dashboard/component/DashboardTable';
import * as Styled from '../../styles/DashboardStyles';
import { fonts } from '../../styles/theme';
import { TextH1 } from '../../components/Text';
import { breachcrumbPath } from '../../utils/breadcrumbConst';

const AdminIndex = () => (
  <AppWrapper breadcrumbs={[breachcrumbPath.admin]}>
    <Styled.DashboardTitleWrapper>
      <TextH1 font={fonts.font1.H20} textAlgin="left">
        Admin Administration
      </TextH1>
    </Styled.DashboardTitleWrapper>
    <DashboardTable tableInfo={dashboardTables[1]} />
  </AppWrapper>
);

export default AdminIndex;
