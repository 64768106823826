import styled from 'styled-components';
import { colours, fonts } from './theme';
import toRem from '../utils/toRem';

type InputWrapperProps = {
  readonly capitalize?: boolean
}

export const TableDivStyled = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled.section`
  width: 100%;
  border-bottom: 1px solid ${colours.colour17};
  margin-top: 0.5rem;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  border-bottom: 1px solid ${colours.colour17};
  padding: 0.625rem;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : '')};
`;

export const ButtonContainterStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${toRem(32)};
  max-width: ${toRem(1000)};
  padding-bottom: ${toRem(32)};
`;

export const ButtonsContainerWrapper = styled.section`
  padding: 0.75rem  0.875rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${colours.colour12};
  border-radius: 8px;
  margin: 1.25rem 0;
  border: 1px solid ${colours.colour17};
`;

export const ButtonWrapper = styled.div`
  margin-left: 0.875rem;
`;

export const AllButtonStyled = styled.div`
  grid-column: 3/4;
  width: 100%;
`;

export const FlexBoxStyled = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`;

export const ListStyled = styled.ul`
  list-style: square;
  list-style-position: inside;
  margin: 0.875rem 0rem 0.5rem 1.5rem;
  ${fonts.font1.H13};
`;

export const TextWrapper = styled.span`
  margin: 0.875rem;
`;

export const LabelWrapper = styled.div`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const ProjectWrapper = styled.div`
  display: flex;
  margin: 0 0 0 170px;
`;

export const FailedWrapper = styled.div`
  border: 1px solid ${colours.colour18};
  padding: 10px 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: ${colours.colour18};
  ${fonts.font1.H14B}
  margin: 2rem;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const MessageWrapper = styled.div`
  margin: 8px 0 0 170px;
  line-height: 20px;
  ${fonts.font1.H12}
`;
