import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { volunteersColumns } from '../../../utils/const';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useVolunteer } from '../../../hooks';
import PageAction from '../../../components/PageAction';
import FilterBar from '../../../components/FilterBar';
import * as Styled from '../../../styles/IndexStyles';

const Volunteer = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterVolunteers,
    volunteers,
    selectVolunteers,
    selectedValue,
    healthAffilation,
    getVolunteers,
    handleCheck,
    handleSearch,
    searchFilter,
    setSearchFilter,
    setSelectedValue,
    handleMenuAction,
    handleUpdateFilter,
    healthAffilationFilter,
    siteCodes,
    selectedSiteCore,
    isLoading,
  } = useVolunteer();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getVolunteers(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  useEffect(() => {
    if (isLoading) return;
    handleSearch();
  }, [healthAffilationFilter, selectedSiteCore]);

  useEffect(() => {
    if (isLoading) return;

    let getData: ReturnType<typeof setTimeout>;
    if (searchFilter) {
      getData = setTimeout(() => {
        handleSearch();
      }, 1000);
    }
    return () => clearTimeout(getData);
  }, [searchFilter]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.volunteer]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterVolunteers.length}
        total={volunteers.length}
      />

      <Styled.FlexboxStyled>
        <PageAction
          name={nameSingle}
          count={volunteers.length}
          selectedCount={selectVolunteers.length}
          selectedValue={selectedValue}
          onChange={setSelectedValue}
          handleClick={handleMenuAction}
        />
        <FilterBar
          filterOptions={[{ value: '', label: '----------' }, { value: '', label: 'All' }, ...healthAffilation]}
          keyName="healthAffilation"
          handleChange={handleUpdateFilter}
          selectedValue={healthAffilationFilter}
          filterOptionsRight={[{ value: '', label: '----------' }, { value: '', label: 'All' }, { value: 'none', label: 'None' }, ...siteCodes]}
          keyRight="siteCode"
          selectedValueRight={selectedSiteCore}
          useRightSelect
        />
      </Styled.FlexboxStyled>

      <TableBasic
        data={filterVolunteers || []}
        columns={volunteersColumns}
        selectedList={selectVolunteers}
        handleCheck={handleCheck}
        footerText={`${filterVolunteers.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default Volunteer;
