import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import FilterBar from '../../../components/FilterBar';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { useProjectInterest } from '../../../hooks';
import { projectInterestTableColumns, projectStatusFilterOptions, enrollmentStatusOptions } from '../../../utils/const';
import * as Styled from '../../../styles/IndexStyles';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const ProjectInterest = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterProjectInterests,
    projectinterests,
    selectProjectinterests,
    getProjectInterests,
    handleCheck,
    handleSearch,
    projectStatusFilter,
    searchFilter,
    setSearchFilter,
    handleUpdateFilter,
    enrollmentStatusFilter,
    selectedIdtype,
  } = useProjectInterest();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getProjectInterests(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  useEffect(() => {
    handleSearch();
  }, [enrollmentStatusFilter, projectStatusFilter]);

  useEffect(() => {
    if (!searchFilter) return;
    handleSearch();
  }, [selectedIdtype]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.projectInterest]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterProjectInterests.length}
        total={projectinterests.length}
      />
      <Styled.FlexboxStyled>
        <FilterBar
          filterOptions={projectStatusFilterOptions}
          filterOptionsRight={enrollmentStatusOptions}
          keyName="status"
          keyRight="enrollment"
          handleChange={handleUpdateFilter}
          selectedValue={projectStatusFilter}
          selectedValueRight={enrollmentStatusFilter}
          useRightSelect
          filterOptionsMiddle={[
            { label: 'Project Id', value: 'project_id' },
            { label: 'Volunteer Id', value: 'volunteer_id' },
          ]}
          keyMiddle="idType"
          selectedValueMiddle={selectedIdtype}
          useMiddleSelect
        />
      </Styled.FlexboxStyled>
      <TableBasic
        data={filterProjectInterests || []}
        columns={projectInterestTableColumns}
        selectedList={selectProjectinterests}
        handleCheck={handleCheck}
        footerText={`${filterProjectInterests.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default ProjectInterest;
