import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const ActionBarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colours.colour15};
  ${fonts.font1.H13}
`;

export const ActionLabelStyled = styled.label`
  margin-right: 0.5rem;
`;

export const SelectStyled = styled.select`
  margin-right: 0.5rem;
  padding: 0.25rem 0.635rem;
  border-radius: 4px;
  border: 1px solid ${colours.colour23};
  min-width: 186px
`;

export const ButtonStyled = styled.button`
  margin-right: 0.5rem;
`;

export const TextWrapper = styled.span`
  margin-left: 0.5rem;
`;

export const FlexboxStyled = styled.div`
  display: flex;
  align-items: center;
`;
