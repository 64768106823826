import React, { useEffect } from 'react';
import { useTableBasic } from '../../hooks/useTableBasic';
import { fonts } from '../../styles/theme';
import { nameTime } from '../../utils/formateDate';
import Button from '../Button';
import { TextP } from '../Text';
import * as Styled from './styles';

type TableBasicProps = {
  data: any[]
  columns?: {
    name: string
    key: string
    width?: string
    type: string
    const?: { [key: string]: string }
    multiKeys?: string[]
    multilabels?: string[]
    linkPath?: string
  }[]
  footerText: string
}

const TableHistory = ({
  footerText, data, columns,
}: TableBasicProps) => {
  const {
    filterTableData,
    setFilterTableData,
    pageNumber,
    setPageNumber,
    totalPages,
    setTotalPages,
    perPage,
    handlePerPage,
  } = useTableBasic(data);

  useEffect(() => {
    if (data && data.length > perPage) {
      setFilterTableData(data.slice(0, perPage));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setFilterTableData(data);
      setTotalPages(1);
    }
  }, [data, perPage]);

  useEffect(() => {
    setFilterTableData(data.slice(perPage * (pageNumber - 1), (perPage * (pageNumber - 1)) + perPage));
  }, [pageNumber]);
  return (
    <Styled.TableStyled>
      <tbody>
        <Styled.TableRowStyled>
          {columns?.map((header) => (
            <Styled.TableHeaderStyled
              key={`header-${header.key}`}
              width={header.width || 'auto'}
            >
              {header.name}
            </Styled.TableHeaderStyled>
          ))}
        </Styled.TableRowStyled>
        {filterTableData.map((row) => (
          <Styled.TableRowStyled key={row.id}>
            {columns?.map((column) => (
              <Styled.TableCellStyled
                key={`${row.id}-${column.key}`}
              >
                {column.type === 'text' && (
                  <TextP font={fonts.font1.H13}>{row[column.key]}</TextP>
                )}
                {column.type === 'date' && row[column.key] && (
                  <TextP font={fonts.font1.H13B}>{nameTime(row[column.key])}</TextP>
                )}
              </Styled.TableCellStyled>
            ))}
          </Styled.TableRowStyled>
        ))}

        <Styled.TableRowStyled>
          <Styled.TableCellFooterStyled colSpan={columns ? columns.length : 2}>
            <Styled.FlexBoxStyled>

              {totalPages > 1 && (
                <>
                  {Array.from(Array(totalPages).keys()).map((page: number) => (
                    <Styled.FooterButtonWrapper key={`page-${page}`}>
                      <Button
                        key={`page-${page + 1}`}
                        onClick={() => setPageNumber(page + 1)}
                        theme={page + 1 === pageNumber ? 'selectPageButton' : 'pageButton'}
                      >
                        <TextP>{page + 1}</TextP>
                      </Button>
                    </Styled.FooterButtonWrapper>
                  ))}
                </>
              )}
              {footerText}
              {totalPages > 1 && (
                <Button
                  onClick={handlePerPage}
                  theme="plain"
                >
                  <TextP>
                    Show all
                  </TextP>
                </Button>
              )}
            </Styled.FlexBoxStyled>
          </Styled.TableCellFooterStyled>
        </Styled.TableRowStyled>
      </tbody>
    </Styled.TableStyled>
  );
};

export default TableHistory;
