import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours } from '../../styles/theme';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';

type HeaderToastProps = {
  message: IToastMessage
}
const HeaderToast = ({ message }: HeaderToastProps) => (
  <div>
    {message && (
      <Styled.HeaderToastWrapper>
        <Styled.IconWrapper>
          <FontAwesomeIcon icon="check-circle" color={colours.colour11} />
        </Styled.IconWrapper>
        <TextP>
          {message.start}
          {message.link && (
            <LinkComponent url={message.link}>{message.linkText}</LinkComponent>
          )}
          {message.end}
        </TextP>
      </Styled.HeaderToastWrapper>
    )}
  </div>
);

export default HeaderToast;
