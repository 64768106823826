import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { updateAdminUserSchema, createAdminUserSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useAdminUser = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update,
  } = useApi();

  const baseUrl = 'admin/adminUser';
  const controllerName = 'adminUsers';
  const name = 'Admin users';
  const nameSingle = 'Admin user';

  const [adminUsers, setAdminUsers] = useState<IAdminUserIndex[] | []>([]);
  const [adminUser, setAdminUser] = useState<IAdminUserIndex>();
  const [filterAdminUsers, setFilterAdminUsers] = useState<IAdminUserIndex[] | []>([]);

  const [saveName, setSavedName] = useState('');

  const [selectAdminUsers, setSelectAdminUsers] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [isActive, setIsActive] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.adminUser,
    ];
    if (adminUsers.length === 1) {
      crumbs.push({
        url: `${breachcrumbPath.adminUser.url}/${adminUsers[0].id}/change`,
        name: `${adminUsers[0].first_name} ${adminUsers[0].last_name}(${adminUsers[0].email})`,
      });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string | boolean, field: string) => {
    setAdminUser((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectAdminUsers.includes(id)) {
      setSelectAdminUsers((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectAdminUsers((oldArray) => [...oldArray, id]);
    }
  };

  // API Calls
  const getAdminUsers = async (isIndex = false) => {
    try {
      const adminUserList = await getIndex(controllerName);
      setAdminUsers(adminUserList);
      if (isIndex) {
        setFilterAdminUsers(adminUserList);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getAdminUser = async (id: number) => {
    try {
      const adminUserInfo = await getOne(id, controllerName);
      setAdminUser(adminUserInfo);
      setSavedName(adminUserInfo.name);
      setSelectAdminUsers(adminUserInfo.id);
      setIsActive(adminUserInfo.is_active);
      setPhoneNumber(adminUser?.phone_number || '');
    } catch (error) {
      throw new Error('error');
    }
  };

  const getAdminUsersById = async (ids: number[]) => {
    try {
      const AdminUserInfo = await getManyById(ids, controllerName);
      setAdminUsers(AdminUserInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewAdminUser = async (values: any) => {
    try {
      const newAdminUser = await create(values, controllerName);
      return newAdminUser;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateAdminUser = async (values: any, id: number) => {
    try {
      const updatedAdminUser = await update(values, id, controllerName);
      return updatedAdminUser;
    } catch (error) {
      throw new Error('error');
    }
  };

  const handleCreate = async (type: string) => {
    try {
      setErrors({});
      const validedValues = await createAdminUserSchema.validate({
        ...adminUser,
      }, { abortEarly: false });
      const adminUserInfo = await createNewAdminUser(validedValues);
      if (adminUserInfo?.error) throw new Error(adminUserInfo.error);
      if (!adminUserInfo?.id) throw new Error(`Failed to update ${nameSingle}`);
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, `${adminUserInfo.first_name} ${adminUserInfo.last_name}(${adminUserInfo.email})`, `/${baseUrl}/${adminUserInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, `${adminUserInfo.first_name} ${adminUserInfo.last_name}(${adminUserInfo.email})`, `/${baseUrl}/${adminUserInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `${adminUserInfo.first_name} ${adminUserInfo.last_name}(${adminUserInfo.email})`, `/${baseUrl}/${adminUserInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${adminUserInfo.id}/change`);
      }
      if (type === 'save') {
        setAdminUser({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      setErrors({});
      if (!adminUser?.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues: any = await updateAdminUserSchema.validate({
        ...adminUser,
      }, { abortEarly: false });

      validedValues.updateActive = validedValues.is_active !== isActive;
      validedValues.updatePhoneNumber = !!phoneNumber && validedValues.phone_number !== phoneNumber;

      const adminUserInfo = await updateAdminUser(validedValues, adminUser.id);
      if (adminUserInfo?.error) throw new Error(adminUserInfo.error);
      if (!adminUserInfo?.id) throw new Error(`Failed to update ${nameSingle}`);
      let message;
      switch (type) {
        case 'edit':
          message = createToastMessage(
            'edit',
            nameSingle,
            `${adminUserInfo.first_name} ${adminUserInfo.last_name}(${adminUserInfo.email})`,
            `/${baseUrl}/${adminUserInfo.id}/change`,
          );
          break;
        case 'save':
          message = createToastMessage(
            'save',
            nameSingle,
            `${adminUserInfo.first_name} ${adminUserInfo.last_name}(${adminUserInfo.email})`,
            `/${baseUrl}/${adminUserInfo.id}/change`,
          );
          break;
        default:
          break;
      }
      setIsActive(adminUserInfo.is_active);
      if (message) {
        setToastMessage(message);
      }
      if (type === 'save') {
        setAdminUser({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    if (!adminUsers) return [];
    const searchValue = adminUsers.filter((res) => {
      let result = false;
      const id = `${res.id}`;
      if (id?.includes(searchFilter)) {
        result = true;
      }
      if (res.first_name?.toLowerCase().includes(searchFilter)) {
        result = true;
      }

      if (res.last_name?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      if (res.email?.toLowerCase().includes(searchFilter)) {
        return true;
      }

      return result;
    });
    setFilterAdminUsers(searchValue);
    return searchValue;
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    adminUser,
    adminUsers,
    selectAdminUsers,
    pageType,
    setPageType,
    toastMessage,
    getAdminUser,
    setSelectAdminUsers,
    getAdminUsers,
    createNewAdminUser,
    handleSubmitButton,
    setAdminUser,
    selectedValue,
    setSelectedValue,
    getAdminUsersById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    getBreadcrumbs,
    filterAdminUsers,
    handleSearch,
    searchFilter,
    setSearchFilter,
    errors,
  };
};
