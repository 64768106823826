import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../config/environment';
import { loginSchema, mfaCodeSchema } from '../utils/validations';
import { useApi } from './useApi';
import { useCookies } from './useCookies';

export const useLogin = () => {
  const navigate = useNavigate();

  const { login, authChallenge } = useApi();
  const { createCookie, deleteCookie } = useCookies();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [mfaCode, setMFACode] = useState('');
  const [mfa, setMFA] = useState(false);

  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleLogin = async () => {
    setErrors({});
    setDisableButton(true);
    try {
      await loginSchema.validate({
        password,
        email,
      }, { abortEarly: false });

      const user = await login({ email, password });
      if (user.error) throw new Error(user.error);

      createCookie('authChallenge', JSON.stringify(user.jwtToken));
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/authChallenge', { state: { challengeName: user.challengeName } });
      }
      setDisableButton(false);
      setMFA(true);
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
      setDisableButton(false);
    }
  };

  const handleMFACode = async () => {
    try {
      await mfaCodeSchema.validate({
        mfaCode,
      }, { abortEarly: false });
      const user = await authChallenge({ mfaCode, type: 'SMS_MFA' });
      if (user.error) throw new Error(user.error);
      if (user) {
        deleteCookie('authChallenge');
        await createCookie(environment.app.cookieName, JSON.stringify(user.jwtToken));
        createCookie(environment.app.authCookieName, JSON.stringify(user.cognitoToken));
        navigate('/dashboard');
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    mfaCode,
    setMFACode,
    mfa,
    handleMFACode,
    errors,
    disableButton,
  };
};
