export const csvGenerator = (csv: string, fileName = 'reachBCEmailList') => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = `${fileName}.csv`;
  a.click();
};

export const csvGeneratorZip = (content: any) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(content);
  link.download = 'reachBCTables.zip';
  link.click();
  URL.revokeObjectURL(link.href);
};
