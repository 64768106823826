const environment = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL as string,
  },
  app: {
    cookieName: process.env.REACT_APP_USER_COOKIE_NAME as string,
    authCookieName: process.env.REACT_APP_USER_COGNITO_COOKIE_NAME as string,
  },
};

export default environment;
