import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { userAccountTypeOptions, usersColumns } from '../../../utils/const';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { useUser } from '../../../hooks';
import PageAction from '../../../components/PageAction';
import FilterBar from '../../../components/FilterBar';
import { DropdownsWrapper } from '../../../styles/UserStyles';

const User = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterUsers,
    users,
    selectUsers,
    selectedValue,
    getUsers,
    handleCheck,
    handleSearch,
    searchFilter,
    setSearchFilter,
    filterSelectedValue,
    handleUpdateFilter,
    setSelectedValue,
    handleMenuAction,
  } = useUser();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getUsers(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  useEffect(() => {
    handleSearch();
  }, [filterSelectedValue]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.user]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterUsers.length}
        total={users.length}
      />
      <DropdownsWrapper>
        <PageAction
          name={nameSingle}
          count={users.length}
          selectedCount={selectUsers.length}
          selectedValue={selectedValue}
          onChange={setSelectedValue}
          handleClick={handleMenuAction}
        />
        <FilterBar
          filterOptions={userAccountTypeOptions}
          keyName="account_type"
          handleChange={handleUpdateFilter}
          selectedValue={filterSelectedValue}
        />
      </DropdownsWrapper>
      <TableBasic
        data={filterUsers || []}
        columns={usersColumns}
        selectedList={selectUsers}
        handleCheck={handleCheck}
        footerText={`${filterUsers.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default User;
