import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { NameSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useUniversity = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update, deleteMany,
  } = useApi();

  const baseUrl = 'core/university';
  const controllerName = 'university';
  const name = 'universities';
  const nameSingle = 'university';

  const [universities, setUniversities] = useState<{id: number, name: string}[] | []>([]);
  const [university, setUniversity] = useState<{id?: number, name?: string}>({});
  const [saveName, setSavedName] = useState('');

  const [selectecUniversities, setSelectecUniversities] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.university,
    ];
    if (universities.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.university.url}/${universities[0].id}/change`, name: universities[0].name });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string, field: string) => {
    setUniversity((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectecUniversities.includes(id)) {
      setSelectecUniversities((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectecUniversities((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectecUniversities } });
    }
  };

  // API Calls
  const getUniversities = async () => {
    try {
      const indexList = await getIndex(controllerName);
      setUniversities(indexList);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getUniversity = async (id: number) => {
    try {
      const universityInfo = await getOne(id, controllerName);
      setUniversity(universityInfo);
      setSavedName(universityInfo.name);
      setSelectecUniversities(universityInfo.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getUniversityById = async (ids: number[]) => {
    try {
      const universityInfo = await getManyById(ids, controllerName);
      setUniversities(universityInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewUniversity = async (values: any) => {
    try {
      const newUniversity = await create(values, controllerName);
      return newUniversity;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateUniversity = async (values: any, id: number) => {
    try {
      const updatedUniversity = await update(values, id, controllerName);
      return updatedUniversity;
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteUniversity = async (ids: number[]) => {
    try {
      const results = await deleteMany(ids, controllerName);
      return results;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleCreate = async (type: string) => {
    try {
      const validedValues = await NameSchema.validate({
        name: university.name,
      }, { abortEarly: false });
      const authority = await createNewUniversity(validedValues);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${authority.id}/change`);
      }
      if (type === 'add') {
        setUniversity({});
        setSavedName('');
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setUniversity({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!university.id) throw new Error('Failed to update university');
      const validedValues = await NameSchema.validate({
        name: university.name,
      }, { abortEarly: false });
      const authority = await updateUniversity(validedValues, university.id);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setUniversity({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setUniversity({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteUniversity(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, universities[0]?.name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    university,
    universities,
    selectecUniversities,
    pageType,
    setPageType,
    toastMessage,
    getUniversity,
    setSelectecUniversities,
    getUniversities,
    createNewUniversity,
    handleSubmitButton,
    setUniversity,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getUniversityById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
  };
};
