import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const BreadcrumbStyled = styled.div`
  background:  ${colours.colour4};
  width: 100%;
  padding: 0.5rem 2.5rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

export const CrumbStyled = styled.div`
  color: ${colours.colour2};
  display: flex;
  align-items: center;
  ${fonts.font1.H14};
`;

export const IconWrapper = styled.div`
  margin: 0 0.5rem;
`;
