import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageAction from '../../../components/PageAction';
import PageTitle from '../../../components/PageTitle';
import TableBasic from '../../../components/Tablebasic';
import { useHealthCategory } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const HealthCategory = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    healthCategories,
    selectHealthCategories,
    getHealthCategories,
    handleCheck,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
  } = useHealthCategory();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getHealthCategories();
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.healthCategory]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
        showButton
      />
      <PageAction
        name={nameSingle}
        count={healthCategories.length}
        selectedCount={selectHealthCategories.length}
        selectedValue={selectedValue}
        onChange={setSelectedValue}
        handleClick={handleMenuAction}
      />
      <TableBasic
        data={healthCategories || []}
        selectedList={selectHealthCategories}
        handleCheck={handleCheck}
        footerText={`${healthCategories.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default HealthCategory;
