import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type PassordInputProps = {
  handleHandle: (value: string) => void
  handleEnter?: () => void
  label?: string
  inputValue: string
  id: string
  padding?: boolean
  error?: string
  theme?: string
}
const PasswordInput = ({
  handleHandle, handleEnter, label, inputValue, id, error, padding = true, theme,
}: PassordInputProps) => (
  <Styled.InputWrapper padding={padding} theme={theme}>
    {label && (
      <Styled.LabelStyled htmlFor={id}>
        {label}
      </Styled.LabelStyled>
    )}
    <Styled.TextAreaWrapper theme={theme}>
      {error && (
        <Styled.ErrorWrapper>
          <TextP>{error}</TextP>
        </Styled.ErrorWrapper>
      )}
      <Styled.TextInputStyled
        type="password"
        id={id}
        value={inputValue === 'null' || inputValue === 'undefined' ? '' : inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleHandle(event.target.value);
        }}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter' && handleEnter) {
            event.preventDefault();
            handleEnter();
          }
        }}
        theme={theme}
        isError={!!error}
      />
    </Styled.TextAreaWrapper>
  </Styled.InputWrapper>
);

export default PasswordInput;
