import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import PageTitle from '../../components/PageTitle';
import { useGeneratorEmailList } from '../../hooks';
import * as Styled from '../../styles/EditDeleteStyles';
import { breachcrumbPath } from '../../utils/breadcrumbConst';

const GeneratorEmailList = () => {
  const {
    nameSingle,
    handleGeneratorList,
    errors,
  } = useGeneratorEmailList();

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.admin,
        breachcrumbPath.uploadCsv,
      ]}
    >
      <PageTitle
        title={`Download ${nameSingle}`}
        linkUrl="/"
        buttonText="history"
      />

      <Styled.FormWrapper>
        {errors.fetch && (
          <ErrorMessage message={errors.fetch} />
        )}

        <Styled.InputWrapper>
          <Button
            onClick={handleGeneratorList}
          >
            Click to download email list
          </Button>

        </Styled.InputWrapper>

      </Styled.FormWrapper>
    </AppWrapper>
  );
};

export default GeneratorEmailList;
