import styled from 'styled-components';
import { colours } from '../../styles/theme';

export const SearchBarStyled = styled.div`
  background: ${colours.colour16};
  border-top: 1px solid ${colours.colour17};
  border-bottom: 1px solid ${colours.colour17};
  padding: 0 0.625rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;
