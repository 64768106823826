const dateList = ['updated_at', 'recruitment_end', 'result_updated_at', 'created_at'];

export const sortTableData = (list: any[], sortBy: string, direction = 'asc') => {
  if (dateList.includes(sortBy)) {
    if (direction === 'desc') {
      return list.sort((a: any, b: any) => {
        if (new Date(a[sortBy]) > new Date(b[sortBy])) {
          return -1;
        }
        if (new Date(a[sortBy]) > new Date(b[sortBy])) {
          return 1;
        }
        return 0;
      });
    }
    return list.sort((a: any, b: any) => {
      if (new Date(a[sortBy]) < new Date(b[sortBy])) {
        return -1;
      }
      if (new Date(a[sortBy]) < new Date(b[sortBy])) {
        return 1;
      }
      return 0;
    });
  }
  if (typeof list[0][sortBy] === 'string') {
    if (direction === 'desc') {
      return list.sort((a: any, b: any) => {
        if (!a[sortBy] && !b[sortBy]) {
          return 0;
        }
        if (!a[sortBy]) {
          return 1;
        }
        if (!b[sortBy]) {
          return -1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return -1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    return list.sort((a: any, b: any) => {
      if (!a[sortBy] && !b[sortBy]) {
        return 0;
      }
      if (!a[sortBy]) {
        return 1;
      }
      if (!b[sortBy]) {
        return -1;
      }

      if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return -1;
      }
      if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  if (direction === 'desc') {
    return list.sort((a: any, b: any) => {
      if (a[sortBy] > b[sortBy]) {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return 0;
    });
  }
  return list.sort((a: any, b: any) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] < b[sortBy]) {
      return 1;
    }
    return 0;
  });
};
