import React from 'react';
import { fonts } from '../../styles/theme';
import InputSelect from '../InputSelect';
import { TextP } from '../Text';
import * as Styled from './styles';

type FilterBarProps = {
  filterOptions: { label: string, value: string | number }[]
  keyName: string
  filterOptionsRight?: { label: string, value: string }[]
  keyRight?: string
  handleChange: (value: string | number, key: string) => void
  selectedValue: string
  selectedValueRight?: string
  useRightSelect?: boolean
  useMiddleSelect?: boolean
  keyMiddle?: string
  filterOptionsMiddle?: { label: string, value: string }[]
  selectedValueMiddle?: string
}

const FilterBar = ({
  filterOptions, selectedValue, selectedValueRight, filterOptionsRight, keyName, keyRight, useRightSelect = false, useMiddleSelect = false, handleChange, keyMiddle, filterOptionsMiddle,
  selectedValueMiddle,
}: FilterBarProps) => (
  <Styled.FilterStyled>
    <Styled.TextWrapper>
      <TextP font={fonts.font1.H13}>Filters:</TextP>
    </Styled.TextWrapper>
    <InputSelect
      options={filterOptions}
      onChange={(value) => handleChange(value, keyName)}
      selectedValue={selectedValue}
    />
    {useMiddleSelect && (
      <InputSelect
        options={filterOptionsMiddle || []}
        onChange={(value) => handleChange(value, keyMiddle || '')}
        selectedValue={selectedValueMiddle || ''}
      />
    )}
    {useRightSelect && (
      <InputSelect
        options={filterOptionsRight || []}
        onChange={(value) => handleChange(value, keyRight || '')}
        selectedValue={selectedValueRight || ''}
      />
    )}
  </Styled.FilterStyled>
);

export default FilterBar;
