export const useCookies = () => {
  const createCookie = (name: string, value: string) => {
    const now = new Date();
    const expireTime = new Date();
    expireTime.setTime(now.getTime() + 1000 * 60 * 15);
    document.cookie = `${name}=${value}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
  };

  const getCookie = (name: string) => {
    const value = document.cookie;
    if (!value) return undefined;
    const cookie = value.split('; ').filter((cookieValue) => {
      if (!cookieValue) return false;
      const cookieName = cookieValue?.split('=')[0];
      return cookieName === name;
    });
    if (!cookie[0]) return undefined;
    return cookie[0].split('=')[1];
  };

  const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/;`;
  };

  return {
    createCookie, getCookie, deleteCookie,
  };
};
