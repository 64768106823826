import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import TableHistory from '../../../components/TableHistory';
import { usePpoResult } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { historyColumns } from '../../../utils/const';

const PPOResultHistory = () => {
  const params = useParams();

  const {
    baseUrl,
    nameSingle,
    ppoResult,
    getHistoryLog,
    history,
  } = usePpoResult();

  useEffect(() => {
    if (params.id) {
      getHistoryLog(params.id);
    }
  }, [params]);

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.ppoResult,
        { url: `${breachcrumbPath.ppoResult.url}/${ppoResult?.id}/change`, name: `PPO Result object (${ppoResult?.id})` },
        { url: '', name: 'history' },
      ]}
    >
      <PageTitle
        title={`Change history: PPO Result ${ppoResult?.id}`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <div>
        <TableHistory
          columns={historyColumns}
          data={history || []}
          footerText=""
        />
      </div>
    </AppWrapper>
  );
};

export default PPOResultHistory;
