import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type IProps = {
  readonly theme?: string
  readonly rightSpace?: boolean
  readonly buttonWidth?: string
  readonly buttonHeight?: string
}

export const ButtonStyled = styled.button<IProps>`
  width: ${({ buttonWidth }) => buttonWidth};
  height: ${({ buttonHeight }) => buttonHeight};
  transition: all .3s ease;
  cursor: pointer;

  text-align: ${({ theme }) => (theme === 'tableHeader' ? 'left' : 'center')};
  text-transform: ${({ theme }) => (theme === 'tableHeader' ? 'capitalize' : '')};
  border-radius:  ${({ theme }) => {
    switch (theme) {
      case 'pageButton':
        return '0';
      case 'selectPageButton':
        return '0';
      case 'tableHeader':
        return '0';
      case 'selectOptions':
        return '0';
      case 'selectedOptions':
        return '0';
      case 'plain':
        return '0';
      default:
        return '4px';
    }
  }};

  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'headerLink':
        return 'underline';
      default:
        return 'none';
    }
  }};

  ${({ theme }) => {
    switch (theme) {
      case 'headerLink':
        return fonts.font1.H11;
      case 'plainFouth':
        return fonts.font1.H11;
      default:
        return fonts.font1.H13;
    }
  }};

  background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour4;
      case 'tertiary':
        return colours.colour17;
      case 'plain':
        return 'transparent';
      case 'plainSecondary':
        return 'transparent';
      case 'plainTertiary':
        return 'transparent';
      case 'plainFouth':
        return 'transparent';
      case 'warning':
        return colours.colour18;
      case 'pageButton':
        return 'transparent';
      case 'selectPageButton':
        return colours.colour4;
      case 'tableHeader':
        return 'transparent';
      case 'selectOptions':
        return 'transparent';
      case 'selectedOptions':
        return colours.colour23;
      case 'headerLink':
        return 'transparent';
      default:
        return colours.colour3;
    }
  }};

  color:  ${({ theme }) => {
    switch (theme) {
      case 'plain':
        return colours.colour4;
      case 'plainSecondary':
        return colours.colour4;
      case 'plainTertiary':
        return colours.colour15;
      case 'plainFouth':
        return colours.colour3;
      case 'pageButton':
        return colours.colour1;
      case 'tertiary':
        return colours.colour1;
      case 'tableHeader':
        return colours.colour1;
      case 'selectOptions':
        return colours.colour1;
      case 'selectedOptions':
        return colours.colour1;
      case 'headerLink':
        return colours.colour2;
      default:
        return colours.colour2;
    }
  }};

  border:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return `1px solid ${colours.colour4}`;
      case 'tertiary':
        return `1px solid ${colours.colour23}`;
      case 'warning':
        return `1px solid ${colours.colour18}`;
      case 'plain':
        return '1px solid transparent';
      case 'plainSecondary':
        return '1px solid transparent';
      case 'plainTertiary':
        return 'none';
      case 'plainFouth':
        return 'none';
      case 'pageButton':
        return '1px solid transparent';
      case 'selectPageButton':
        return '1px solid transparent';
      case 'tableHeader':
        return 'none';
      case 'selectOptions':
        return 'none';
      case 'selectedOptions':
        return 'none';
      case 'headerLink':
        return 'none';
      default:
        return `1px solid ${colours.colour3}`;
    }
  }};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'tertiary':
        return '0.25rem 0.5rem';
      case 'plain':
        return '0 0.5rem';
      case 'plainSecondary':
        return '0';
      case 'plainTertiary':
        return '0';
      case 'plainFouth':
        return '0';
      case 'pageButton':
        return '0 0.25rem';
      case 'selectPageButton':
        return '0 0.25rem';
      case 'tableHeader':
        return '0 0.25rem';
      case 'selectOptions':
        return '0.1rem 0.25rem';
      case 'selectedOptions':
        return '0.1rem 0.25rem';
      case 'headerLink':
        return '0';
      default:
        return '0.625rem 0.938rem';
    }
  }};

  box-shadow: ${({ theme }) => {
    switch (theme) {
      default:
        return 'none';
    }
  }};
  
  &:hover {
    background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour20;
      case 'tertiary':
        return colours.colour17;
      case 'warning':
        return colours.colour19;
      case 'plain':
        return 'transparent';
      case 'plainSecondary':
        return 'transparent';
      case 'plainTertiary':
        return 'transparent';
      case 'plainFouth':
        return 'transparent';
      case 'tableHeader':
        return colours.colour23;
      case 'selectOptions':
        return colours.colour4;
      case 'selectedOptions':
        return colours.colour13;
      case 'headerLink':
        return 'transparent';
      default:
        return colours.colour8;
    }
  }};
  border: ${({ theme }) => {
    switch (theme) {
      case 'tertiary':
        return `1px solid ${colours.colour1}`;
      default:
        break;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'plain':
        return colours.colour8;
      case 'plainTertiary':
        return colours.colour3;
      case 'plainFouth':
        return colours.colour8;
      case 'selectOptions':
        return colours.colour1;
      case 'headerLink':
        return colours.colour4;
      case 'pageButton':
        return colours.colour2;
      default:
        break;
    }
  }};

  box-shadow: ${({ theme }) => {
    switch (theme) {
      default:
        return 'none';
    }
  }};

}
  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => {
    switch (theme) {
      default:
        return colours.colour8;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      default:
        return colours.colour12;
    }
  }};
  }
`;
