import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type TextInputProps = {
  readonly isError?: boolean
  readonly theme?: string
}

type InputWrapperProps = {
  readonly padding: boolean
  readonly theme?: string
}

type TextAreaWrapperProps = {
  readonly theme?: string
}

export const InputWrapper = styled.div<InputWrapperProps>`
  padding: ${({ padding }) => (padding ? '0.625rem' : '0')};
  display: flex;
  align-items: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return 'flex-start';
      default:
        return 'center';
    }
  }};
  flex-direction: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return 'column';
      default:
        return 'row';
    }
  }};
`;

export const LabelStyled = styled.label`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const TextInputStyled = styled.input<TextInputProps>`
  width: 100%;
  
  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '0';
      default:
        return '4px';
    }
  }};
  padding: 0.35rem;
  box-shadow: none;
  border: 1px solid ${({ isError }) => (isError ? colours.colour18 : colours.colour23)};
`;

export const TextAreaWrapper = styled.div<TextAreaWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return '0.5rem';
      default:
        return '';
    }
  }};
    width: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '100%';
      case 'column':
        return '100%';
      case 'form':
        return '437px';
      default:
        return '275px';
    }
  }};
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
`;
