import styled from 'styled-components';

export const DashboardStyled = styled.section`
  display: flex;
  width: 100%;
`;

export const TableWrapper = styled.section`
  width: 75%;
`;

export const DashboardTitleWrapper = styled.div`
  margin: 0.875rem 0;
`;
