import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import FilterBar from '../../../components/FilterBar';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { useProject } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { projectStatusFilterOptions, projectTableColumns } from '../../../utils/const';

const Project = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    projects,
    filterProjects,
    selectProjects,
    searchFilter,
    projectStatusFilter,
    setSearchFilter,
    getProjects,
    handleCheck,
    handleSearch,
    handleUpdateFilter,
    isLoading,
    setIsLoading,
  } = useProject();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
    if (location.state?.searchQuery) {
      setSearchFilter(location.state.searchQuery);
      handleSearch(location.state.searchQuery);
      getProjects(true, true);
    } else if (location.search) {
      const searchInfo = ((location.search.split('='))[1]).replaceAll('%20', ' ');
      setSearchFilter(searchInfo);
      handleSearch(searchInfo);
      getProjects(true, true);
    } else {
      getProjects(true, !!location.state?.searchQuery);
    }
  }, [location]);

  useEffect(() => {
    if (isLoading) return;

    let getData: ReturnType<typeof setTimeout>;
    if (searchFilter) {
      getData = setTimeout(() => {
        handleSearch();
      }, 1000);
    }
    return () => clearTimeout(getData);
  }, [searchFilter]);

  useEffect(() => {
    if (!isLoading) {
      handleSearch();
    } else {
      setIsLoading(false);
    }
  }, [projectStatusFilter]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.project]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <SearchBar
        handleSearch={handleSearch}
        searchCount={filterProjects.length}
        total={projects.length}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
      />
      <FilterBar
        filterOptions={projectStatusFilterOptions}
        keyName="status"
        handleChange={handleUpdateFilter}
        selectedValue={projectStatusFilter}
      />
      <TableBasic
        data={filterProjects || []}
        columns={projectTableColumns}
        selectedList={selectProjects}
        handleCheck={handleCheck}
        footerText={`${filterProjects.length} ${name}`}
        basicUrl={`/${baseUrl}`}
        query={`?search=${searchFilter}`}
        checkBoxColumn={false}
      />
    </AppWrapper>
  );
};

export default Project;
