import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import environment from '../config/environment';

const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((c) => c.startsWith(name));
  const value = cookie && cookie.split('=')[1];

  if (!cookie || !value) {
    return {};
  }

  const decrypt = jwtDecode(value);

  return {
    decrypt,
    value,
  };
};

export const useCheckLogin = () => {
  const [cookie] = useState(getCookie(environment.app.cookieName));

  const renewCookie = () => {
    const { value } = getCookie(environment.app.cookieName);
    const authCookie = getCookie(environment.app.authCookieName);

    if (!value) {
      window.location.href = '/login?idle-time-out';
      return;
    }
    const expiration = new Date(Date.now() + 30 * 60 * 1000);
    document.cookie = `${environment.app.cookieName}=${value};  expires=${expiration.toUTCString()}; path=/; Secure`;
    if (authCookie.value) {
      document.cookie = `${environment.app.authCookieName}=${authCookie.value};  expires=${expiration.toUTCString()}; path=/; Secure`;
    }
  };

  return {
    hookCookie: cookie.decrypt,
    hookRenewCookie: renewCookie,
  };
};
