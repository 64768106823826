import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { NameSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useHealthAuthority = () => {
  const navigate = useNavigate();

  const {
    getOne, getIndex, getManyById, create, update, deleteMany,
  } = useApi();

  const baseUrl = 'core/healthauthority';
  const controllerName = 'healthauthorities';
  const name = 'healthauthorities';
  const nameSingle = 'healthauthority';

  const [healthAuthorities, setHealthAuthorities] = useState<{id: number, name: string}[] | []>([]);
  const [healthAuthority, setHealthAuthority] = useState<{id?: number, name?: string}>({});

  const [selectedHealthAuthorities, setSelectedHealthAuthorities] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.healthAuthority,
    ];
    if (healthAuthorities.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.healthAuthority.url}/${healthAuthorities[0].id}/change`, name: healthAuthorities[0].name });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string, field: string) => {
    setHealthAuthority((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectedHealthAuthorities.includes(id)) {
      setSelectedHealthAuthorities((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectedHealthAuthorities((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectedHealthAuthorities } });
    }
  };

  // API Calls
  const getHealthAuthorities = async () => {
    try {
      const response = await getIndex(controllerName);
      setHealthAuthorities(response);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHealthAuthority = async (id: number) => {
    try {
      const response = await getOne(id, controllerName);
      setHealthAuthority(response);
      setSelectedHealthAuthorities(response.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHealthAuthoritiesById = async (ids: number[]) => {
    try {
      const response = await getManyById(ids, controllerName);
      setHealthAuthorities(response);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewHealthAuthority = async (values: any) => {
    try {
      return await create(values, controllerName);
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateNewHealthAuthority = async (values: any, id: number) => {
    try {
      return await update(values, id, controllerName);
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteHealthAuthorities = async (ids: number[]) => {
    try {
      return await deleteMany(ids, controllerName);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleCreate = async (type: string) => {
    try {
      const validedValues = await NameSchema.validate({
        name: healthAuthority.name,
      }, { abortEarly: false });
      const authority = await createNewHealthAuthority(validedValues);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${authority.id}/change`);
      }
      if (type === 'add') {
        setHealthAuthority({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthAuthority({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!healthAuthority.id) throw new Error('Failed to update health authority');
      const validedValues = await NameSchema.validate({
        name: healthAuthority.name,
      }, { abortEarly: false });
      const authority = await updateNewHealthAuthority(validedValues, healthAuthority.id);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `${baseUrl}/${authority.id}`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setHealthAuthority({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthAuthority({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteHealthAuthorities(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, healthAuthorities[0]?.name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  return {
    healthAuthority,
    healthAuthorities,
    selectedHealthAuthorities,
    pageType,
    toastMessage,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    setPageType,
    getHealthAuthority,
    setSelectedHealthAuthorities,
    getHealthAuthorities,
    createNewHealthAuthority,
    handleSubmitButton,
    setHealthAuthority,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getHealthAuthoritiesById,
    getBreadcrumbs,
  };
};
