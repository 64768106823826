import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import TextInput from '../../../components/InputText';
import { LinkComponent } from '../../../components/Link';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useUniversity } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const UniversityEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    university,
    toastMessage,
    handleChange,
    getUniversity,
    handleSubmitButton,
    setPageType,
    setUniversity,
  } = useUniversity();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getUniversity(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setUniversity({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.university,
        { url: '', name: university.name || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl=""
        buttonText=""
        showButton={false}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      <Styled.FormWrapper>
        <TextInput
          id="name"
          label="name:"
          handleHandle={handleChange}
          inputValue={university.name || ''}
          keyName="name"
        />
      </Styled.FormWrapper>
      <Styled.ButtonsContainerWrapper>
        <div>
          {pageType === 'change' && (
            <LinkComponent
              url={`/${baseUrl}/${university.id}/delete`}
              theme="warning"
            >
              <div>
                <TextP font={fonts.font1.H13}>Delete</TextP>
              </div>
            </LinkComponent>
          )}
        </div>
        <Styled.FlexBoxStyled>
          <Button
            onClick={() => handleSubmitButton('add')}
            theme="secondary"
          >
            <p>Save and add another</p>
          </Button>
          <Styled.ButtonWrapper>

            <Button
              onClick={() => handleSubmitButton('edit')}
              theme="secondary"
            >
              <p>Save and continue editing</p>
            </Button>
          </Styled.ButtonWrapper>
          <Styled.ButtonWrapper>

            <Button
              onClick={() => handleSubmitButton('save')}
            >
              <p>SAVE</p>
            </Button>
          </Styled.ButtonWrapper>
        </Styled.FlexBoxStyled>
      </Styled.ButtonsContainerWrapper>
    </AppWrapper>
  );
};

export default UniversityEdit;
