import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import TableHistory from '../../../components/TableHistory';
import { useStudyResult } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { historyColumns } from '../../../utils/const';

const StudyResultHistory = () => {
  const params = useParams();

  const {
    baseUrl,
    nameSingle,
    studyResult,
    getHistoryLog,
    history,
  } = useStudyResult();

  useEffect(() => {
    if (params.id) {
      getHistoryLog(params.id);
    }
  }, [params]);

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.studyResult,
        { url: `${breachcrumbPath.studyResult.url}/${studyResult?.id}/change`, name: `Study Result object (${studyResult?.id})` },
        { url: '', name: 'history' },
      ]}
    >
      <PageTitle
        title={`Change history: Study Result ${studyResult?.id}`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <div>
        <TableHistory
          columns={historyColumns}
          data={history}
          footerText=""
        />
      </div>
    </AppWrapper>
  );
};

export default StudyResultHistory;
