import toRem from '../utils/toRem';

export const colours = {
  colour1: '#333333',
  colour2: '#ffffff',
  colour3: '#447e9b',
  colour4: '#79aec8',
  colour5: '#f8f8f8',
  colour6: '#f5dd5d',
  colour7: '#ffc',
  colour8: '#036',
  colour9: '#eeeeee',
  colour10: '#FFB700',
  colour11: '#70BF2B',
  colour12: '#f8f8f8',
  colour13: '#999999',
  colour14: '#417690',
  colour15: '#666666',
  colour16: '#F6F6F6',
  colour17: '#EEEEEE',
  colour18: '#ba2121',
  colour19: '#a41515',
  colour20: '#609ab6',
  colour21: '#dfd',
  colour22: '#ffefef',
  colour23: '#cccccc',
  colour24: '#c4dce8',
};

export const fonts = {
  font1: {
    H11: `font-size: ${toRem(11)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H11B: `font-size: ${toRem(11)}; font-weight: 700; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H12: `font-size: ${toRem(12)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H13: `font-size: ${toRem(13)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H13B: `font-size: ${toRem(13)}; font-weight: 600; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H14: `font-size: ${toRem(14)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H14B: `font-size: ${toRem(14)}; font-weight: 600; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H16B: `font-size: ${toRem(16)}; font-weight: 600; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H18: `font-size: ${toRem(18)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H20: `font-size: ${toRem(20)}; font-weight: 300; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
    H24: `font-size: ${toRem(24)}; font-weight: 400; font-family: Roboto, Lucida Grande, DejaVu Sans, Bitstream Vera Sans, Verdana, Arial, sans-serif;`,
  },
};
