import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import { LinkComponent } from '../../../components/Link';
import { TextH3, TextP } from '../../../components/Text';
import { useProject } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { fonts } from '../../../styles/theme';

const ProjectDelete = () => {
  const location: any = useLocation();
  const params = useParams();

  const {
    nameSingle,
    name,
    baseUrl,
    projects,
    selectProjects,
    setSelectProjects,
    handleDelete,
    getProjectsById,
    getBreadcrumbs,
    errors,
  } = useProject();

  useEffect(() => {
    if (location.state?.ids) {
      setSelectProjects(location.state.ids);
      getProjectsById(location.state.ids);
    }
  }, [location]);

  useEffect(() => {
    if (params.id) {
      setSelectProjects([parseInt(params.id, 10)]);
      getProjectsById([parseInt(params.id, 10)]);
    }
  }, [params]);

  return (
    <AppWrapper breadcrumbs={getBreadcrumbs()}>
      <Styled.TextWrapper>
        <TextH3 font={fonts.font1.H20}>Are you sure?</TextH3>
      </Styled.TextWrapper>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.message} />
      )}
      {projects.length === 1 ? (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the {nameSingle} “{projects[0].project_name}”? All of the following related items will be deleted:
        </TextP>
      ) : (
        <TextP font={fonts.font1.H14}>
          Are you sure you want to delete the selected {name}? All of the following objects and their related items will be deleted:
        </TextP>
      )}
      <div>
        <Styled.TextWrapper>
          <TextP font={fonts.font1.H16B}>Summary</TextP>
          <Styled.ListStyled>
            <li>
              {name}: {projects.length}
            </li>
          </Styled.ListStyled>
        </Styled.TextWrapper>
      </div>
      <div>
        <TextP font={fonts.font1.H16B}>Objects</TextP>
        {projects && (
          <Styled.ListStyled>
            {projects?.map((project: { project_name?: string, project_id?: number }) => (
              <li key={project.project_id}>
                {nameSingle}: {' '}
                <LinkComponent url={`/${baseUrl}/${project.project_id}/change`}>
                  {project.project_name || `projectId ${project.project_id}`}
                </LinkComponent>
              </li>
            ))}
          </Styled.ListStyled>
        )}
        <Styled.FlexBoxStyled>
          <Button
            onClick={() => handleDelete(selectProjects)}
            theme="warning"
          >
            <TextP font={fonts.font1.H12}>Yes, I&apos;m sure</TextP>
          </Button>
          <Styled.ButtonWrapper>
            <LinkComponent
              url={projects.length === 1 ? `/${baseUrl}/${projects[0].project_id}/change` : `/${baseUrl}`}
              theme="buttonSecondary"
            >
              <div>
                <TextP font={fonts.font1.H12}>
                  No, take me back
                </TextP>
              </div>
            </LinkComponent>
          </Styled.ButtonWrapper>
        </Styled.FlexBoxStyled>
      </div>
    </AppWrapper>
  );
};

export default ProjectDelete;
