import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import PageTitle from '../../../components/PageTitle';
import TableHistory from '../../../components/TableHistory';
import { useProject } from '../../../hooks';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { historyColumns } from '../../../utils/const';

const ProjectHistory = () => {
  const params = useParams();

  const {
    baseUrl,
    nameSingle,
    project,
    getHistoryLog,
    history,
  } = useProject();

  useEffect(() => {
    if (params.id) {
      getHistoryLog(params.id);
    }
  }, [params]);

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.project,
        { url: `${breachcrumbPath.project.url}/${project?.id}/change`, name: project?.title || `${project?.id}` },
        { url: '', name: 'history' },
      ]}
    >
      <PageTitle
        title={`Change history: ${project?.title}`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <div>
        <TableHistory
          columns={historyColumns}
          data={history}
          footerText=""
        />
      </div>
    </AppWrapper>
  );
};

export default ProjectHistory;
