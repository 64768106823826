import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import FilterBar from '../../../components/FilterBar';
import PageTitle from '../../../components/PageTitle';
import SearchBar from '../../../components/SearchBar';
import TableBasic from '../../../components/Tablebasic';
import { resultsColumns, resultStatusFilterOptions } from '../../../utils/const';
import * as Styled from '../../../styles/IndexStyles';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { usePpoResult } from '../../../hooks';

const PpoResults = () => {
  const location: any = useLocation();
  const {
    nameSingle,
    name,
    baseUrl,
    filterPpoResults,
    ppoResults,
    selectPpoResults,
    getPpoResults,
    handleCheck,
    handleSearch,
    resultStatusFilter,
    searchFilter,
    setSearchFilter,
    handleUpdateFilter,
  } = usePpoResult();

  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  useEffect(() => {
    getPpoResults(true);
  }, []);

  useEffect(() => {
    if (location.state?.toastMessage) {
      setToastMessage(location.state.toastMessage);
    }
  }, [location]);

  useEffect(() => {
    handleSearch();
  }, [resultStatusFilter]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[breachcrumbPath.core, breachcrumbPath.ppoResult]}
    >
      <PageTitle
        title={`Select ${nameSingle} to change`}
        linkUrl={`/${baseUrl}/add`}
        buttonText={`add ${nameSingle}`}
        useIcon
      />
      <SearchBar
        handleSearch={handleSearch}
        setSearchValue={setSearchFilter}
        searchValue={searchFilter}
        searchCount={filterPpoResults.length}
        total={ppoResults.length}
      />
      <Styled.FlexboxStyled>
        <FilterBar
          filterOptions={resultStatusFilterOptions}
          keyName="result_status"
          handleChange={handleUpdateFilter}
          selectedValue={resultStatusFilter}
        />
      </Styled.FlexboxStyled>
      <TableBasic
        data={filterPpoResults || []}
        columns={resultsColumns}
        selectedList={selectPpoResults}
        handleCheck={handleCheck}
        footerText={`${filterPpoResults.length} ${name}`}
        basicUrl={`/${baseUrl}`}
      />
    </AppWrapper>
  );
};

export default PpoResults;
