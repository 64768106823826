import React from 'react';
import { colours, fonts } from '../../styles/theme';
import Button from '../Button';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';

type MainHeaderProps = {
  handleLogout: () => void;
  userName: string
}

const MainHeader = ({ userName, handleLogout }: MainHeaderProps) => (
  <Styled.MainHeaderWraper>
    <Styled.MainHeaderStyled>
      <LinkComponent url="/" theme="secondary">
        <TextP font={fonts.font1.H24}>Reach BC Administration</TextP>
      </LinkComponent>
      <Styled.OptionWrapper>
        <TextP color={colours.colour7}>Welcome, {userName}.</TextP>
        <LinkComponent url="/passwordChange/" theme="headerLink">
          change password
        </LinkComponent>
        <p>/</p>
        <Button onClick={handleLogout} theme="headerLink">log out</Button>
      </Styled.OptionWrapper>
    </Styled.MainHeaderStyled>
  </Styled.MainHeaderWraper>
);

export default MainHeader;
