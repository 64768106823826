import React, { ReactNode, useEffect } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import environment from '../../config/environment';
import { useCookies } from '../../hooks';

type ProtectedRouteProps = {
  children: ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { getCookie } = useCookies();

  const isLoggedin = () => {
    const cookies = getCookie(environment.app.cookieName);
    if (!cookies) navigate('/login');
  };

  useEffect(() => {
    isLoggedin();
  }, []);

  return (<div>{children}</div>);
};

export default ProtectedRoute;
