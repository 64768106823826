import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { healthCategorySchema } from '../utils/validations';
import { useApi } from './useApi';

export const useHealthCategory = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update, deleteMany,
  } = useApi();

  const baseUrl = 'core/healthcategory';
  const controllerName = 'admin/healthCategories';
  const name = 'health categories';
  const nameSingle = 'health category';

  const [healthCategories, setHealthCategories] = useState<{category: string, id: number, item?: string}[] | []>([]);
  const [healthCategory, setHealthCategory] = useState<IHealthCategory>({});
  const [saveName, setSavedName] = useState('');

  const [selectHealthCategories, setSelectHealthCategories] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [selectedValue, setSelectedValue] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.hospitals,
    ];
    if (healthCategories.length === 1) {
      crumbs.push({
        url: `${breachcrumbPath.healthCategory.url}/${healthCategories[0].id}/change`,
        name: `${healthCategories[0].category}: ${healthCategories[0].item}`,
      });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string, field: string) => {
    setHealthCategory((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectHealthCategories.includes(id)) {
      setSelectHealthCategories((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectHealthCategories((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectHealthCategories } });
    }
  };

  // API Calls
  const getHealthCategories = async () => {
    try {
      const healthCategoryInfo = await getIndex(controllerName);
      setHealthCategories(healthCategoryInfo);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getHealthCategory = async (id: number) => {
    try {
      const healthCategoryInfo = await getOne(id, controllerName);
      setHealthCategory(healthCategoryInfo);
      setSavedName(healthCategoryInfo.item);
      setSelectHealthCategories(healthCategoryInfo.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHealthCategoriesById = async (ids: number[]) => {
    try {
      const healthCategoryInfo = await getManyById(ids, controllerName);
      setHealthCategories(healthCategoryInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewHealthCategory = async (values: any) => {
    try {
      const newHealthCategory = await create(values, controllerName);
      return newHealthCategory;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateHealthCategory = async (values: any, id: number) => {
    try {
      const updatedHealthCategory = await update(values, id, controllerName);
      return updatedHealthCategory;
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteHealthCategory = async (ids: number[]) => {
    try {
      const results = await deleteMany(ids, controllerName);
      return results;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleCreate = async (type: string) => {
    try {
      const validedValues = await healthCategorySchema.validate({
        ...healthCategory,
        category: healthCategory.category?.toLocaleUpperCase(),
      }, { abortEarly: false });
      const healthCategoryInfo = await createNewHealthCategory(validedValues);
      if (!healthCategoryInfo || !healthCategoryInfo.id) throw new Error(`Failed to create new ${nameSingle}`);
      setErrors({});
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${healthCategoryInfo.id}/change`);
      }
      if (type === 'add') {
        setHealthCategory({});
        setSavedName('');
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthCategory({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!healthCategory.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues = await healthCategorySchema.validate({
        ...healthCategory,
        category: healthCategory.category?.toLocaleUpperCase(),
      }, { abortEarly: false });
      const healthCategoryInfo = await updateHealthCategory(validedValues, healthCategory.id);
      if (!healthCategoryInfo?.item) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, `${healthCategoryInfo.category}: ${healthCategoryInfo.item}`, `/${baseUrl}/${healthCategoryInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setHealthCategory({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthCategory({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteHealthCategory(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, healthCategories[0]?.item || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    healthCategory,
    healthCategories,
    selectHealthCategories,
    pageType,
    setPageType,
    toastMessage,
    getHealthCategory,
    setSelectHealthCategories,
    getHealthCategories,
    createNewHealthCategory,
    handleSubmitButton,
    setHealthCategory,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getHealthCategoriesById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    errors,
    getBreadcrumbs,
  };
};
