import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { NameSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useHospital = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update, deleteMany,
  } = useApi();

  const baseUrl = 'core/hospital';
  const controllerName = 'hospitals';
  const name = 'hospitals';
  const nameSingle = 'hospital';

  const [hospitals, setHospitals] = useState<{id: number, name: string}[] | []>([]);
  const [hospital, setHospital] = useState<{id?: number, name?: string}>({});
  const [saveName, setSavedName] = useState('');

  const [selectHospitals, setSelectHospitals] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.hospitals,
    ];
    if (hospitals.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.hospitals.url}/${hospitals[0].id}/change`, name: hospitals[0].name });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string, field: string) => {
    setHospital((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectHospitals.includes(id)) {
      setSelectHospitals((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectHospitals((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectHospitals } });
    }
  };

  // API Calls
  const getHospitals = async () => {
    try {
      const hostipalList = await getIndex(controllerName);
      setHospitals(hostipalList);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const getHospital = async (id: number) => {
    try {
      const hospitalInfo = await getOne(id, controllerName);
      setHospital(hospitalInfo);
      setSavedName(hospitalInfo.name);
      setSelectHospitals(hospitalInfo.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHospitalsById = async (ids: number[]) => {
    try {
      const HospitalInfo = await getManyById(ids, controllerName);
      setHospitals(HospitalInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewHospital = async (values: any) => {
    try {
      const newHospital = await create(values, controllerName);
      return newHospital;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateHospital = async (values: any, id: number) => {
    try {
      const updatedHospital = await update(values, id, controllerName);
      return updatedHospital;
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteHospital = async (ids: number[]) => {
    try {
      const results = await deleteMany(ids, controllerName);
      return results;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleCreate = async (type: string) => {
    try {
      const validedValues = await NameSchema.validate({
        name: hospital.name,
      }, { abortEarly: false });
      const hospitalInfo = await createNewHospital(validedValues);
      if (!hospitalInfo?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${hospitalInfo.id}/change`);
      }
      if (type === 'add') {
        setHospital({});
        setSavedName('');
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHospital({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!hospital.id) throw new Error(`Failed to update ${nameSingle}`);
      const validedValues = await NameSchema.validate({
        name: hospital.name,
      }, { abortEarly: false });
      const hospitalInfo = await updateHospital(validedValues, hospital.id);
      if (!hospitalInfo?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, hospitalInfo.name, `/${baseUrl}/${hospitalInfo.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setHospital({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHospital({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteHospital(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, hospitals[0]?.name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    hospital,
    hospitals,
    selectHospitals,
    pageType,
    setPageType,
    toastMessage,
    getHospital,
    setSelectHospitals,
    getHospitals,
    createNewHospital,
    handleSubmitButton,
    setHospital,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getHospitalsById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
  };
};
