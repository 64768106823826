import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const MainHeaderWraper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background: ${colours.colour3};
  ${fonts.font1.H12}
  color: ${colours.colour2};
  padding: 0.875rem 1.25rem;
`;

export const MainHeaderStyled = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionWrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  ${fonts.font1.H11}

  p {
    margin: 0 0.25rem;
  }
  button {
    text-transform: uppercase;
  }
`;
