import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type InputSelectProps = {
  options: { value: string | number, label: string }[]
  placeholder?: string
  onChange: (value: string | number) => void
  selectedValue: string | number | undefined
  label?: string
  width?: string
  disabled?: boolean
  error?: string
}

const InputSelect = ({
  options, placeholder, selectedValue, error, label, width, disabled, onChange,
}: InputSelectProps) => (
  <Styled.SelectWrapper>
    {label && (
      <Styled.LabelStyled htmlFor="action">
        {label}
      </Styled.LabelStyled>
    )}
    <Styled.InputWrapper>
      {error && (
        <Styled.ErrorWrapper>
          <TextP>{error}</TextP>
        </Styled.ErrorWrapper>
      )}
      <Styled.SelectStyled
        id="action"
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          onChange(event.target.value);
        }}
        value={selectedValue}
        width={width}
        disabled={disabled}
      >
        {placeholder && <Styled.SelectOptionStyled>{placeholder}</Styled.SelectOptionStyled>}
        {options.map((option, index) => (
          <Styled.SelectOptionStyled
            value={option.value}
            key={`${index}-${option.value}`}
          >
            {option.label}
          </Styled.SelectOptionStyled>
        ))}
      </Styled.SelectStyled>
    </Styled.InputWrapper>
  </Styled.SelectWrapper>
);

export default InputSelect;
