import React from 'react';
import InputCheckbox from '../InputCheckbox/Index';
import { TextP } from '../Text';
import * as Styled from './styles';

type CheckboxGroupProps = {
  mainLabel: string
  selectValues: string[]
  checkboxOptions: { value: string, label: string }[]
  handleChange: (checked: boolean, value: string) => void
  error?: string
}

const CheckboxGroup = ({
  mainLabel, checkboxOptions, selectValues, error, handleChange,
}: CheckboxGroupProps) => (
  <Styled.CheckboxGroupStyled>
    <Styled.MainLabelStyled>
      <TextP>{mainLabel}</TextP>
    </Styled.MainLabelStyled>
    <div>
      {checkboxOptions.map((option) => (
        <Styled.CheckboxWrapper key={option.value}>
          <InputCheckbox
            postLabal={option.label}
            checkedValue={selectValues.includes(option.value)}
            handleChange={(checked) => handleChange(checked, option.value)}
          />
        </Styled.CheckboxWrapper>
      ))}
      {error && (
      <Styled.ErrorWrapper>
        <TextP>{error}</TextP>
      </Styled.ErrorWrapper>
      )}
    </div>
  </Styled.CheckboxGroupStyled>
);

export default CheckboxGroup;
