import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type IconWrapperProps = {
  readonly left?: boolean;
}

type TextHeaderStyledProps = {
  readonly theme?: string
}

type SelectStyledProps = {
  readonly height?: string
}

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${colours.colour23};
  overflow: hidden;
  height: 350px;
`;

export const TextHeaderStyled = styled.div<TextHeaderStyledProps>`
  background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour4;
      default:
        return colours.colour12;
    }
  }};
  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour2;
      default:
        return colours.colour15;
    }
  }};;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  ${fonts.font1.H13}
  border-bottom: 1px solid ${colours.colour23};
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  margin: ${({ left }) => (left ? '0 0.5rem 0 0' : '0 0 0 0.5rem')};
`;

export const InputTextWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colours.colour23};
`;

export const ButtonContentStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.25rem;
`;

export const CenterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colours.colour23};
  border-radius: 500px;
  padding: 0.25rem;
  margin: 0 0.5rem;
`;

export const SelectStyled = styled.div<SelectStyledProps>`
  height: ${({ height }) => height || '90.7%'};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const ContaintWrapper = styled.div`
  min-width: 40%;
`;

export const LabelStyled = styled.div`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
  align-self: flex-start;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour18};
  ${fonts.font1.H14B}
`;
