import { useState } from 'react';
import JSZip from 'jszip';
import { csvGenerator, csvGeneratorZip } from '../utils/csvGenerator';
import { useApi } from './useApi';

export const useGeneratorTablesCsv = () => {
  const { getOne, getIndex } = useApi();

  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const name = 'Table CSV';
  const nameSingle = 'Table CSV';
  const baseUrl = 'admin/csv';

  const handleGeneratorList = async (table: string) => {
    setErrors({});
    try {
      const results = await getOne(table, baseUrl);
      if (results.error || !results.file) throw new Error(results.error);
      csvGenerator(results.file, table);
    } catch (error: any) {
      setErrors({ fetch: error.message });
    }
  };

  const handleGeneratorAll = async () => {
    setErrors({});
    try {
      const results = await getIndex(baseUrl);
      if (results.error || !results.files) throw new Error(results.error);
      const zip = new JSZip();
      results.files.forEach((table: {name: string, data: string}) => {
        zip.file(`${table.name}.csv`, table.data);
      });
      const content = await zip.generateAsync({ type: 'blob' });
      csvGeneratorZip(content);
    } catch (error: any) {
      setErrors({ fetch: error.message });
    }
  };

  return {
    name,
    nameSingle,
    handleGeneratorList,
    handleGeneratorAll,
    errors,
  };
};
