import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTableBasic } from '../../hooks/useTableBasic';
import { colours } from '../../styles/theme';
import { nameDate, nameTime, numericalDate } from '../../utils/formateDate';
import Button from '../Button';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import * as Styled from './styles';

type TableBasicProps = {
  selectedList: number[]
  data: any[]
  columns?: {
    name: string
    key: string
    width?: string
    type: string
    const?: { [key: string]: string }
    multiKeys?: string[]
    multilabels?: string[]
    linkPath?: string
    dateType?: string
  }[]
  handleCheck: (id: number) => void
  footerText: string
  basicUrl: string
  checkBoxColumn?: boolean
  query?: string
}

const TableBasic = ({
  selectedList,
  handleCheck,
  footerText,
  data,
  basicUrl,
  columns,
  checkBoxColumn = true,
  query = '',
}: TableBasicProps) => {
  const {
    filterTableData,
    setFilterTableData,
    pageNumber,
    setPageNumber,
    totalPages,
    setTotalPages,
    perPage,
    handlePerPage,
    handleSort,
    activeSort,
    sortDirection,
    showPageNumber,
    setShowPageNumber,
    updatedPageList,
  } = useTableBasic(data);

  useEffect(() => {
    if (data && data.length > perPage) {
      setFilterTableData(data.slice(0, perPage));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setFilterTableData(data);
      setTotalPages(1);
    }
  }, [data, perPage]);

  useEffect(() => {
    if (totalPages > 10) {
      setShowPageNumber([1, 2, 3, 4, ' ... ', totalPages - 1, totalPages]);
    }
  }, [totalPages]);

  useEffect(() => {
    setFilterTableData(data.slice(perPage * (pageNumber - 1), (perPage * (pageNumber - 1)) + perPage));
  }, [pageNumber]);

  return (
    <Styled.TableStyled>
      <tbody>
        {columns && (
          <Styled.TableRowStyled>
            {checkBoxColumn && (
              <Styled.TableCellStyled width="2%">
                <Styled.CheckBoxStyled
                  type="checkbox"
                  checked={false}
                  onChange={() => ''}
                />
              </Styled.TableCellStyled>
            )}
            {
              columns.map((header) => (
                <Styled.TableHeaderStyled
                  key={`header-${header.key}`}
                  width={header.width || 'auto'}
                >
                  <Button
                    onClick={() => handleSort(header.key)}
                    theme="tableHeader"
                    buttonWidth="100%"
                    buttonHeight="32px"
                  >
                    <Styled.ButtonStyled>
                      {header.name}
                      {activeSort === header.key && (
                        <FontAwesomeIcon icon={sortDirection === 'asc' ? 'caret-up' : 'caret-down'} />
                      )}
                    </Styled.ButtonStyled>
                  </Button>
                </Styled.TableHeaderStyled>
              ))
            }
          </Styled.TableRowStyled>
        )}
        {columns ? (
          <>
            {filterTableData.map((row) => (
              <Styled.TableRowStyled key={`columns-row-${row.id}`}>
                {checkBoxColumn && (
                  <Styled.TableCellStyled selected={selectedList.includes(row.id)} width="8%">
                    <Styled.CheckBoxStyled
                      type="checkbox"
                      checked={selectedList.includes(row.id)}
                      onChange={() => handleCheck(row.id)}
                    />
                  </Styled.TableCellStyled>
                )}
                {columns.map((column) => (
                  <Styled.TableCellStyled
                    key={`${row.id}-${column.key}`}
                    selected={selectedList.includes(row.id)}
                  >
                    {column.type === 'icon' && (
                      <FontAwesomeIcon
                        icon={row[column.key] ? 'check-circle' : 'xmark-circle'}
                        color={row[column.key] ? colours.colour11 : colours.colour18}
                        size="lg"
                      />
                    )}
                    {column.type === 'circle' && (
                      <FontAwesomeIcon
                        icon={row[column.key] ? 'circle' : 'circle'}
                        color={!row[column.key] ? colours.colour11 : colours.colour18}
                        size="lg"
                      />
                    )}
                    {column.type === 'checkIcon' && (
                      <FontAwesomeIcon
                        icon={row[column.key] ? 'check' : 'xmark'}
                        color={!row[column.key] ? colours.colour18 : colours.colour11}
                        size="lg"
                      />
                    )}
                    {column.type === 'text' && (
                      <span>
                        {
                          column.const ? (
                            <TextP>{column.const[row[column.key]]}</TextP>
                          ) : (
                            <TextP>{row[column.key]}</TextP>
                          )
                        }
                      </span>
                    )}
                    {column.type === 'date' && row[column.key] && (
                      <TextP>
                        {column.dateType === 'all' && <>{nameTime(row[column.key]).replace(' at', ',')}</>}
                        {column.dateType === 'numeric' && (<>{numericalDate(row[column.key])}</>)}
                        {!column.dateType && (<>{nameDate(row[column.key])}</>)}
                      </TextP>
                    )}
                    {column.type === 'link' && (
                      <span>
                        {
                          column.const ? (
                            <LinkComponent url={`${basicUrl}/${row.id}/change`}>
                              <TextP>
                                {column.const[row[column.key]]}
                              </TextP>
                            </LinkComponent>
                          ) : (
                            <span>
                              {row[column.key] === null ? (
                                <TextP>
                                  No Result
                                </TextP>
                              ) : (
                                <LinkComponent
                                  url={column.linkPath
                                    ? `${row.type === 'study' ? 'studyresult' : 'pporesult'}/${row[column.key]}/change`
                                    : `${basicUrl}/${row.id}/change${query}`}
                                >
                                  <TextP>
                                    {row[column.key]}
                                  </TextP>
                                </LinkComponent>
                              )}
                            </span>
                          )
                        }
                      </span>
                    )}
                    {column.type === 'projectlink' && (
                      <span>
                        <LinkComponent url={`project/${row[column.key]}/change`}>
                          <TextP>
                            {row[column.key]}
                          </TextP>
                        </LinkComponent>

                      </span>
                    )}

                    {column.type === 'multi' && column.key === 'project_team' && (
                      <div>
                        <TextP>
                          <Styled.CellLabelStyled>
                            PI
                          </Styled.CellLabelStyled>
                          : {row.pi?.name || row.pi_user}
                        </TextP>
                        <TextP>
                          <Styled.CellLabelStyled>
                            Created by
                          </Styled.CellLabelStyled>
                          : {row.created_by?.name || row.created_by_user || ''}
                        </TextP>
                        <TextP>
                          <Styled.CellLabelStyled>
                            Team members
                          </Styled.CellLabelStyled>
                          : {row.team_members?.join(', ')}
                        </TextP>
                      </div>
                    )}
                  </Styled.TableCellStyled>
                ))}
              </Styled.TableRowStyled>
            ))}
          </>
        ) : (
          <>
            {filterTableData.map((row) => (
              <Styled.TableRowStyled key={`filter-${row.id}`}>
                <Styled.TableCellStyled width="30px" selected={selectedList.includes(row.id)}>
                  <Styled.CheckBoxStyled
                    type="checkbox"
                    checked={selectedList.includes(row.id)}
                    onChange={() => handleCheck(row.id)}
                  />
                </Styled.TableCellStyled>
                <Styled.TableCellStyled selected={selectedList.includes(row.id)}>
                  <LinkComponent url={`${basicUrl}/${row.id}/change`}>{row?.name}</LinkComponent>
                </Styled.TableCellStyled>
              </Styled.TableRowStyled>
            ))}
          </>
        )}
        <Styled.TableRowStyled>
          <Styled.TableCellFooterStyled colSpan={columns ? columns.length : 2}>
            <Styled.FlexBoxStyled>
              {totalPages > 1 && totalPages < 10 && (
                <>
                  {Array.from(Array(totalPages).keys()).map((page: number) => (
                    <Styled.FooterButtonWrapper key={`page-${page}`}>
                      <Button
                        key={`page-${page + 1}`}
                        onClick={() => setPageNumber(page + 1)}
                        theme={page + 1 === pageNumber ? 'selectPageButton' : 'pageButton'}
                      >
                        <TextP>{page + 1}</TextP>
                      </Button>
                    </Styled.FooterButtonWrapper>
                  ))}
                </>
              )}

              {totalPages > 10 && (
                <>
                  {showPageNumber.map((page: number | string) => (
                    <React.Fragment key={`page-${page}`}>
                      {typeof page === 'number' ? (
                        <Styled.FooterButtonWrapper key={`page-${page}`}>
                          <Button
                            onClick={() => {
                              updatedPageList(page + 1);
                              setPageNumber(page + 1);
                            }}
                            theme={page + 1 === pageNumber ? 'selectPageButton' : 'pageButton'}
                          >
                            <TextP>{page}</TextP>
                          </Button>
                        </Styled.FooterButtonWrapper>
                      ) : (
                        <Styled.PageTextWrapper>
                          <TextP> ... </TextP>
                        </Styled.PageTextWrapper>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
              <Styled.FooterTextWrapper>
                <TextP>{footerText}</TextP>
              </Styled.FooterTextWrapper>
              {totalPages > 1 && (
                <Button
                  onClick={handlePerPage}
                  theme="plain"
                >
                  <TextP>
                    Show all
                  </TextP>
                </Button>
              )}
            </Styled.FlexBoxStyled>
          </Styled.TableCellFooterStyled>
        </Styled.TableRowStyled>
      </tbody>
    </Styled.TableStyled>
  );
};

export default TableBasic;
