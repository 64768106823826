import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours } from '../../styles/theme';
import TextInput from '../InputText';
import { TextP } from '../Text';
import * as Styled from './styles';
import { useInputAddRemove } from './useInputAddRemove';
import Button from '../Button';

type InputAddRemoveProps = {
  allOptionsList: { label: string, value: number }[]
  selectOptions: { label: string, value: number }[]
  handleUpdate: (list: number[], add: boolean) => void
  handleAllUpdate: (add: boolean) => void
  label: string
  error?: string
}

const InputAddRemove = ({
  allOptionsList, selectOptions, label, error, handleUpdate, handleAllUpdate,
}: InputAddRemoveProps) => {
  const {
    searchValue,
    setSearchValue,
    setUpList,
    filterList,
    handleFilterList,
    selectedValueLeft,
    handleAddRemove,
    selectedValueRight,
  } = useInputAddRemove();

  useEffect(() => {
    setUpList(allOptionsList, selectOptions);
  }, [allOptionsList, selectOptions]);

  useEffect(() => {
    handleFilterList();
  }, [searchValue]);

  return (
    <Styled.InputsWrapper>
      <Styled.LabelStyled>
        {label}:
      </Styled.LabelStyled>
      <Styled.ContaintWrapper>
        {error && (
          <Styled.ErrorWrapper>
            <TextP>{error}</TextP>
          </Styled.ErrorWrapper>
        )}
        <Styled.SelectWrapper>
          <Styled.TextHeaderStyled>
            <TextP>Available {label}</TextP>
            <Styled.IconWrapper>
              {/* <FontAwesomeIcon icon="question-circle" color={colours.colour15} /> */}
            </Styled.IconWrapper>
          </Styled.TextHeaderStyled>
          <Styled.InputTextWrapper>
            <Styled.IconWrapper left>
              <FontAwesomeIcon icon="magnifying-glass" color={colours.colour15} />
            </Styled.IconWrapper>
            <TextInput
              inputValue={searchValue}
              handleHandle={(value) => setSearchValue(value)}
              id="search"
              keyName=""
              padding={false}
              theme="form"
            />
          </Styled.InputTextWrapper>
          <Styled.SelectStyled height="78%">
            {filterList.map((item) => (
              <Button
                key={item.value}
                onClick={() => handleAddRemove(item.value, 'left')}
                theme={selectedValueLeft.includes(item.value) ? 'selectedOptions' : 'selectOptions'}
              >
                <TextP>{item.label}</TextP>
              </Button>
            ))}
          </Styled.SelectStyled>
        </Styled.SelectWrapper>
        <Styled.ButtonContentStyled>
          <Button
            onClick={() => handleAllUpdate(true)}
            theme="plainTertiary"
          >
            <Styled.ButtonContentStyled>
              <TextP>Choose All</TextP>
              <Styled.IconWrapper>
                <FontAwesomeIcon icon="chevron-circle-right" size="sm" />
              </Styled.IconWrapper>
            </Styled.ButtonContentStyled>
          </Button>
        </Styled.ButtonContentStyled>
      </Styled.ContaintWrapper>
      <div>
        <Styled.CenterButtonWrapper>
          <Button
            onClick={() => handleUpdate(selectedValueLeft, true)}
            theme="plainTertiary"
          >
            <FontAwesomeIcon icon="chevron-circle-right" size="sm" />
          </Button>
          <Button
            onClick={() => handleUpdate(selectedValueRight, false)}
            theme="plainTertiary"
          >
            <FontAwesomeIcon icon="chevron-circle-left" size="sm" />
          </Button>
        </Styled.CenterButtonWrapper>
      </div>
      <Styled.ContaintWrapper>
        <Styled.SelectWrapper>
          <Styled.TextHeaderStyled theme="secondary">
            <TextP>Chosen {label}</TextP>
            <Styled.IconWrapper>
              {/* <FontAwesomeIcon icon="question-circle" color={colours.colour2} size="sm" /> */}
            </Styled.IconWrapper>
          </Styled.TextHeaderStyled>
          <Styled.SelectStyled>
            {selectOptions.map((item) => (
              <Button
                key={item.value}
                onClick={() => handleAddRemove(item.value, 'right')}
                theme={selectedValueRight.includes(item.value) ? 'selectedOptions' : 'selectOptions'}
              >
                <TextP>{item.label}</TextP>
              </Button>
            ))}
          </Styled.SelectStyled>
        </Styled.SelectWrapper>

        <Styled.ButtonContentStyled>
          <Button
            onClick={() => handleAllUpdate(false)}
            theme="plainTertiary"
          >
            <Styled.ButtonContentStyled>
              <Styled.IconWrapper left>
                <FontAwesomeIcon icon="chevron-circle-left" />
              </Styled.IconWrapper>
              <TextP>Remove All</TextP>
            </Styled.ButtonContentStyled>
          </Button>
        </Styled.ButtonContentStyled>

      </Styled.ContaintWrapper>
    </Styled.InputsWrapper>
  );
};

export default InputAddRemove;
