import React from 'react';
import Button from '../Button';
import { TextP } from '../Text';
import * as Styled from './styles';

type PageTitleProps = {
  name: string
  count: number
  selectedCount: number
  selectedValue: string
  onChange: (value: string) => void
  handleClick: (action: string) => void
  showDelete?: boolean
}

const PageAction = ({
  name, count, selectedCount, selectedValue, showDelete = true, onChange, handleClick,
}: PageTitleProps) => (
  <Styled.ActionBarStyled>
    <Styled.FlexboxStyled>
      <Styled.ActionLabelStyled htmlFor="action">Action:</Styled.ActionLabelStyled>
      <Styled.SelectStyled
        id="action"
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          onChange(event.target.value);
        }}
        value={selectedValue}
      >
        <option>---------</option>
        {showDelete && (
          <option value="delete_selected">Delete selected {name}</option>
        )}
      </Styled.SelectStyled>
      <Button
        onClick={() => handleClick(selectedValue)}
        theme="tertiary"
      >
        Go
      </Button>
      <Styled.TextWrapper>
        <TextP>{selectedCount} of {count} selected</TextP>
      </Styled.TextWrapper>
    </Styled.FlexboxStyled>
  </Styled.ActionBarStyled>
);

export default PageAction;
