import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputText from '../../../components/InputText';
import PageTitle from '../../../components/PageTitle';
import { TextP } from '../../../components/Text';
import { useAdminUser } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';

const AdminUserEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    adminUser,
    toastMessage,
    handleChange,
    getAdminUser,
    handleSubmitButton,
    setPageType,
    setAdminUser,
    errors,
  } = useAdminUser();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getAdminUser(parseInt(params.id, 10));
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setAdminUser({});
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.admin,
        breachcrumbPath.adminUser,
        { url: '', name: adminUser?.email || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl=""
        buttonText=""
        showButton={false}
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <InputText
            id="first_name"
            label="first name:"
            inputValue={`${adminUser?.first_name}` || ''}
            handleHandle={handleChange}
            keyName="first_name"
            padding={false}
            theme="form"
            error={errors.first_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="last_name"
            label="last name:"
            inputValue={`${adminUser?.last_name}` || ''}
            handleHandle={handleChange}
            keyName="last_name"
            padding={false}
            theme="form"
            error={errors.last_name}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="email"
            label="email:"
            inputValue={`${adminUser?.email}` || ''}
            handleHandle={handleChange}
            keyName="email"
            padding={false}
            theme="form"
            error={errors.email}
            disabled={pageType !== 'add'}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="phone_number"
            label="phone number:"
            inputValue={`${adminUser?.phone_number}` || ''}
            handleHandle={handleChange}
            keyName="phone_number"
            padding={false}
            theme="form"
            error={errors.phone_number}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="is active:"
            checkedValue={adminUser?.is_active || false}
            handleChange={(value) => handleChange(value, 'is_active')}
          />
        </Styled.InputWrapper>

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${adminUser?.id}`}
        showNew={false}
      />
    </AppWrapper>
  );
};

export default AdminUserEdit;
