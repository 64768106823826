import styled from 'styled-components';
import { fonts } from '../../styles/theme';

export const InputWrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
`;

export const LabelStyled = styled.div`
  width: 170px;
  display: inline-block;
  text-transform: capitalize;
  ${fonts.font1.H13B}
`;

export const TextWrapper = styled.div`
  margin-top: 0.25rem;
`;
