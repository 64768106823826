import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../components/AppWrapper';
import EditFooterBar from '../../../components/EditFooterBar';
import ErrorMessage from '../../../components/ErrorMessage';
import FormLabelText from '../../../components/FormLabelText';
import InputCheckbox from '../../../components/InputCheckbox/Index';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import PageTitle from '../../../components/PageTitle';
import TableBasic from '../../../components/Tablebasic';
import { TextP } from '../../../components/Text';
import { useResearcher } from '../../../hooks';
import * as Styled from '../../../styles/EditDeleteStyles';
import { colours, fonts } from '../../../styles/theme';
import { breachcrumbPath } from '../../../utils/breadcrumbConst';
import { researcherPositionOptions, researcherProjectsColumns } from '../../../utils/const';
import { newResearcherConst } from '../../../utils/userConst';

const ResearcherEdit = () => {
  const params = useParams();
  const location = useLocation();

  const {
    baseUrl,
    nameSingle,
    saveName,
    pageType,
    researcher,
    toastMessage,
    allUsers,
    universities,
    healthAffilation,
    researcherProjects,
    handleChange,
    getResearcher,
    handleSubmitButton,
    setPageType,
    setResearcher,
    errors,
  } = useResearcher();

  const titleOptions: { [key: string]: string } = {
    change: `Change ${nameSingle}`,
    add: `Add ${nameSingle}`,
  };

  useEffect(() => {
    if (params.id) {
      getResearcher(parseInt(params.id, 10));
    } else {
      getResearcher('info');
    }
  }, [params]);

  useEffect(() => {
    if (location?.pathname.includes('change')) {
      setPageType('change');
    }
    if (location?.pathname.includes('add')) {
      setPageType('add');
    }
    if (!params?.id) {
      setResearcher(newResearcherConst);
    }
  }, [location]);

  return (
    <AppWrapper
      toastMessage={toastMessage}
      breadcrumbs={[
        breachcrumbPath.core,
        breachcrumbPath.researcher,
        { url: '', name: `${researcher.first_name} ${researcher.last_name}(${researcher.email})` || '' },
      ]}
    >
      <PageTitle
        title={titleOptions[pageType]}
        linkUrl={`${baseUrl}/add`}
        buttonText=""
      />
      <TextP color={colours.colour1} font={fonts.font1.H16B}>{saveName}</TextP>
      {Object.keys(errors).length > 0 && (
        <ErrorMessage message={errors.fetch} />
      )}
      <Styled.FormWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="user:"
            onChange={(value) => handleChange(value, 'user_id')}
            placeholder="----------"
            options={allUsers}
            selectedValue={researcher?.user_id || ''}
            error={errors.user_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="site_code"
            label="Site Registration Code:"
            inputValue={`${researcher?.site_code}` || ''}
            handleHandle={handleChange}
            keyName="site_code"
            padding={false}
            theme="form"
            error={errors.site_code}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="mobile_phone_number"
            label="mobile phone number:"
            inputValue={`${researcher?.mobile_phone_number}` || ''}
            handleHandle={handleChange}
            keyName="mobile_phone_number"
            padding={false}
            theme="form"
            error={errors.mobile_phone_number}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Position:"
            onChange={(value) => handleChange(value, 'position')}
            options={researcherPositionOptions}
            placeholder="----------"
            selectedValue={researcher?.position || ''}
            error={errors.position}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="position_other"
            label="position/title other:"
            inputValue={`${researcher?.position_other}` || ''}
            handleHandle={handleChange}
            keyName="position_other"
            padding={false}
            theme="form"
            error={errors.position_other}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="health authority affiliation:"
            onChange={(value) => handleChange(value, 'ha_affiliation_id')}
            options={healthAffilation}
            placeholder="----------"
            selectedValue={researcher?.ha_affiliation_id || ''}
            error={errors.ha_affiliation_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputSelect
            label="Academic affiliation:"
            onChange={(value) => handleChange(value, 'academic_affiliation_id')}
            options={universities}
            placeholder="----------"
            selectedValue={researcher?.academic_affiliation_id || ''}
            error={errors.academic_affiliation_id}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputText
            id="academic_affiliation_other"
            label="University Affiliation Other:"
            inputValue={`${researcher?.academic_affiliation_other}` || ''}
            handleHandle={handleChange}
            keyName="academic_affiliation_other"
            padding={false}
            theme="form"
            error={errors.academic_affiliation_other}
          />
        </Styled.InputWrapper>

        <Styled.InputWrapper>
          <InputCheckbox
            label="Is PI:"
            checkedValue={researcher?.is_pi || false}
            handleChange={(value) => handleChange(value, 'is_pi')}
          />
        </Styled.InputWrapper>

        {researcherProjects.length > 0
          && (
            <Styled.InputWrapper>
              <FormLabelText
                label="Researcher Project:"
                textValue=""
              />
              <Styled.ProjectWrapper>
                <TableBasic
                  data={researcherProjects || []}
                  columns={researcherProjectsColumns}
                  selectedList={[]}
                  handleCheck={() => ''}
                  footerText={`${researcherProjects.length} projects`}
                  basicUrl={`/${baseUrl}`}
                  checkBoxColumn={false}
                />
              </Styled.ProjectWrapper>
            </Styled.InputWrapper>
          )}

      </Styled.FormWrapper>
      <EditFooterBar
        handleSubmit={handleSubmitButton}
        urlPath={`${baseUrl}/${researcher?.id}`}
        showNew={false}
        showDelete={false}
      />
    </AppWrapper>
  );
};

export default ResearcherEdit;
