import React from 'react';
import AppWrapper from '../../components/AppWrapper';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import PageTitle from '../../components/PageTitle';
import { useGeneratorTablesCsv } from '../../hooks';
import * as Styled from '../../styles/EditDeleteStyles';
import { breachcrumbPath } from '../../utils/breadcrumbConst';
import { TABLE_TYPES } from './enum';

const GeneratorTablesCsv = () => {
  const {
    nameSingle,
    handleGeneratorList,
    handleGeneratorAll,
    errors,
  } = useGeneratorTablesCsv();

  return (
    <AppWrapper
      breadcrumbs={[
        breachcrumbPath.admin,
        breachcrumbPath.downloadCsv,
      ]}
    >
      <PageTitle
        title={`Download ${nameSingle}`}
        linkUrl="/"
        buttonText=""
      />

      <Styled.FormWrapper>
        {errors.fetch && (
          <ErrorMessage message={errors.fetch} />
        )}

        <Styled.ButtonContainterStyled>
          {TABLE_TYPES.map((table) => (
            <Button
              key={table.db_name}
              onClick={() => handleGeneratorList(table.db_name)}
            >
              {table.label}
            </Button>
          ))}
        </Styled.ButtonContainterStyled>
        <Styled.ButtonContainterStyled>
          <Styled.AllButtonStyled>
            <Button
              onClick={() => handleGeneratorAll()}
              buttonWidth="100%"
            >
              All CSV files
            </Button>
          </Styled.AllButtonStyled>
        </Styled.ButtonContainterStyled>
      </Styled.FormWrapper>
    </AppWrapper>
  );
};

export default GeneratorTablesCsv;
