import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { breachcrumbPath } from '../utils/breadcrumbConst';
import { createToastMessage } from '../utils/createToastMessage';
import { NameSchema } from '../utils/validations';
import { useApi } from './useApi';

export const useHealthAuthorityRegion = () => {
  const navigate = useNavigate();
  const {
    getOne, getIndex, getManyById, create, update, deleteMany,
  } = useApi();

  const baseUrl = 'core/healthauthorityregion';
  const controllerName = 'healthAuthorityRegions';
  const name = 'health authority regions';
  const nameSingle = 'health authority region';

  const [healthAuthorityRegions, setHealthAuthorityRegions] = useState<{id: number, name: string}[] | []>([]);
  const [healthAuthorityRegion, setHealthAuthorityRegion] = useState<{id?: number, name?: string}>({});
  const [saveName, setSavedName] = useState('');

  const [selectedHealthAuthorityRegions, setSelectedHealthAuthorityRegions] = useState<number[]>([]);
  const [changedFields, setChangedField] = useState<string[]>([]);

  const [pageType, setPageType] = useState('add');
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  const [selectedValue, setSelectedValue] = useState('');

  const getBreadcrumbs = () => {
    const crumbs = [
      breachcrumbPath.core,
      breachcrumbPath.hospitals,
    ];
    if (healthAuthorityRegions.length === 1) {
      crumbs.push({ url: `${breachcrumbPath.healthAuthorityRegion.url}/${healthAuthorityRegions[0].id}/change`, name: healthAuthorityRegions[0].name });
      crumbs.push({ url: '', name: 'Delete' });
    } else {
      crumbs.push({ url: '', name: 'Delete multiple objects' });
    }
    return crumbs;
  };

  const handleChange = (value: string, field: string) => {
    setHealthAuthorityRegion((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (!changedFields.includes(field)) {
      setChangedField((oldArray) => [...oldArray, field]);
    }
  };

  const handleCheck = (id: number) => {
    if (selectedHealthAuthorityRegions.includes(id)) {
      setSelectedHealthAuthorityRegions((oldArray) => oldArray.filter((arr) => id !== arr));
    } else {
      setSelectedHealthAuthorityRegions((oldArray) => [...oldArray, id]);
    }
  };

  const handleMenuAction = (action: string) => {
    if (action === 'delete_selected') {
      navigate('delete', { state: { ids: selectedHealthAuthorityRegions } });
    }
  };

  // API Calls
  const getHealthAuthorityRegions = async () => {
    try {
      const healthAuthorityRegionssList = await getIndex(controllerName);
      setHealthAuthorityRegions(healthAuthorityRegionssList);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHealthAuthorityRegion = async (id: number) => {
    try {
      const healthAuthorityInfo = await getOne(id, controllerName);
      setHealthAuthorityRegion(healthAuthorityInfo);
      setSavedName(healthAuthorityInfo.name);
      setSelectedHealthAuthorityRegions(healthAuthorityInfo.id);
    } catch (error) {
      throw new Error('error');
    }
  };

  const getHealthAuthorityRegionsById = async (ids: number[]) => {
    try {
      const healthAuthorityRegionInfo = await getManyById(ids, controllerName);
      setHealthAuthorityRegions(healthAuthorityRegionInfo);
    } catch (error) {
      throw new Error('error');
    }
  };

  const createNewHealthAuthorityRegion = async (values: any) => {
    try {
      const newHealthAuthorityRegion = await create(values, controllerName);
      return newHealthAuthorityRegion;
    } catch (error) {
      throw new Error('error');
    }
  };

  const updateHealthAuthorityRegion = async (values: any, id: number) => {
    try {
      const updatedHealthAuthorityRegion = await update(values, id, controllerName);
      return updatedHealthAuthorityRegion;
    } catch (error) {
      throw new Error('error');
    }
  };

  const deleteHealthAuthorityRegion = async (ids: number[]) => {
    try {
      const results = await deleteMany(ids, controllerName);
      return results;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleCreate = async (type: string) => {
    try {
      const validedValues = await NameSchema.validate({
        name: healthAuthorityRegion.name,
      }, { abortEarly: false });
      const authority = await createNewHealthAuthorityRegion(validedValues);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'edit') {
        navigate(`/${baseUrl}/${authority.id}/change`);
      }
      if (type === 'add') {
        setHealthAuthorityRegion({});
        setSavedName('');
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthAuthorityRegion({});
        setSavedName('');
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleUpdate = async (type: string) => {
    try {
      if (!healthAuthorityRegion.id) throw new Error('Failed to update health authority');
      const validedValues = await NameSchema.validate({
        name: healthAuthorityRegion.name,
      }, { abortEarly: false });
      const authority = await updateHealthAuthorityRegion(validedValues, healthAuthorityRegion.id);
      if (!authority?.name) throw new Error('');
      let message;
      switch (type) {
        case 'add':
          message = createToastMessage('add', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'edit':
          message = createToastMessage('edit', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        case 'save':
          message = createToastMessage('save', nameSingle, authority.name, `/${baseUrl}/${authority.id}/change`);
          break;
        default:
          break;
      }
      if (message) {
        setToastMessage(message);
      }
      if (type === 'add') {
        setHealthAuthorityRegion({});
        navigate(`/${baseUrl}/add`);
      }
      if (type === 'save') {
        setHealthAuthorityRegion({});
        navigate(`/${baseUrl}`, { state: { toastMessage: message } });
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleDelete = async (ids: number[]) => {
    try {
      const deleteCount = await deleteHealthAuthorityRegion(ids);
      const message = deleteCount > 1
        ? createToastMessage('deletes', name, deleteCount)
        : createToastMessage('delete', nameSingle, healthAuthorityRegions[0]?.name || '');
      navigate(`/${baseUrl}`, { state: { toastMessage: message } });
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const handleSubmitButton = (type: string) => {
    switch (pageType) {
      case 'add':
        handleCreate(type);
        break;
      case 'change':
        handleUpdate(type);
        break;
      default:
        break;
    }
  };

  return {
    nameSingle,
    name,
    baseUrl,
    saveName,
    healthAuthorityRegion,
    healthAuthorityRegions,
    selectedHealthAuthorityRegions,
    pageType,
    setPageType,
    toastMessage,
    getHealthAuthorityRegion,
    setSelectedHealthAuthorityRegions,
    getHealthAuthorityRegions,
    createNewHealthAuthorityRegion,
    handleSubmitButton,
    setHealthAuthorityRegion,
    selectedValue,
    setSelectedValue,
    handleMenuAction,
    getHealthAuthorityRegionsById,
    handleCheck,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    getBreadcrumbs,
  };
};
