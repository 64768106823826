import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type ToastProps = {
  readonly type?: string
}

export const HeaderToastWrapper = styled.div<ToastProps>`
${fonts.font1.H13};
  background: ${({ type }) => {
    switch (type) {
      case 'warning':
        return colours.colour7;
      case 'error':
        return colours.colour22;
      default:
        return colours.colour21;
    }
  }};
  display: flex;
  align-items: center;
  padding: 0.625rem;
`;

export const IconWrapper = styled.span`
  margin-right: 0.5rem;
`;
