import React from 'react';
import { TextP } from '../Text';
import * as Styled from './styles';

type ErrorMessageProps = {
  message?: string
}

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Styled.ErrorStyled>
    {message ? (
      <TextP>{message}</TextP>
    ) : (
      <TextP>Please correct the errors below.</TextP>
    )}
  </Styled.ErrorStyled>
);

export default ErrorMessage;
