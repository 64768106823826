import styled from 'styled-components';
import { colours, fonts } from './theme';

export const LoginPageWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${colours.colour5};
  height: 100vh;
`;

export const LoginWrapper = styled.section`
  border: 1px solid ${colours.colour9};
  background: white;
  border-radius: 4px;
  width: 400px;
  margin-top: 100px;
`;

export const HeaderStyled = styled.div`
  background: ${colours.colour3};
  color: ${colours.colour2};
  padding: 15px 20px;
  border-start-end-radius: 4px;
  border-top-left-radius: 4px;
`;

export const LoginBodyWrapper = styled.div`
  padding: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const ErrorStyled = styled.div`
  ${fonts.font1.H14B};
  padding: 0.75rem 0.875rem;
  margin: 0 0 0.75rem 0;
  color: ${colours.colour19};
  border: 1px solid ${colours.colour19};
  border-radius: 4px;
`;
